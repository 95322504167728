import React, { useState } from 'react';
import DisplayUtils from '../../../../utils/DisplayUtils';
import CartUtils from '../../../../utils/CartUtils';
import { savePdf } from '../../../../utils/pdfUtils';
import { useTranslation } from 'react-i18next';
import './OrderItem.css';
import { OrderProductItem, OrderDetail, PdfOrder } from '../../../index';

const OrderItem = ({ order, repeatOrder }) => {
  const { t } = useTranslation();
  const [showDetail, setShowDetail] = useState(false);

  const getDate = date =>
    date
      .split('T')[0]
      .split('-')
      .reverse()
      .join('-');

  const onRepeat = order => {
    repeatOrder(order);
    setShowDetail(false);
  };
  
  const exportPdf = () => {
    savePdf(<PdfOrder order={order} getDate={getDate}/>, `DBS_${getDate(order.date)}_${order.reference}.pdf`)
  };

  //const orderState = t('Account.orders.completed').split(' ')[1];
  const filterProducts =
  order.products &&
  order.products.map(product => {
    if (!product.sizes || !product.sizes.name) {
      return product;
    } else {
      return {
        ...product,
        id: product.sizes.id ? product.id + '.' + product.sizes.id : product.id,
        name: product.sizes.name ? product.name + ' ' + product.sizes.name : product.name,
        price: product.sizes.price ? product.sizes.price : product.price,
        pricepvp: product.sizes.pricepvp ? product.sizes.pricepvp : product.pricepvp,
        images: product.sizes.image ? [product.sizes.image] : product.images,
        promotion: product.sizes.promotion ? product.sizes.promotion : product.promotion,
        promotionType: product.sizes.promotionType ? product.sizes.promotionType : product.promotionType,
      };
    }
  });
 
  return (
    <div className="order-item-container bg-white black mb-3 px-3">
      {showDetail && (
        <OrderDetail
          order={order}
          onRepeat={onRepeat}
          exportPdf={exportPdf}
          getDate={getDate}
          setShowDetail={setShowDetail}
        />
      )}
      <div className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} row m-0 w-100 item-content`}>
        <div className={`${DisplayUtils.isMobile() ? 'mobile col-12 mt-4' : 'desktop col-3'} p-0`}>
          <div className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} mt-3`}>
            <span className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} detail-title black`}>
              {t('Account.orders.summary')}
            </span>
            <span className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} detail-info d-block`}>
              {t('Account.orders.orderId')} {order.reference}
            </span>
            <span className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} detail-info d-block`}>
              {t('Account.orders.orderDate')} {getDate(order.date)}
            </span>
            <span className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} detail-info d-block`}>
              {t('Account.orders.orderTotal')} {CartUtils.formatPrice(order.totalPaidTaxIncl.toFixed(2))}
            </span>
          </div>

          <div className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} mt-4`}>
            <span className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} detail-title black`}>
              {t('Account.orders.status')}
            </span>
            <span className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} detail-info d-block`}>
              {t('Account.orders.completed')}
            </span>
          </div>

          <div className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} mt-3`}>
            <span className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} detail-title black d-block`}>
              {t('Account.orders.sendTo')}
            </span>
            <span className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} detail-info d-block`}>
              {order.address.firstname} {order.address.lastname}
            </span>
            <span className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} detail-info d-block`}>
              {order.address.address}
            </span>
            <span className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} detail-info d-block`}>
              {order.address.city} {order.address.postcode}
            </span>
            <span className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} detail-info d-block`}>
              {order.address.state} {order.address.country}
            </span>
          </div>
        </div>
        <div className={`${DisplayUtils.isMobile() ? 'mobile col-12 p-0' : 'desktop col-9 pr-5'}  `}>
          <div className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} mt-3 box-products`}>
            {!DisplayUtils.isMobile() && (
              <div className="row m-0 w-100">
                <div className="desktop justify-content-between d-flex align-items-center col-12 p-0">
                  <div className="col-6 p-0">
                    <span className='desktop detail-title black'>
                      {t('Account.orders.orderProducts')}
                    </span>
                  </div>
                  <div className="col-2 p-0 d-flex justify-content-center">
                    <span className='desktop detail-title-pvpr dbs-gray3 text-center'>
                      {t('Account.orders.orderPvp')}
                    </span>
                  </div>
                  <div className="col-2 p-0 d-flex justify-content-center">
                    <span className='desktop detail-title black text-center'>
                      {t('Account.orders.orderPrice')}
                    </span>
                  </div>
                  <div className="col-2 p-0 d-flex justify-content-center">
                    <span className='desktop detail-title-normal black'>
                      {t('Account.orders.orderQuantity')}
                    </span>
                  </div>
                </div>
              </div>
            )}
            {DisplayUtils.isMobile() && (
              <span className='mobile detail-title black'>
                {t('Account.orders.orderProducts')}
              </span>
            )}
            <div className="mt-2 list-products ">
              {filterProducts.map((product, index) => (
                <OrderProductItem key={index} product={product} />
              ))}
            </div>
          </div>
        </div>
      </div>
      <div
        className={`${
          DisplayUtils.isMobile() ? 'mobile mt-3' : 'desktop justify-content-between'
        } row m-0 box-btn `}
      >
        {!DisplayUtils.isMobile() && (
          <button
            className={`${DisplayUtils.isMobile() ? 'mobile ' : 'desktop see-more-btn'} bg-dbs-gray3 white btn-modal p-0`}
            onClick={() => setShowDetail(true)}
          >
            <span>{t('Account.orders.seeMore')}</span>
          </button>
        )}
        <div className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop justify-content-end'} d-flex`}>
          <button
            className={`${
              DisplayUtils.isMobile() ? 'mobile ' : 'desktop mr-5 repeat-btn'
            } bg-dbs-blue white btn-modal p-0 align-middle`}
            onClick={() => onRepeat(filterProducts)}
          >
            <span>{t('Account.orders.repeat')}</span>
          </button>

          <button
            className={`${DisplayUtils.isMobile() ? 'mobile ' : 'desktop pdf-btn'} bg-dbs-gray1 white btn-modal p-0`}
            onClick={exportPdf}
          >
            <span>{t('Account.orders.pdf')}</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default OrderItem;
