import React from 'react';
import DisplayUtils from '../../../../utils/DisplayUtils';
import './ModalRegister.css';

const ModalRegister = ({ title, description, mainBtnText, mainAction, secondaryBtnText, secondaryAction, closeModal }) => {
  return (
    <div className="registerModal-box d-flex align-items-center">
      <div
        className={`${!DisplayUtils.isMobile() ? 'desktop' : 'mobile'} modal fade`}
        id="registerModal"
        role="dialog"
        aria-labelledby="myModalLabel"
        tabIndex="-1"
        aria-hidden="true"
      >
        <div className={`${!DisplayUtils.isMobile() ? 'desktop' : 'mobile m-0'} register-modal-dialog `} role="document">
          <div className={`${!DisplayUtils.isMobile() ? 'desktop' : 'mobile'} register-modal-content bg-light-b2b`}>
            <div className={`${!DisplayUtils.isMobile() ? 'desktop' : 'mobile'} register-modal-body bg-light-b2b black`}>
              <div className={`${!DisplayUtils.isMobile() ? 'desktop' : 'mobile'} text-box black`}>
                <span className="d-flex justify-content-center title mb-4">{title}</span>
                <span className="mt-4 description">{description}</span>
              </div>
              <div className="row m-0 p-2">
                <div className={`${!DisplayUtils.isMobile() ? 'desktop' : 'mobile'} col-12 d-flex p-0 justify-content-center`}>
                  <button className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} btn-modal bg-secondary-b2b btn-transparent d-flex justify-content-center align-items-center`}onClick={() => mainAction()}>
                    <span className="white">{mainBtnText}</span>
                  </button>
                </div>
                <div className={`${!DisplayUtils.isMobile() ? 'desktop' : 'mobile'} col-12 d-flex p-0 justify-content-center`}>
                  <button className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} btn-modal-skip btn-transparent d-flex justify-content-center align-items-center`}onClick={() => secondaryAction()}>
                    <span>{secondaryBtnText}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalRegister;
