import React, { useReducer, useState } from 'react';
import DisplayUtils from '../../../../utils/DisplayUtils';
import constants from '../../../../utils/constants';
import { useTranslation } from 'react-i18next';
import { Loading, ModalSimple, ModalRegister } from '../../../index';
import { adminService } from '../../../../services/index';
import './RegisterUser.css';

const initialState = {
  formAlias: '',
  formName: '',
  formSurname: '',
  formCommerce: '',
  formCif: '',
  formPhone: '',
  formEmail: '',
  formConfEmail: '',
  formPass: '',
  formConfPass: '',
  errors: {},
};

const reducer = (state, action) => {
  state.errors[action.type] = !action.payload;
  switch (action.type) {
    case 'formAlias':
      return { ...state, formAlias: action.payload };
    case 'formName':
      return { ...state, formName: action.payload };
    case 'formSurname':
      return { ...state, formSurname: action.payload };
    case 'formCommerce':
      return { ...state, formCommerce: action.payload };
    case 'formCif':
      return { ...state, formCif: action.payload };
    case 'formPhone':
      return { ...state, formPhone: action.payload };
    case 'formEmail':
      return { ...state, formEmail: action.payload };
    case 'formConfEmail':
      return { ...state, formConfEmail: action.payload };
    case 'formPass':
      return { ...state, formPass: action.payload };
    case 'formConfPass':
      return { ...state, formConfPass: action.payload };
    default:
      return initialState;
  }
};

const RegisterUser = ({ userId, showAddresses }) => {
  const { t } = useTranslation();

  const [state, dispatch] = useReducer(reducer, initialState);
  const [loading, setLoading] = useState(false);
  const [registerErrorModal, setRegisterErrorModal] = useState(false);
  const [registerOkModal, setRegisterOkModal] = useState(false);

  const emptyError = t('Errors.empty');
  const format = t('Errors.notValid');
  const emailNotEqual = t('Errors.emailNotEqual');
  const passNotEqual = t('Errors.passNotEqual');

  const isValid = () => {
    return (
      Object.values(state).some(item => item === '') ||
      !constants.EMAIL_PATTERN.test(state.formEmail) ||
      state.formEmail !== state.formConfEmail ||
      state.formPass.length < 6 ||
      state.formPass !== state.formConfPass ||
      !constants.NAME_PATTERN.test(state.formName) ||
      !constants.NAME_PATTERN.test(state.formSurname)
    );
  };

  const handleSubmit = async event => {
    setLoading(true);
    dispatch({ type: 'initialState' });
    event.preventDefault();
    const newUser = {
      alias: state.formAlias,
      firstname: state.formName,
      lastname: state.formSurname,
      company: state.formCommerce,
      taxId: state.formCif,
      phone: state.formPhone,
      email: state.formEmail,
      password: state.formPass,
    };
    try {
      const data = await adminService.createUser(newUser);
      if (data) {
        userId(data.id);
        setLoading(false);
        showRegisterOkModal();
      }
    } catch (error) {
      setLoading(false);
      showRegisterErrorModal();
    }
  };

  const goToAddresses = () => {
    showAddresses(true);
    hideRegisterOkModal();
  };

  const showRegisterOkModal = async () => {
    await setRegisterOkModal(true);
    window.$('#registerModal').modal('show');
  };

  const hideRegisterOkModal = () => {
    setRegisterOkModal(false);
    window.$('#registerModal').modal('hide');
    window.$('.modal-backdrop').remove();
    window.$('body').removeClass('modal-open');
  };

  const showRegisterErrorModal = async () => {
    await setRegisterErrorModal(true);
    window.$('#simpleModal').modal('show');
  };

  const hideRegisterErrorModal = () => {
    setRegisterErrorModal(false);
    window.$('#simpleModal').modal('hide');
    window.$('.modal-backdrop').remove();
    window.$('body').removeClass('modal-open');
  };
  const inputs = [
    'formAlias',
    'formName',
    'formSurname',
    'formCommerce',
    'formCif',
    'formPhone',
    'formEmail',
    'formConfEmail',
    'formPass',
    'formConfPass',
  ];

  return loading ? (
    <Loading transparent />
  ) : (
    <div className={`${DisplayUtils.isMobile() ? 'mobile ' : 'desktop '} admin-register-container bg-light-b2b`}>
      <div className="admin-register-content">
        <div className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop pb-3'} admin-register-text`}>
          <span className="title d-block mb-2">{t('AdminPanel.register.title')}</span>
        </div>
        <div className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} row m-0 admin-register-form`}>
          {inputs.map((item, index) => (
            <div className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} col-12 p-0`} key={index}>
              <input
                name={item}
                value={state[item]}
                type={index > 7 ? 'password' : 'text'}
                placeholder={t(`RegisterForm.${item}`)}
                className={`${state.errors[item] && 'is-invalid'} 
              ${index <= 2 && state[item] && !constants.NAME_PATTERN.test(state[item]) && 'is-invalid'}
                form-control w-100`}
                onBlur={e => dispatch({ type: e.target.name, payload: e.target.value })}
                onChange={e => dispatch({ type: e.target.name, payload: e.target.value })}
                autoComplete="off"
              />
              {state.errors[item] ? (
                <span className="red">{t('Errors.empty')}</span>
              ) : (
                index <= 2 &&
                state[item] &&
                !constants.NAME_PATTERN.test(state[item]) && <span className="red">{format}</span>
              )}
              {(item === 'formEmail' || item === 'formConfEmail') &&
                (state.errors[item] ? (
                  <span className="red">{emptyError}</span>
                ) : state[item] && !constants.EMAIL_PATTERN.test(state[item]) ? (
                  <span className="red">{format}</span>
                ) : (
                  state.formEmail !== state.formConfEmail && <span className="red">{emailNotEqual}</span>
                ))}

              {index > 7 && state.formPass !== state.formConfPass && <span className="red">{passNotEqual}</span>}
            </div>
          ))}
        </div>
        <button
          onClick={e => handleSubmit(e)}
          disabled={isValid()}
          className={`${
            DisplayUtils.isMobile() ? 'mobile' : 'desktop ml-auto'
          } white bg-dark-b2b admin-register-button btn-transparent align-items-center d-flex justify-content-center`}
        >
          <span>{t('AdminPanel.register.save')}</span>
        </button>
      </div>
      {registerErrorModal && (
        <ModalSimple
          title={t('RegisterClientModal.title')}
          description={t('RegisterClientModal.descriptionError')}
          mainBtnText={t('Globals.ok')}
          mainAction={hideRegisterErrorModal}
          closeModal={hideRegisterErrorModal}
        />
      )}
      {registerOkModal && (
        <ModalRegister
          title={t('RegisterClientModal.titleOk')}
          description={t('RegisterClientModal.descriptionOk')}
          mainBtnText={t('RegisterClientModal.next')}
          mainAction={goToAddresses}
          secondaryBtnText={t('RegisterClientModal.skip')}
          secondaryAction={hideRegisterOkModal}
          closeModal={hideRegisterOkModal}
        />
      )}
    </div>
  );
};

export default RegisterUser;
