import React, { useEffect, useState } from 'react';
import constants from '../utils/constants';

export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  const [currentToken, setCurrentToken] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [currentCart, setCurrentCart] = useState([]);
  const [modalCart, setModalCart] = useState(false);
  const [currentSearchWord, setCurrentSearchWord] = useState(null);
  const [selectedWord, setSelectedWord] = useState(null);
  const [promotionsCurrentSearchWord, setPromotionsCurrentSearchWord] = useState(null);
  const [promotionsSelectedWord, setPromotionsSelectedWord] = useState(null);

  useEffect(() => {
    setCurrentToken(localStorage.getItem(constants.CURRENT_TOKEN_KEY));
    setCurrentCart(JSON.parse(localStorage.getItem(constants.CURRENT_CART_KEY)));
  }, [setCurrentCart, setCurrentToken]);

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        currentCart,
        setCurrentUser,
        setCurrentCart,
        currentToken,
        setCurrentToken,
        modalCart,
        setModalCart,
        currentSearchWord,
        setCurrentSearchWord,
        selectedWord,
        setSelectedWord,
        promotionsCurrentSearchWord,
        setPromotionsCurrentSearchWord,
        promotionsSelectedWord,
        setPromotionsSelectedWord
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
