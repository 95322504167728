import React, { useContext } from 'react';
import { withRouter } from 'react-router-dom';
import DisplayUtils from '../../../../utils/DisplayUtils';
import { useTranslation } from 'react-i18next';
import './CartModal.css';
import { Link } from 'react-router-dom';
import { CartModalProduct } from '../../../index';
import CartUtils from '../../../../utils/CartUtils';
import { AuthContext } from '../../../../contexts/AuthContext';

const CartModal = ({ closeModal, goCart, cartLocal, history, returnShop }) => {
  const { t } = useTranslation();

  const { currentCart, setCurrentCart } = useContext(AuthContext);

  const deleteProduct = product => {
    CartUtils.deleteToCart(currentCart, setCurrentCart, product);
  };

  const total =
    cartLocal && cartLocal.length > 0
      ? cartLocal
          .map(
            product =>
              product.quantity * CartUtils.getPricePromotion(product.price, product.promotion, product.promotionType, product.expiration)
          )
          .reduce((a, b) => a + b)
          .toFixed(2)
      : 0;

  const totalArticle =
    cartLocal && cartLocal.length > 0 ? cartLocal.map(product => product.quantity).reduce((a, b) => a + b) : 0;


  const listBrands = cartLocal && cartLocal.length > 0 && cartLocal.map(product => product.nameManufacturer)
    
  const totalBrands = listBrands ? listBrands.filter((item,index) => listBrands.indexOf(item) === index) : []
      

  const btnMore = () => {
    closeModal();
    const currentPath = history.location.pathname;
    currentPath === '/product-detail' && history.goBack(-1)
  };

  return (
    <div className="cartModal-box">
      <div
        className={`${!DisplayUtils.isMobile() ? 'desktop' : 'mobile'} modal fade`}
        id="cartModal"
        role="dialog"
        aria-labelledby="myModalLabel"
        tabIndex="-1"
        aria-hidden="true"
      >
        <div className={`${!DisplayUtils.isMobile() ? 'desktop' : 'mobile'} modal-dialog m-0`} role="document">
          {cartLocal && cartLocal.length > 0 ? (
            <div
              className={`${!DisplayUtils.isMobile() ? 'desktop' : 'mobile'} modal-content ${
                totalArticle && cartLocal.length > 2 ? 'box-products' : ''
              }`}
            >
              <div className={`${!DisplayUtils.isMobile() ? 'desktop' : 'mobile'} modal-body p-0`}>
                <div
                  className={`${
                    !DisplayUtils.isMobile() ? 'desktop p-2' : 'mobile'
                  } list-product-box d-flex flex-column align-items-start ${
                    totalArticle && cartLocal.length > 2 ? '' : ''
                  } black`}
                >
                  <div className="w-100 list-products-content">
                    {cartLocal &&
                      cartLocal.map(item => (
                        <CartModalProduct item={item} key={item.id} deleteProduct={deleteProduct} modal/>
                      ))}
                  </div>
                </div>
                <div className={`cart-buttons py-4 ${totalArticle && cartLocal.length > 2 ? 'h-40' : ''}`}>
                  <div className={`${!DisplayUtils.isMobile() ? 'desktop' : 'mobile'} info-cart-articles row m-0`}>
                    <div className="row m-0 w-100">
                      <div className="col-3 p-0"><span className="dbs-black">{t('CartDetail.brands')}</span></div>
                      <div className="col-9 p-0"><span className="dbs-black">{totalBrands.length}</span></div>
                    </div>
                    <div className="row m-0 w-100">
                      <div className="col-3 p-0"><span className="dbs-black">{t('CartDetail.articles')}</span></div>
                    <div className="col-9 p-0"><span className="dbs-black">{currentCart.length}</span></div>
                    </div>
                    <div className="row m-0 w-100">
                      <div className="col-3 p-0"><span className="dbs-black">{t('CartDetail.units')}</span></div>
                      <div className="col-9 p-0"><span className="dbs-black">{totalArticle}</span></div>
                    </div>
                  </div>
                  <div className={`${!DisplayUtils.isMobile() ? 'desktop' : 'mobile'} info-cart-buttons row m-0`}>
                    <div className="col-8 p-2">
                      <span
                        className={`${
                          !DisplayUtils.isMobile() ? 'desktop pl-3' : 'mobile ml-3'
                        } d-flex justify-content-start`}
                      >
                        {t('CartModal.subtotal').toUpperCase()}
                      </span>
                      <span
                        className={`${
                          !DisplayUtils.isMobile() ? 'pl-3 desktop' : 'mobile ml-3'
                        } d-flex justify-content-start total`}
                      >
                        {t('CartModal.total').toUpperCase()}
                      </span>
                    </div>
                    <div className="col-4 p-2 ">
                      <span
                        className={`${
                          !DisplayUtils.isMobile() ? 'px-3 desktop' : 'mobile mx-3'
                        } d-flex justify-content-end`}
                      >
                        {/* {CartUtils.getPriceNotTax(total)}€ */}
                        {CartUtils.formatPrice(total)}
                      </span>
                      <span
                        className={`${
                          !DisplayUtils.isMobile() ? 'px-3 desktop' : 'mobile mx-3'
                        } d-flex justify-content-end total`}
                      >
                        {/* {total}€ */}
                        {CartUtils.formatPrice(CartUtils.getPriceWithTax(total))}
                      </span>
                    </div>
                  </div>
                  <div className="row m-0 ">
                    <div
                      className={`${!DisplayUtils.isMobile() ? 'desktop' : 'mobile'} d-flex justify-content-end col-6`}
                    >
                      <button
                        onClick={btnMore}
                        className={`${
                          !DisplayUtils.isMobile() ? 'desktop' : 'mobile'
                        } btn-cart continue bg-dbs-gray1 d-flex justify-content-center align-items-center`}
                      >
                        <span className="white">{t('CartModal.more')}</span>
                      </button>
                    </div>
                    <div
                      className={`${
                        !DisplayUtils.isMobile() ? 'desktop' : 'mobile'
                      } d-flex justify-content-start col-6`}
                    >
                      <Link
                        to={{
                          pathname: '/cart-detail',
                          state: { returnShop: returnShop },
                        }}
                        className="black"
                      >
                        <button
                          onClick={event => goCart(event)}
                          className={`${
                            !DisplayUtils.isMobile() ? 'desktop' : 'mobile'
                          } btn-cart go-cart bg-dbs-blue d-flex justify-content-center align-items-center`}
                        >
                          <span className="white">{t('CartModal.finish')}</span>
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div
              className={`${
                !DisplayUtils.isMobile() ? 'desktop justify-content-center ' : 'mobile justify-content-start'
              } bg-light-b2b d-flex align-items-center flex-column box-empty`}
            >
              {DisplayUtils.isMobile() && (
                <div
                  className={`${
                    DisplayUtils.isMobile() ? 'mobile' : 'desktop'
                  } btn-close d-flex justify-content-center align-items-center`}
                  onClick={() => closeModal()}
                >
                  <img alt="closebtn" src="/misc/icons/add_black.png" />
                </div>
              )}
              <img src="/misc/icons/deliver_blue.svg" alt="bag" className="cart-icon" />
              <h2 className="black">{t('CartModal.empty')}</h2>
              <span>{t('CartModal.description')}</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default withRouter(CartModal);
