import React from 'react';
import { OrderProductDetailItem } from '../../../index';
import DisplayUtils from '../../../../utils/DisplayUtils';
import CartUtils from '../../../../utils/CartUtils';
import { useTranslation } from 'react-i18next';
import './OrderDetail.css';

const OrderDetail = ({ order, getDate, setShowDetail, exportPdf, onRepeat }) => {
  const { t } = useTranslation();

  

  const totalUnits =
    order.products && order.products.length > 0
      ? order.products.map(product => product.quantity).reduce((a, b) => a + b)
      : 0;

      const listBrands = order.products && order.products.length > 0 && order.products.map(product => product.nameManufacturer)
    
      const totalBrands = listBrands ? listBrands.filter((item,index) => listBrands.indexOf(item) === index) : []
      const filterProducts =
      order.products &&
      order.products.map(product => {
        if (!product.sizes || !product.sizes.name) {
          return product;
        } else {
          return {
            ...product,
            id: product.sizes.id ? product.id + '.' + product.sizes.id : product.id,
            name: product.sizes.name ? product.name + ' ' + product.sizes.name : product.name,
            price: product.sizes.price ? product.sizes.price : product.price,
            pricepvp: product.sizes.pricepvp ? product.sizes.pricepvp : product.pricepvp,
            images: product.sizes.image ? [product.sizes.image] : product.images,
            promotion: product.sizes.promotion ? product.sizes.promotion : product.promotion,
            promotionType: product.sizes.promotionType ? product.sizes.promotionType : product.promotionType,
          };
        }
      });
    
      
  return (
    <div className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop d-flex justify-content-center'} full-screen-detail`}>
      <div className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} detail-content bg-dbs-white`}>
        {DisplayUtils.isMobile() ? (
          <button onClick={() => setShowDetail(false)} className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} `}>
            <img alt="closebtn" className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} close-btn bg-dbs-gray5`} src="/misc/icons/add_black.png" onClick={() => setShowDetail(false)}/>
          </button>
        ) : (
          <img alt="closebtn" className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} close-btn bg-dbs-gray5`} src="/misc/icons/add_black.png" onClick={() => setShowDetail(false)}/>
        )}
        <div>
          <div className="row m-0 w-100 mb-3">
            <div className="col-4 pl-0 pr-1">
              <div className="data-container">
                <span className="title">{t('Account.orders.orderDetailAddress').toUpperCase()}</span>
                <div className="address-info">
                <span className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} detail-info d-block`}>
                    {order.address.alias}
                  </span>
                  <span className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} detail-info d-block`}>
                    {order.address.address}
                  </span>
                  <span className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} detail-info d-block`}>
                  {order.address.postcode} {order.address.city}
                  </span>
                  <span className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} detail-info d-block`}>
                    {order.address.state} {order.address.country}
                  </span>
                </div>
              </div>
            </div>
            <div className="col-4 p-0">
              <div className="data-container">
                <span className="title">{t('Account.orders.orderDetailSummary').toUpperCase()}</span>
                <div className="summary-info">
                    <div className="info-row">
                      <span className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} detail-info title d-inline-block`}>
                        {t('Account.orders.orderDetailNumber')}
                      </span>
                      <span className="detail-info font-weight-bold">{order.reference}</span>
                    </div>
                    <div className="info-row">
                      <span className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} detail-info title d-inline-block`}>
                        {t('Account.orders.orderDetailDate')}
                      </span>
                      <span className="detail-info">{getDate(order.date)}</span>
                    </div>
                    <div className="info-row">
                      <span className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} detail-info title d-inline-block`}>
                        {t('Account.orders.orderDetailBruto')}
                      </span>
                      <span className="detail-info font-weight-bold">{CartUtils.formatPrice(order.totalPaidTaxExcl.toFixed(2))}*</span>
                    </div>
                </div>
                <span className="more-info">{t('Account.orders.orderDetailInfo1')}</span>
              </div>
            </div>
            <div className="col-4 pr-0 pl-1">
              <div className="data-container">
                <span className="title">{t('Account.orders.orderDetailStatus').toUpperCase()}</span>
                <div className="status-info">
                  <span className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} detail-info d-block`}>
                    {order.state}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <span className="title">{t('Account.orders.orderDetailTitle').toUpperCase()}</span>
          <div className="mt-5 w-100">
            <div className="row w-100 m-0 justify-content-between d-flex align-items-center order-details-header">
              <div className="col-1 p-0"></div>
              <div className="col-4 p-0">{t('Account.orders.orderDetailDescription')}</div>
              <div className="col-2 p-0 d-flex justify-content-center dbs-gray3">{t('Account.orders.orderDetailPvpr')}</div>
              <div className="col-1 p-0 d-flex justify-content-center">{t('Account.orders.orderDetailQuantity')}</div>
              <div className="col-2 p-0 d-flex justify-content-center">{t('Account.orders.orderDetailPrice')}</div>
              <div className="col-2 p-0 d-flex justify-content-center font-weight-bold">{t('Account.orders.orderDetailPriceTotal')}</div>
            </div>
            {filterProducts.map((product, index) => (
              <OrderProductDetailItem key={index} product={product} />
            ))}
          </div>
          <div className="additional-info row m-0 w-100">
            <div className="col-1 p-0"></div>
            <div className="col-6 p-0 articles-info">
              <div className="w-100">
                <span className="articles-info-title d-inline-flex">{t('CartDetail.brands').toUpperCase()}</span>
                <span className="articles-info-data d-inline-flex">{totalBrands.length}</span>

              </div>
              <div className="w-100">
                <span className="articles-info-title d-inline-flex">{t('CartDetail.articles').toUpperCase()}</span>
                <span className="articles-info-data d-inline-flex">{order.products.length}</span>
              </div>
              <div className="w-100">
                <span className="articles-info-title d-inline-flex">{t('CartDetail.units').toUpperCase()}</span>
                <span className="articles-info-data d-inline-flex">{totalUnits}</span>
              </div>
            </div>
            <div className="col-5 p-0 prices-info">
              <div className="w-100 d-flex justify-content-end">
                <span className="prices-info-title d-inline-flex">{t('Account.orders.orderDetailSubtotal').toUpperCase()}</span>
                <span className="prices-info-data d-inline-flex">{CartUtils.formatPrice(order.totalPaidTaxExcl.toFixed(2))}*</span>
              </div>
              <div className="w-100 d-flex justify-content-end">
                <span className="prices-info-title total-title d-inline-flex font-weight-bold align-items-end">{t('Account.orders.orderDetailTotal').toUpperCase()}</span>
                <span className="prices-info-data total-data d-inline-flex font-weight-bold align-items-end">{CartUtils.formatPrice(order.totalPaidTaxIncl.toFixed(2))}</span>
              </div>
            </div>
          </div>
          <div className="w-100">
            <p className="footer-info dbs-gray3">{t('Account.orders.orderDetailInfo1')}</p>
            <p className="footer-info dbs-gray3">{t('Account.orders.orderDetailInfo2')}</p>
          </div>
          <div className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop justify-content-center'} d-flex mt-4`}>
            <button className={`${DisplayUtils.isMobile() ? 'mobile ' : 'desktop mr-5 pdf-btn'} bg-dbs-blue white btn-modal p-0`} onClick={exportPdf}>
              <span>{t('Account.orders.pdf')}</span>
            </button>
            <button className={`${DisplayUtils.isMobile() ? 'mobile ' : 'desktop repeat-btn'} bg-dbs-gray1 white btn-modal p-0 align-middle`}onClick={() => onRepeat(filterProducts)}>
              <span>{t('Account.orders.repeat')}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderDetail;
