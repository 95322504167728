import React, { useContext, useState, useEffect, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { BackBtn, CartItem, ModalDouble, ModalError, Loading, ModalSimple } from '../index';
import { AuthContext } from '../../contexts/AuthContext';
import DisplayUtils from '../../utils/DisplayUtils';
import CartUtils from '../../utils/CartUtils';
import { orderService } from '../../services/index';
import './CartDetail.css';
import { useTranslation } from 'react-i18next';

const CartDetail = ({ history }) => {
  const { t } = useTranslation();

  const { currentCart, currentUser } = useContext(AuthContext);
  const [totalCart, setTotalCart] = useState(0);
  const [deliveryPrice, setDeliveryPrice] = useState(null);
  const [selectedCarrier, setSelectedCarrier] = useState(null);
  const [confirmOrder, setConfirmOrder] = useState(false);
  const [showError, setShowError] = useState(false);
  const [minimumError, setMinimumError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [ listCarriers, setListCarriers] = useState([])
  const [selectedAddress, setSelectedAddress] = useState(null)
  const [message, setMessage ] = useState('');
  const [ noAddress, setNoAddress] = useState(null)

  useEffect(() => {
    let unmounted = false;
    if(!unmounted) {
      const getCarriers = async () => {
        try {
          const response = await orderService.getCarriers()
          setListCarriers(response)
          setLoading(false)
        } catch (error) {
          showErrorModal()
          setLoading(false)
        }
      }
      getCarriers()
    }
    return () => {
      unmounted = true
    }
  }, []);

  useEffect(() => {
    setTotalCart(CartUtils.getTotalCart(currentCart) + deliveryPrice);
  }, [deliveryPrice, currentCart])

  const screenHeight = window.innerHeight - (DisplayUtils.isMobile() ? 140 : 160);

  const updateTotal = () => {
    setTotalCart(CartUtils.getTotalCart(currentCart) + deliveryPrice);
  };

  const showDoubleModal = async () => {
    await setConfirmOrder(true);
    window.$('#doubleModal').modal('show');
  };

  const hideDoubleModal = async () => {
    await setConfirmOrder(false);
    window.$('#doubleModal').modal('hide');
    window.$('.modal-backdrop').remove();
    window.$('body').removeClass('modal-open');
  };
  const showErrorModal = async () => {
    await setShowError(true);
    window.$('#errorModal').modal('show');
  };
  const hideErrorModal = () => {
    setShowError(false);
    window.$('#errorModal').modal('hide');
    window.$('.modal-backdrop').remove();
    window.$('body').removeClass('modal-open');
  };

  const processOrder = async () => {
    setLoading(true)
    const data = {
      addressId: selectedAddress ? selectedAddress : currentUser.addresses[0].idAddress,
      totalPrice: totalCart,
      carrierId: !selectedCarrier ? listCarriers[0] && listCarriers[0].id : selectedCarrier.id,
      message: message,
      products: currentCart.map(item => {
        return {
          id: item.id,
          quantity: item.quantity,
        };
      }),
    };

    hideDoubleModal();
    try {
      const response = await orderService.addOrder(data);
      if (response) {
        setLoading(false);
        history.push('/order-success');
      }
    } catch (error) {
      setLoading(false);
      showErrorModal()
    }
  };

  const checkCart = () => {
    if(currentUser && currentUser.addresses && currentUser.addresses.length === 0){
      showAddressErrorModal()
    } else if(totalCart < 170) {
      showMinimumErrorModal();
    } else {
      showDoubleModal();
    }
  }

  const selectDelivery = (e, carrier) => {
    e.stopPropagation()
    setSelectedCarrier(carrier)
    carrier.price ? setDeliveryPrice(carrier.price) : setDeliveryPrice(0)
  };
  const onBack = history.location.state && history.location.state.returnShop;

  const totalUnits =
    currentCart && currentCart.length > 0
      ? currentCart.map(product => product.quantity).reduce((a, b) => a + b)
      : 0;

  const showMinimumErrorModal = async () => {
    await setMinimumError(true);
    window.$('#simpleModal').modal('show');
  };

  const hideMinimumErrorModal = () => {
    setMinimumError(false);
    window.$('#simpleModal').modal('hide');
    window.$('.modal-backdrop').remove();
    window.$('body').removeClass('modal-open');
  };
  const showAddressErrorModal = async () => {
    await setNoAddress(true);
    window.$('#simpleModal').modal('show');
  };

  const hideAddressErrorModal = () => {
    setNoAddress(false);
    window.$('#simpleModal').modal('hide');
    window.$('.modal-backdrop').remove();
    window.$('body').removeClass('modal-open');
  };

  const redirectTo = (to) => {
    if(to === 'shop'){
      hideMinimumErrorModal()
      history.push('/shop')

    } else {
      hideAddressErrorModal()
      history.push('/contactus')
    }
  }

  const listBrands = currentCart && currentCart.length > 0 && currentCart.map(product => product.nameManufacturer)
    
  const totalBrands = listBrands ? listBrands.filter((item,index) => listBrands.indexOf(item) === index) : []

  return (
    <div className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} cart-detail-container`}>
      {showError && <ModalError hideErrorModal={hideErrorModal} />}
      {confirmOrder && (
        <ModalDouble
          title={t('CartDetail.modalProcess')}
          description={t('CartDetail.description')}
          mainBtnText={t('Globals.yes')}
          secondaryBtnText={t('Globals.no')}
          mainAction={processOrder}
          secondaryAction={hideDoubleModal}
          closeModal={hideDoubleModal}
        />
      )}
      {minimumError && (
        <ModalSimple
          title={t('CartDetail.minimumErrorTitle', { amount: CartUtils.formatPrice(170 - totalCart) })}
          description={t('CartDetail.minimumErrorDescription')}
          mainBtnText={t('CartDetail.minimumErrorBtn')}
          mainAction={() => redirectTo('shop')}
          closeModal={hideMinimumErrorModal}
        />
      )}
      {noAddress && (
        <ModalSimple
          title={t('CartDetail.addressErrorTitle')}
          description={t('CartDetail.addressErrorDescription')}
          mainBtnText={t('CartDetail.addressErrorBtn')}
          mainAction={redirectTo}
          closeModal={hideAddressErrorModal}
        />
      )}
      {loading ? (
        <div style={{ height: '100vh' }}>
          <Loading white />
        </div>
      ) : currentUser ? (
        <div
          className={`${currentCart && currentCart.length > 0 ? '' : 'empty-cart'} row m-0 cart-detail-parts bg-secondary-b2b`}
          style={DisplayUtils.isMobile() ? (currentCart && currentCart.length > 0 ? {} : { height: screenHeight }) : { height: screenHeight }}
        >
          {currentCart && currentCart.length > 0 ? (
            <Fragment>
              <div
                className={`${
                  DisplayUtils.isMobile() ? 'mobile col-12' : 'desktop col-8'
                } cart-detail-products bg-dbs-white black`}
                style={DisplayUtils.isMobile() ? {} : { height: screenHeight }}
              >
                <div className="row m-0 w-100">
                  <div className="col-1 p-0"/>
                  <h4 className="dbs-blue">{t('CartDetail.yourCart')}</h4>
                </div>
                <div className="row m-0 w-100">
                  <div className={`${DisplayUtils.isMobile() ? 'mobile pr-0 col-5' : 'desktop justify-content-start col-6'} d-flex align-items-center p-0`}></div>
                  <div className={`${DisplayUtils.isMobile() ? 'mobile col-7 p-0' : 'desktop pl-2 col-6'} h-100 m-0 d-flex align-items-center justify-content-between box-titles`}>
                    <div className={`${DisplayUtils.isMobile() ? 'mobile mobile-flex' : 'desktop d-flex justify-content-between'} `}>
                      <span className={`${DisplayUtils.isMobile() ? 'mobile text-center' : 'desktop'} product-price`}>
                        {DisplayUtils.isMobile() ? t('CartDetail.price') : t('CartDetail.priceTag')}
                      </span>
                    </div>
                    <div className="d-inline-flex align-items-center">
                      <span className={`${DisplayUtils.isMobile() ? 'mobile text-center' : 'desktop'} product-quantity`}>
                        {t('CartDetail.quantity')}
                      </span>
                    </div>
                    <div style={{height:"30px", width:"30px"}}/>
                  </div>
                </div>
                <div className="separator bg-dbs-gray4" />
                {currentCart.map((product, index) => (
                  <div key={index}>
                    <CartItem key={index} product={product} updateTotal={updateTotal} />
                    <div className="separator bg-dbs-gray4" />
                  </div>
                ))}
              </div>
              <div
                className={`${
                  DisplayUtils.isMobile() ? 'mobile col-12' : 'desktop col-4'
                } cart-detail-info bg-dbs-gray4 white p-0`}
                style={DisplayUtils.isMobile() ? {} : { height: screenHeight }}
              >
                <div className="info-box bg-dbs-gray5">
                  <h5 className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} dbs-black`}>
                    {t('CartDetail.process')}
                  </h5>

                  <span className="d-flex flex-column main-blue">{t('CartDetail.deliveryType')}</span>
                  {listCarriers &&
                    listCarriers.map((carrier, index) => (
                      <div
                        key={index}
                        className={`${
                          !DisplayUtils.isMobile() ? 'desktop' : 'mobile'
                        } checkbox-black checkbox-carriers mt-3 d-flex align-items-center`}
                      >
                        <input
                          type="radio"
                          name="exampleRadios"
                          id="exampleRadios1"
                          defaultChecked={index === 0}
                          onClick={e => selectDelivery(e, carrier)}
                        />
                        <label className="form-check-label dbs-black ml-2">
                          <span className="d-block">{carrier.name}</span>
                        </label>
                      </div>
                    ))}
                  <div className="ml-4">
                    <span className="dbs-gray3">{t('CartDetail.minCart')}</span>
                  </div>
                  <hr className="bg-dbs-gray4" />
                  <span className="d-flex flex-column main-blue mb-3">{t('CartDetail.deliveryAddress')}</span>
                  {currentUser && currentUser.addresses && currentUser.addresses.length > 0 ? (
                    currentUser.addresses.map((address, index) => (
                      <div
                        key={index}
                        className={`${
                          !DisplayUtils.isMobile() ? 'desktop' : 'mobile'
                        } mb-2 checkbox-black d-flex align-items-center`}
                      >
                        <input
                          type="radio"
                          name="addressRadio"
                          id="addressRadios1"
                          value={address.id}
                          defaultChecked={index === 0}
                          onClick={() => setSelectedAddress(address.idAddress)}
                        />
                        <label className="form-check-label dbs-black ml-2">
                          <span>{address.alias}</span>
                        </label>
                      </div>
                    ))
                  ) : (
                    <span style={{fontSize: '12px'}} className="dbs-black">
                      {t('CartDetail.emptyAddress')}
                    </span>
                  )}
                  <hr className="bg-dbs-gray4 mb-4" />
                  <div className="row m-0 w-100">
                    <div className="col-4 p-0"><span className="dbs-black">{t('CartDetail.brands')}</span></div>
                    <div className="col-8 p-0"><span className="dbs-black">{totalBrands.length}</span></div>
                  </div>
                  <div className="row m-0 w-100">
                    <div className="col-4 p-0"><span className="dbs-black">{t('CartDetail.articles')}</span></div>
                    <div className="col-8 p-0"><span className="dbs-black">{currentCart.length}</span></div>
                  </div>
                  <div className="row m-0 w-100">
                    <div className="col-4 p-0"><span className="dbs-black">{t('CartDetail.units')}</span></div>
                    <div className="col-8 p-0"><span className="dbs-black">{totalUnits}</span></div>
                  </div>
                  <div className="row m-0 w-100">
                    <div className="col-9 p-0">
                      {/* {selectedCarrier ? 
                        <span className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} d-flex flex-column info-subtotal mt-3 dbs-black carrier`}>{selectedCarrier.name.toUpperCase()}</span> 
                        :  */}
                        <span className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} d-flex flex-column info-subtotal mt-3 dbs-black carrier`}>ENVÍO</span>
                      {/* } */}
                    </div>
                    <div className="col-3 p-0">
                      <span className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} d-flex flex-column info-subtotal data mt-3 dbs-black carrier`}>
                      {selectedCarrier ? 
                        (selectedCarrier.price && selectedCarrier.price > 0 ? `${CartUtils.formatPrice(selectedCarrier.price.toFixed(2))}` : 'GRATUITO')
                        : 
                        (listCarriers[0] && listCarriers[0].price && listCarriers[0].price > 0 ? `${CartUtils.formatPrice(listCarriers[0].price.toFixed(2))}` : 'GRATUITO')
                      }
                      </span>
                    </div>
                  </div>
                  <div className="row m-0 w-100">
                    <div className="col-9 p-0">
                      <span className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} d-flex flex-column info-subtotal mt-3 dbs-black`}>
                        {t('CartModal.subtotal').toUpperCase()}
                      </span>
                    </div>
                    <div className="col-3 p-0">
                      <span className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} d-flex flex-column info-subtotal data mt-3 dbs-black`}>
                        {CartUtils.formatPrice(totalCart.toFixed(2))}*
                      </span>
                    </div>
                  </div>
                  <div className="row m-0 w-100">
                    <div className="col-8 p-0">
                      <span className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} d-flex flex-column info-total mt-3 dbs-black`}>
                        {t('CartDetail.total').toUpperCase()}
                      </span>
                    </div>
                    <div className="col-4 p-0">
                      <span className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} d-flex flex-column info-total data mt-3 dbs-black`}>
                        {CartUtils.formatPrice(CartUtils.getPriceWithTax(totalCart))}
                      </span>
                    </div>
                  </div>
                  <hr className="bg-dbs-gray4 mb-0" />
                  <div className="row m-0 w-100 d-flex justify-content-end">
                    <span className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} info-subtotal-text`}>
                      {t('Account.orders.orderDetailInfo1')}
                    </span>
                  </div>
                  <div className="row m-0 w-100">
                    <div className="col-12 p-0">
                      <span className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} d-flex flex-column mt-3 dbs-black`}>
                        {t('CartDetail.info')}
                      </span>
                    
                      <textarea
                        name="formMessage"
                        value={message}
                        // className={`${state.errors.formMessage && 'is-invalid'} w-100`}
                        
                        onChange={(e) => setMessage(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="button-box d-flex align-items-end btn-accept-order">
                  <button
                    className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} next-btn bg-secondary-b2b`}
                      onClick={checkCart}
                      // disabled={currentUser && currentUser.addresses && currentUser.addresses.length === 0}
                  >
                    {t('CartDetail.make')}
                  </button>
                </div>
              </div>
            </Fragment>
          ) : (
            <div
              className={`${
                !DisplayUtils.isMobile() ? 'desktop justify-content-center ' : 'mobile justify-content-center'
              } w-100 bg-light-b2b d-flex align-items-center flex-column box-empty h-100`}
            >
              <div>
                <img src="/misc/icons/deliver_blue.svg" alt="bag" className="" />
                <h2 className="black">{t('CartModal.empty')}</h2>
                <span>{t('CartModal.description')}</span>
              </div>
            </div>
          )}
          <BackBtn returnShop={onBack} />
        </div>
      ) : (
        <div style={{ height: '550px' }}>
          <Loading white />
        </div>
      )}
    </div>
  );
};

export default withRouter(CartDetail);
