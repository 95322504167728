import React from 'react';
import DisplayUtils from '../../../../utils/DisplayUtils';
import { useTranslation } from 'react-i18next';
import constants from '../../../../utils/constants';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';
import './ModalPromotions.css';

const ModalPromotions = ({ title, description, mainBtnText, currentUser, closeModal, listPromos }) => {
  const { t } = useTranslation();
  
  const carouselSettings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    swipe: true,
  };

  const getRoute = item => item.split('?')[0];
  const itemId = item => item.split('=')[1];

  const goRoute = item => {
    const route = getRoute(item);
    closeModal()
    route === '/shop' && localStorage.removeItem(constants.CURRENT_FILTERS_KEY);
  };

  const sliderPromos = listPromos.map((item, index) => (
    <div className="bg-dbs-white slider-promos" key={index}>
      <div className={`${!DisplayUtils.isMobile() ? ' desktop' : ' mobile'} my-4 title-promo`}>
        <span className="dbs-blue">{item.mediaTitle}</span>
      </div>
      <img src={item.mediaSource} alt={index} className="w-100" />
      <div className={`${!DisplayUtils.isMobile() ? ' desktop p-4' : ' mobile p-1'} row m-0 `}>
        {item.mediaContent &&
        <div
          className={`${!DisplayUtils.isMobile() ? ' desktop py-2 pl-2 pr-3' : ' mobile p-2'} d-flex justify-content-end col-6 `}
        >
          <Link
            key={index}
            className=""
            to={{
              pathname: getRoute(item.mediaContent),
              state: { item: { id: itemId(item.mediaContent) } },
            }}
          >
            <button
              className={`${
                DisplayUtils.isMobile() ? 'mobile' : 'desktop'
              }  bg-secondary-b2b btn-transparent d-flex justify-content-center align-items-center retry-btn`}
              onClick={() => goRoute(item.mediaContent)}
            >
              <span className="white">{mainBtnText}</span>
            </button>
          </Link>
        </div>
            }
        <div className={`${!DisplayUtils.isMobile() ? `desktop ${item.mediaContent ? 'py-2 pl-3 pr-2 col-6 justify-content-start' : 'col-12 p-0 justify-content-center'}` : `mobile ${item.mediaContent ? ' p-2 col-6 justify-content-start' : 'col-12 p-2 justify-content-center'}`}   d-flex  `}>
          <button
            className={`${
              DisplayUtils.isMobile() ? 'mobile' : 'desktop'
            }  bg-dbs-gray3 btn-transparent d-flex justify-content-center align-items-center retry-btn`}
            onClick={() => closeModal()}
          >
            <span className="white">{t('PromosModal.continue')}</span>
          </button>
        </div>
      </div>
    </div>
  ));
  return (
    <div className="modalPromotions-box d-flex align-items-center">
      <div
        className={`${!DisplayUtils.isMobile() ? 'desktop' : 'mobile'} modal fade`}
        id="modalPromotions"
        role="dialog"
        aria-labelledby="myModalLabel"
        tabIndex="-1"
        aria-hidden="true"
      >
        <div
          className={`${!DisplayUtils.isMobile() ? 'desktop' : 'mobile m-0'} simple-modal-image-dialog `}
          role="document"
        >
          <div className={`${!DisplayUtils.isMobile() ? 'desktop' : 'mobile'} simple-modal-image-content bg-light-b2b`}>
            <div
              className={`${
                !DisplayUtils.isMobile() ? 'desktop' : 'mobile'
              } simple-modal-image-body bg-light-b2b black`}
            >
              {!DisplayUtils.isMobile() && (
                <div
                  className={`${
                    DisplayUtils.isMobile() ? 'mobile' : 'desktop'
                  } btn-close d-flex justify-content-center align-items-center`}
                  onClick={() => closeModal()}
                >
                  <img alt="closebtn" src="/misc/icons/add_black.png" />
                </div>
              )}
              <div className={`${!DisplayUtils.isMobile() ? 'desktop' : 'mobile'} text-box black`}>
                <img
                  className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} anonymous-logo`}
                  src="/misc/icons/logo-4.svg"
                  alt="logo"
                />
                <div className="d-flex justify-content-center title mb-2">
                  {title} {currentUser && currentUser.firstname}!
                </div>
                <div className="mt-2 description">
                  <span>{description}</span>
                </div>
              </div>
            </div>
            <div
              className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} simple-modal-image-promos bg-dbs-white `}
            >
              <Slider {...carouselSettings}>{sliderPromos}</Slider>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalPromotions;
