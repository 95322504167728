import React, { useEffect, useState } from 'react';
import DisplayUtils from '../../../../utils/DisplayUtils';
import { Loading, ModalDouble, ModalError } from '../../../index';
import './UserList.css';
import 'antd/dist/antd.css';
import { Table, Input, Button } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { adminService } from '../../../../services/index';

const UserList = ({ setEditUser }) => {
  const { t } = useTranslation();

  const [userList, setUserList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [deleteUser, setDeleteUser] = useState(false);
  const [idUser, setIdUser] = useState(null);
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    const getClientsList = async () => {
      setLoading(true);
      try {
        const response = await adminService.getClients();
        const data = response.map((user) => {
          return {
            alias: user.alias,
            key: user.id,
            firstname: user.firstname,
            lastname: user.lastname,
            company: user.company,
            email: user.email,
            phone: user.phone,
            taxId: user.taxId
          };
        });
        setUserList(data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        showErrorModal();
      }
    };
    getClientsList();
  }, []);

  const showErrorModal = async () => {
    await setShowError(true);
    window.$('#errorModal').modal('show');
  };
  const hideErrorModal = () => {
    setShowError(false);
    window.$('#errorModal').modal('hide');
    window.$('.modal-backdrop').remove();
    window.$('body').removeClass('modal-open');
  };

  const showDeleteModal = async (id) => {
    setIdUser(id);
    await setDeleteUser(true);
    window.$('#doubleModal').modal('show');
  };

  const hideDoubleModal = async () => {
    await setDeleteUser(false);
    window.$('#doubleModal').modal('hide');
    window.$('.modal-backdrop').remove();
    window.$('body').removeClass('modal-open');
  };

  const onRemoveUser = async () => {
    try {
      const response = await adminService.removeUser(idUser);
      response && hideDoubleModal();

      const update = userList.filter((user) => user.key !== idUser);
      response && setUserList(update);
    } catch (error) {
      console.error(error);
    }
  };

  const goToEdit = (user) => {
    setEditUser(user);
  }

  const getColumnSearchProps = (name, dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Buscar ${name}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Buscar
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    render: (text) => text,
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };

  const handleReset = (clearFilters) => {
    clearFilters();
  };

  const desktopColumns = [
    {
      title: 'Nombre',
      dataIndex: 'firstname',
      key: 'firstname',
      ...getColumnSearchProps('nombre', 'firstname'),
    },
    {
      title: 'Apellidos',
      dataIndex: 'lastname',
      key: 'lastname',
      ...getColumnSearchProps('apellidos', 'lastname'),
    },
    {
      title: 'Empresa',
      dataIndex: 'company',
      key: 'company',
      ...getColumnSearchProps('empresa', 'company'),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      ...getColumnSearchProps('email', 'email'),
    },
    {
      title: 'Acciones',
      key: 'acciones',
      render: (key) => (
        <div className="desktop actions-container row m-0 d-flex justify-content-center">
          <button className="d-flex align-items-center bg-dark-b2b justify-content-center mr-2" onClick={() => goToEdit(key)}>
            <img alt="edit-icon" src="/misc/icons/edit_white.svg" />
          </button>
          <button
            className="d-flex align-items-center bg-dark-b2b justify-content-center"
            onClick={() => showDeleteModal(key.key)}
          >
            <img alt="delete-icon" src="/misc/icons/delete@2x.png" />
          </button>
        </div>
      ),
    },
  ];

  const mobileColumns = [
    {
      title: 'Nombre',
      dataIndex: 'firstname',
      key: 'firstname',
      ...getColumnSearchProps('nombre', 'firstname'),
    },
    {
      title: 'Empresa',
      dataIndex: 'company',
      key: 'company',
      ...getColumnSearchProps('empresa', 'company'),
    },
    {
      title: 'Acciones',
      key: 'acciones',
      render: (key) => (
        <div className="mobile actions-container row m-0 d-flex justify-content-center">
          <button className="d-flex align-items-center bg-dark-b2b justify-content-center mr-1" onClick={() => goToEdit(key)}>
            <img alt="edit-icon" src="/misc/icons/edit_white.svg" />
          </button>
          <button
            className="d-flex align-items-center bg-dark-b2b justify-content-center"
            onClick={() => showDeleteModal(key.key)}
          >
            <img alt="delete-icon" src="/misc/icons/delete@2x.png" />
          </button>
        </div>
      ),
    },
  ];

  return loading ? (
    <Loading white />
  ) : (
    <div className={`${DisplayUtils.isMobile() ? 'mobile p-3' : 'desktop'} admin-users-container bg-light-b2b`}>
      {deleteUser && (
        <ModalDouble
          title={t('AdminPanel.delete.title')}
          mainBtnText={t('Globals.yes')}
          secondaryBtnText={t('Globals.no')}
          mainAction={onRemoveUser}
          secondaryAction={hideDoubleModal}
          closeModal={hideDoubleModal}
        />
      )}
      {showError && <ModalError hideErrorModal={hideErrorModal} />}
      <div className="admin-users-content">
        <div className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop pb-3'} admin-users-text`}>
          <span className="title d-block mb-2">{t('AdminPanel.menu.list')}</span>
        </div>
        <Table columns={DisplayUtils.isMobile() ? mobileColumns : desktopColumns} dataSource={userList} />
      </div>
    </div>
  );
};

export default UserList;
