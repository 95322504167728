import React, { useState, useEffect, Fragment } from 'react';
import { Loading, ModalError } from '../../index';
import DisplayUtils from '../../../utils/DisplayUtils';
import { authService } from '../../../services/index';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import './ActivateUser.css';

const ActivateUser = ({ history }) => {
  const { t } = useTranslation();
  const [valueQuery, setValueQuery] = useState('');
  const [loading, setLoading] = useState(false);
  const [showError, setShowError] = useState(false);

   useEffect(() => {
     const query = history.location.search.split('?');
     const code = query[1].split('=')[1];

     setValueQuery(code);
   }, [history.location.search]);

  const onActivate = async () => {
    setLoading(true)
    try {
      const response = await authService.activate(valueQuery);
      if (response) {
        setLoading(false);
        history.push('/');
      } 
    } catch (error) {
      setLoading(false);
      showErrorModal();
    }
  };
  const showErrorModal = async () => {
    await setShowError(true);
    window.$('#errorModal').modal('show');
  };
  const hideErrorModal = () => {
    setShowError(false);
    window.$('#errorModal').modal('hide');
    window.$('.modal-backdrop').remove();
    window.$('body').removeClass('modal-open');
  };

  return (
    <div className="activate-box">
      {showError && <ModalError hideErrorModal={hideErrorModal} />}
      <div
        className={`${
          !DisplayUtils.isMobile() ? 'desktop' : 'mobile'
        } text-box-activate black row m-0 d-flex justify-content-center `}
      >
        {loading ? (
          <Loading white />
        ) : (
          <Fragment>
            <img
              className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} login-logo`}
              src="/misc/icons/logo-dbs-black.svg"
              alt="logo"
            />
            <h3 className="d-flex justify-content-center text-center w-100 mb-4">{t('Activate.title')}</h3>
            <span className="d-flex justify-content-center description text-center w-100">
              {t('Activate.description')}
            </span>
            <div
              className={`${
                !DisplayUtils.isMobile() ? 'desktop justify-content-center' : 'mobile justify-content-center'
              } col-12 d-flex p-0`}
            >
              <button
                className={`${
                  !DisplayUtils.isMobile() ? 'desktop ' : 'mobile '
                    } bg-dark-b2b btn-transparent d-flex justify-content-center align-items-center mt-5`}
                  onClick={onActivate}
              >
                <span className="white">{t('Globals.ok')}</span>
              </button>
            </div>
          </Fragment>
        )}
      </div>
    </div>
  );
};

export default withRouter(ActivateUser);
