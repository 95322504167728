import React, { useState, useEffect, useContext } from 'react';
import { AddressItem, Loading } from '../../index';
import { AuthContext } from '../../../contexts/AuthContext';
import './Addresses.css';
import DisplayUtils from '../../../utils/DisplayUtils';
import { useTranslation } from 'react-i18next';

const Addresses = () => {
  const [loading, setLoading] = useState(true);
  const { currentUser } = useContext(AuthContext)
  const { t } = useTranslation();

  useEffect(() => {
    let time = setTimeout(() => {
      setLoading(false)
    }, 600);

    return () => {
      clearTimeout(time)
    }
  }, []);

  return (
    <div className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} addresses-container`}>
      {loading ? (
        <div style={{ height: '550px' }}>
          <Loading transparent />
        </div>
      ) : (
        currentUser.addresses && currentUser.addresses.length > 0 ? (
          <div className="row m-0">
            {currentUser.addresses.map((address, index) => (
              <AddressItem key={index} address={address} />
            ))}
            <span className={`${DisplayUtils.isMobile() ? 'mobile p-0' : 'desktop p-0 pl-3'} add-info col-12`}>{t('Account.addresses.infoAdd')}<a href='/contactus'>soporte</a></span>
          </div>
        ): (
          <div className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop '} empty-addresses-container bg-dbs-white h-100 d-flex align-items-center justify-content-center`}>
            <div className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} row m-0 empty-addresses-parts`}>
              <div className={`${!DisplayUtils.isMobile() ? 'desktop justify-content-center ' : 'mobile justify-content-start'} w-100 bg-dbs-white d-flex align-items-center flex-column box-empty `}>
                <img src="/misc/icons/empty_addresses.svg" alt="bag" className="" />
                <span className="text-center text-empty">{t('Account.addresses.emptyAddresses')}</span>
              </div>
            </div>
          </div>
        )
      )}
    </div>
  );
};

export default Addresses;
