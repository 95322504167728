import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import {
  BackBtn,
  TopPromotions,
  FilterPromotions,
  Loading,
  ListPromotions,
  Pagination,
  EmptyBox,
  ModalError,
} from '../index';
import { productsService } from '../../services/index';
import DisplayUtils from '../../utils/DisplayUtils';
import CartUtils from '../../utils/CartUtils';
import { useTranslation } from 'react-i18next';
import './Promotions.css';

const Promotions = ({ location }) => {
  const { t } = useTranslation();

  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [listCategories, setListCategories] = useState([]);
  const [productsLoading, setProductsLoading] = useState(true);
  const [listProducts, setListProducts] = useState([]);
  const [actuallyId, setActuallyId] = useState(null);
  const [items, setItems] = useState(null);
  const [showError, setShowError] = useState(false);
  const [fromHome, setFromHome] = useState(location.state); // eslint-disable-line
  const productPerPage = 12;
  const PROMOTIONS_ID = process.env.REACT_APP_PRESTASHOP_PROMOTIONS_CAT_ID;

  useEffect(() => {
    const promotions = true;
    const getCategories = async () => {
      setLoading(true);
      try {
        const response = await productsService.getCategories(promotions);
        setListCategories(response[0].subcategories);
        const responseItems = await productsService.getItems();
        setItems(responseItems[0]);
        if (fromHome && fromHome.item) {
          setActuallyId(fromHome.item.id);
        } else {
          setActuallyId(PROMOTIONS_ID);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        showErrorModal();
      }
    };
    getCategories();
  }, [PROMOTIONS_ID, fromHome]);

  useEffect(() => {
    const getProducts = async (id, page) => {
      const options = {
        id: id,
        offset: page,
      };
      setProductsLoading(true);
      try {
        const response = await productsService.getProducts(options);
        if (response) {
          const allProducts = response.productsInfo;
          const promoProducts = [];
          allProducts &&
            allProducts.length > 0 &&
            allProducts.map(product => !CartUtils.getExpiration(product.expiration) && promoProducts.push(product));
          setListProducts(promoProducts);
          setProductsLoading(false);
        }
      } catch (error) {
        setProductsLoading(false);
        showErrorModal();
      }
    };
    actuallyId && getProducts(Number(actuallyId), 1);
  }, [actuallyId]);

  const showErrorModal = async () => {
    await setShowError(true);
    window.$('#errorModal').modal('show');
  };
  const hideErrorModal = () => {
    setShowError(false);
    window.$('#errorModal').modal('hide');
    window.$('.modal-backdrop').remove();
    window.$('body').removeClass('modal-open');
  };

  const indexOfLastProduct = currentPage * productPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productPerPage;

  const renderProducts = listProducts.slice(indexOfFirstProduct, indexOfLastProduct);

  const paginate = pageNumber => {
    switch (pageNumber) {
      case 'right':
        setCurrentPage(currentPage + 1);
        break;
      case 'left':
        setCurrentPage(currentPage - 1);
        break;
      default:
        setCurrentPage(pageNumber);
        break;
    }
  };

  const changeCategory = id => {
    setActuallyId(id);
  };
  return loading ? (
    <div style={{ height: '100%' }}>
      <Loading white />
    </div>
  ) : (
    <div className="promotions-container bg-dbs-white">
      {showError && <ModalError hideErrorModal={hideErrorModal} />}
      {DisplayUtils.isMobile() && <BackBtn white />}
      {items && <TopPromotions items={items} />}
      <FilterPromotions
        PROMOTIONS_ID={PROMOTIONS_ID}
        changeCategory={changeCategory}
        listCategories={listCategories}
        actuallyId={actuallyId}
      />
      {productsLoading ? (
        <div style={{ height: '500px' }}>
          <Loading white />
        </div>
      ) : renderProducts && renderProducts.length > 0 ? (
        <div className="">
          <ListPromotions listProducts={renderProducts} />

          <div className="row mx-0 my-2 pt-2 pb-2 justify-content-center bg-dbs-white ">
            <Pagination
              ItemsPerPage={productPerPage}
              totalItems={listProducts.length}
              paginate={paginate}
              currentPage={currentPage}
            />
            <div className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} pvpr-info col-12 mb-4`}>
              <div className="col-12">
                {t('Products.pvpInfo')}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="bg-dbs-gray5 d-flex justify-content-center align-items-center" style={{ height: '500px' }}>
          <EmptyBox inputSearch={''} />
        </div>
      )}
    </div>
  );
};

export default withRouter(Promotions);
