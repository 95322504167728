const TYPE = {
  MOBILE: 'mobile',
  PHABLET: 'phablet',
  TABLET: 'tablet',
  WEB: 'web',
};

const DisplayType = (width) => {
  if (width < 550) {
    return TYPE.MOBILE;
  } else if (width >= 550 && width < 801) {
    return TYPE.TABLET;
  } else {
    return TYPE.WEB;
  }
};

const isMobile = () => {
  return DisplayType(window.innerWidth) !== TYPE.WEB;
};
const isTablet = () => {
  return DisplayType(window.innerWidth) === TYPE.TABLET;
};

const getProductImage = (product, imgDefault) => {
  if(product.images && product.images[0]) {
    if(typeof product.images[0] === 'number') {
      return imgDefault
    }
    const newArr = product.images[0].split('/')
    if(newArr[newArr.length -1] === 'undefined.jpg' || newArr[newArr.length -1] === 'null.jpg' || newArr[newArr.length -1] === 'undefined.png' || newArr[newArr.length -1] === 'null.png'){
      return imgDefault
    } else {
      return product.images[0]
    }
  } else {
    return imgDefault
  }
}

const isDifferentChrome = () => {
  return (navigator.userAgent.indexOf("Edg") !== -1) || /*@cc_on!@*/false || !!document.documentMode ||
      navigator.userAgent.indexOf("Mi") !== -1;
}

export default { TYPE, DisplayType, isMobile, isTablet, getProductImage, isDifferentChrome };
