import React from 'react';
import DisplayUtils from '../../../../utils/DisplayUtils';
import CartUtils from '../../../../utils/CartUtils';
import constants from '../../../../utils/constants';
import { useTranslation } from 'react-i18next';
import './CartModalProduct.css';

const CartModalProduct = ({ item, deleteProduct }) => {

  const { t } = useTranslation();
  return (
    <div
      className={`${
        DisplayUtils.isMobile() ? 'mobile' : 'desktop'
      } row m-0 d-flex align-items-center box-products-list px-2 w-100`}
    >
      <div className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} col-2 p-0`}>
        <img className="img-cart-product align-middle mt-2" src={DisplayUtils.getProductImage(item, constants.NO_IMAGE)}
              alt="img-product"
              onError={(e)=>{e.target.onerror = null; e.target.src=`${constants.NO_IMAGE}`}}/>
      </div>
      <div className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} col-10 text-cart black px-0 py-2`}>
        <div className="row m-0">
          <div
            className={`${
              DisplayUtils.isMobile() ? 'mobile col-8 p-0' : 'desktop col-9 pl-0'
            } d-flex align-items-center`}
          >
            <span>{item.name}</span>
          </div>
          <div
            className={`${DisplayUtils.isMobile() ? 'mobile col-2' : 'desktop col-2 justify-content-start'}  p-0 d-flex align-items-center`}
          >
            <span className="price">
              {CartUtils.formatPrice(CartUtils.getPricePromotion(item.price, item.promotion, item.promotionType, item.expiration))}
            </span>
          </div>
          <div className={`${DisplayUtils.isMobile() ? 'mobile col-2' : 'desktop col-1'} p-0`}>
            <button
              onClick={() => deleteProduct(item)}
              className="btn-remove-cart btn-transparent d-flex align-items-center justify-content-center"
            />
          </div>
        </div>
        <div className="row m-0">
          <div className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} col-12 p-0 d-flex align-items-center`}>
            <span className="dbs-gray3">{t('Globals.quantity')}{item.quantity}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CartModalProduct;
