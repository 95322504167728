import React from 'react';
import { ProductSizesItem } from '../../index';
import DisplayUtils from '../../../utils/DisplayUtils';
import './ProductSizes.css';

const ProductSizes = ({ product, onHover, itemClicked, selectedSize, itemOver, addToCart, overImageToShow }) => {
    return(
        <div className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} sizes-container`}>
            {product.sizes ? (
                product.sizes.map((size, index) => (
                    <ProductSizesItem key={index} size={size} productSize={product} onHover={onHover} itemClicked={itemClicked} selectedSize={selectedSize} itemOver={itemOver} addToCart={addToCart}  overImageToShow={overImageToShow}  />
                ))
            ) : (
                <ProductSizesItem product={product} onHover={onHover} itemClicked={itemClicked} selectedSize={selectedSize} itemOver={itemOver} addToCart={addToCart} overImageToShow={overImageToShow} />
            )}
        </div>
    )
}

export default ProductSizes;