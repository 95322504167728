import React, { useEffect, useState } from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import PrivateRoute from './guards/PrivateRoute';
import {
  Login,
  NavBar,
  Home,
  Footer,
  NotFound,
  Shop,
  ContactUs,
  Promotions,
  ProductDetail,
  CartDetail,
  Profile,
  BrandDetail,
  Admin,
  OrderSuccess,
  ResetPass,
  ModalCookies,
  Cookies,
  Privacy,
  TermsConditions,
} from './components/index';
import constants from './utils/constants';
import { useLocalStorage } from './hooks/useLocalStorage';
import './App.css';

const App = ({ location }) => {
  const [width, setWidth] = useState(window.innerWidth);
  const [modalCookies, setModalCookies] = useState(true);
  const [cookies, setCookies] = useLocalStorage(constants.CURRENT_COOKIES_KEY, null);

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    cookies && setModalCookies(false);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };

  }, [width, cookies, location.pathname]);

  const acceptCookies = () => {
    const data = new Date();
    setCookies(data.getTime());
    closeCookies();
  };
  const closeCookies = () => setModalCookies(false);

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  const includeNavFooter = [
    'contactus',
    'shop',
    'promotions',
    'product-detail',
    'cart-detail',
    'profile',
    'brand-detail',
    'order-success',
    'cookies',
    'privacy',
    'terms-conditions'
  ];

  const showNavFooter = includeNavFooter.indexOf(location.pathname.split('/').join('')) >= 0;

  return (
    <div className="App">
      {showNavFooter && <NavBar currentPath={location.pathname} />}
      {modalCookies && <ModalCookies acceptCookies={acceptCookies} closeCookies={closeCookies} />}
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/password-reset" component={ResetPass} />
        <Route path="/cookies" render={props => <Cookies acceptCookies={acceptCookies} {...props} />} />
        <Route exact path="/privacy" component={Privacy} />
        <Route exact path="/terms-conditions" component={TermsConditions} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/contactus" component={ContactUs} />
        <Route exact path="/shop" component={Shop} />
        <PrivateRoute exact path="/promotions" component={Promotions} />
        <Route exact path="/product-detail" component={ProductDetail} />
        <PrivateRoute exact path="/cart-detail" component={CartDetail} />
        <PrivateRoute exact path="/order-success" component={OrderSuccess} />
        <PrivateRoute exact path="/profile" component={Profile} />
        <Route exact path="/brand-detail" component={BrandDetail} />
        <PrivateRoute exact path="/admin-panel" component={Admin} />
        <Route path="*" component={NotFound} />
      </Switch>
      {showNavFooter && <Footer />}
    </div>
  );
};

export default withRouter(App);
