import React from 'react';
import DisplayUtils from '../../../utils/DisplayUtils';
import constants from '../../../utils/constants';
import './TopPromotion.css';

const TopPromotions = ({ items }) => (
  <div className="title-box bg-dbs-white">
    <img
      alt="head-img"
      className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} head-image-promo`}
      src={items.mediaSource}
      onError={(e)=>{e.target.onerror = null; e.target.src=`${'/misc/img/promos.png'}`}}
    />
    <div className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} section-title`}>
      <span className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} title`}>
        {items.mediaTitle} <span className="title-blue">{items.highlight}</span>
      </span>
      <span className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} description d-flex flex-column`}>
        {items.mediaContent.replace(constants.REMOVE_HTML_PATTERN, '')}
      </span>
    </div>
  </div>
);

export default TopPromotions;
