import http from './base-http-service';
import constants from "../utils/constants";

const getCarriers = async () => {
  const config = { headers: { Authorization: localStorage.getItem(constants.CURRENT_TOKEN_KEY) } };
  try {
    const response = await http.get('/orders/carriers', config);
    if (response.status === 200) {
      return response.data;
    } else {
      throw response.data;
    }
  } catch (error) {
    throw error.response.data;
  }
}
const getOrders = async (limit, offset) => {
  const config = { headers: { Authorization: localStorage.getItem(constants.CURRENT_TOKEN_KEY) } };
  try {
    const response = await http.get(`/orders?limit=${limit}&offset=${offset}`, config);
    if (response.status === 200) {
      return response.data;
    } else {
      throw response.data;
    }
  } catch (error) {
    throw error.response.data;
  }
}
const addOrder = async (data) => {
  const config = { headers: { Authorization: localStorage.getItem(constants.CURRENT_TOKEN_KEY) } };
  try {
    const response = await http.post('/orders', data, config);
    if (response.status === 200) {
      return response.data;
    } else {
      throw response.data;
    }
  } catch (error) {
    throw error.response.data;
  }
}

export default {
  getOrders,
  addOrder,
  getCarriers
}