import React, { useEffect, useState, Fragment, useContext } from 'react';
import './ProfileInfo.css';
import {  Loading } from '../../index';
import DisplayUtils from '../../../utils/DisplayUtils';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../../contexts/AuthContext';

const ProfileInfo = ({ onRecover, showDoubleModal }) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(true);
  const { currentUser } = useContext(AuthContext)

  useEffect(() => {
    let time = setTimeout(() => {
      setLoading(false);
    }, 600);
    return () => {
      clearTimeout(time)
    }
  }, []);

  return (
    <div className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} profile-info-container`}>
      {loading ? (
        <div style={{ height: '550px' }}>
          <Loading transparent />
        </div>
      ) : (
          <div className={`${DisplayUtils.isMobile() ? '' : ''} col-12 p-0`}>
            <div className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} profile-info-content bg-white pb-4`}>
              <div
                className={`${
                  DisplayUtils.isMobile() ? 'mobile' : 'desktop'
                  } row m-0 address-title bg-dbs-gray4 black d-flex align-items-center`}
              >
                <span className="col-12 p-0">{t('Account.profile.title')}</span>
              </div>
              {currentUser && (
                <Fragment>
                  <div className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} address-info`}>
                    <span className="d-block dbs-gray2">
                      {t('Account.profile.name')} <span className="black">{currentUser.firstname}</span>
                    </span>
                    <span className="d-block dbs-gray2">
                      {t('Account.profile.surname')} <span className="black">{currentUser.lastname}</span>
                    </span>
                    <span className="d-block dbs-gray2">
                      {t('Account.profile.commerceName')} <span className="black">{currentUser.company}</span>
                    </span>
                    <span className="d-block dbs-gray2">
                      {t('Account.profile.cif')} <span className="black">{currentUser.taxId}</span>
                    </span>
                    <span className="d-block dbs-gray2">
                      {t('Account.profile.phone')} <span className="black">{currentUser.phone}</span>
                    </span>
                    <span className="d-block dbs-gray2">
                      {t('Account.profile.email')} <span className="black">{currentUser.email}</span>
                    </span>
                  </div>
                  <div className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} box-pass`}>
                    <button className="btn-transparent" onClick={() => onRecover(currentUser.email)}>
                      {t('Account.profile.pass')}
                    </button>
                    <button className="btn-transparent ml-3" onClick={showDoubleModal}>
                      {t('Account.profile.remove')}
                    </button>
                  </div>
                </Fragment>
              )}
            </div>
          </div>
        )}
    </div>
  );
};

export default ProfileInfo;
