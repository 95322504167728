import React from 'react';
import DisplayUtils from '../../../../utils/DisplayUtils';
import './AddressItem.css';

const AddressItem = ({ address }) => (
  <div className={`${DisplayUtils.isMobile() ? 'mb-4 p-0 col-12' : 'mb-4 address-info-content'} `}>
    <div className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} address-container bg-white pb-4`}>
      <div
        className={`${
          DisplayUtils.isMobile() ? 'mobile' : 'desktop'
        } row m-0 address-title bg-dbs-gray4 black d-flex align-items-center`}
      >
        <span className="col-12 p-0">{address.alias}</span>
      </div>
      <div className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} address-info`}>
        <span className="d-block dbs-gray2">
          {address.firstname} {address.lastname}
        </span>
        <span className="d-block dbs-gray2">{address.address1}</span>
        <span className="d-block dbs-gray2">{address.city}</span>
        <span className="d-block dbs-gray2">
          {address.province} {address.postcode}
        </span>
      </div>
    </div>
  </div>
);

export default AddressItem;
