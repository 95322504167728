import http from './base-http-service';
import constants from '../utils/constants';

const getClients = async () => {
  let config = { headers: { Authorization: localStorage.getItem(constants.CURRENT_TOKEN_KEY) } };
  try {
    const response = await http.get('/users/all', config);
      return response.data;
  } catch (error) {
    throw error;
  }
};

const removeUser = async (id) => {
  let config = { headers: { Authorization: localStorage.getItem(constants.CURRENT_TOKEN_KEY) } };
  try {
    const response = await http.delete(`/users/${id}`, config);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const createUser = async (newUser) => {
  const config = { headers: { Authorization: localStorage.getItem(constants.CURRENT_TOKEN_KEY) } };
  try {
    const response = await http.post('/users', newUser, config);
    if (response.status === 200) {
      return response.data;
    } else {
      throw response.data;
    }
  } catch (error) {
    throw error.response.data;
  }
};

const updateUser = async (editedUser) => {
  const config = { headers: { Authorization: localStorage.getItem(constants.CURRENT_TOKEN_KEY) } };
  try {
    const response = await http.patch(`/users/${editedUser.id}`, editedUser, config);
    if (response.status === 200) {
      return response.data;
    } else {
      throw response.data;
    }
  } catch (error) {
    throw error.response.data;
  }
}

const createAddress = async (userId, address) => {
  const config = { headers: { Authorization: localStorage.getItem(constants.CURRENT_TOKEN_KEY) } };
  try {
    const response = await http.post(`/users/${userId}/address`, address, config);
    return response.data;
  } catch (error) {
    throw error;
  }
}

const updateAddress = async (userId, addressId, address) => {
  const config = { headers: { Authorization: localStorage.getItem(constants.CURRENT_TOKEN_KEY) } };
  try {
    const response = await http.patch(`/users/${userId}/address/${addressId}`, address, config);
    if (response.status === 200) {
      return response.data;
    } else {
      throw response.data;
    }
  } catch (error) {
    throw error.response.data;
  }
}

const deleteAddress = async (userId, addressId) => {
  const config = { headers: { Authorization: localStorage.getItem(constants.CURRENT_TOKEN_KEY) } };
  try {
    const response = await http.delete(`/users/${userId}/address/${addressId}`, config);
    if (response.status === 200) {
      return response.data;
    } else {
      throw response.data;
    }
  } catch (error) {
    throw error.response.data;
  }
}

export default {
  getClients,
  removeUser,
  createUser,
  createAddress,
  updateAddress,
  deleteAddress,
  updateUser
};
