import axios from 'axios';
import * as env from '../environment';
import { authService } from '../services/index';

const { API_URL } = env[process.env.REACT_APP_ENVIRONMENT];

const http = axios.create({
  baseURL: API_URL,
});

http.interceptors.response.use(
  (response) => response,
  (error) => {
    const code = error.response && error.response.status;
    if (code === 403) {
      authService.logout();
    } else {
      return Promise.reject(code);
    }
  }
);

export default http;
