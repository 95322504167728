import React, { useReducer, useContext, useState, useEffect, Fragment } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import { authService } from '../../../services/index';
import {
  FormRegisterPerson,
  FormRegisterCompany,
  RegisterOk,
  ModalInput,
  ModalSimple,
  Loading,
  ModalError,
  FormLogin,
} from '../../index';
import './Login.css';
import DisplayUtils from '../../../utils/DisplayUtils';
import constants from '../../../utils/constants';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../../contexts/AuthContext';

const initialState = {
  email: '',
  password: '',
  errors: {},
};

const Login = () => {
  const { t } = useTranslation();
  const [errorResponse, setErrorResponse] = useState(null);
  const { currentToken, setCurrentToken, currentUser, setCurrentUser } = useContext(AuthContext);
  const reducer = (state, action) => {
    setErrorResponse(null);
    state.errors[action.type] = !action.payload;
    switch (action.type) {
      case 'email':
        return { ...state, email: action.payload };
      case 'password':
        return { ...state, password: action.payload };
      default:
        return initialState;
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);
  const [showRegisterPerson, setShowRegisterPerson] = useState(false);
  const [showRegisterCompany, setShowRegisterCompany] = useState(false);
  const [showRegisterOk, setShowRegisterOk] = useState(false);
  const [showRecoverModal, setShowRecoverModal] = useState(false);
  const [showRecoverOkModal, setShowRecoverOkModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const [loadingInitial, setLoadingInitial] = useState(true);

  useEffect(() => {
    let time = setTimeout(() => {
      setLoadingInitial(false);
    }, 600);
    return () => {
      clearTimeout(time)
    }
  }, []);

  const isValid = () => {
    return (
      Object.values(state).some(item => item === '') ||
      !constants.EMAIL_PATTERN.test(state.email) ||
      state.password.length < 6
    );
  };
  const showErrorModal = async () => {
    await setShowError(true);
    window.$('#errorModal').modal('show');
  };
  const hideErrorModal = () => {
    setShowError(false);
    window.$('#errorModal').modal('hide');
    window.$('.modal-backdrop').remove();
    window.$('body').removeClass('modal-open');
  };

  const handleSubmit = async event => {
    event.preventDefault();
    localStorage.removeItem(constants.CURRENT_INITIAL_KEY);
    localStorage.removeItem(constants.CURRENT_FILTERS_KEY);
    setLoading(true);
    const user = { email: state.email, password: state.password };
    try {
      const data = await authService.authenticate(user);
      if (data) {
        getUserInfo(data.token);
      }
    } catch (error) {
      setLoading(false);
      error === 401 ? setErrorResponse(t('Errors.errorLogin')) : showErrorModal();
    }
  };

  const onRecover = async email => {
    setShowRecoverModal(false);
    try {
      const response = await authService.recover({ email });
      response && showRecoverPassOkModal();
    } catch (error) {
      showErrorModal();
    }
  };

  const getUserInfo = async token => {
    try {
      const user = await authService.getUserInfo();
      if (user) {
        setCurrentUser(user);
        setCurrentToken(token);
      }
    } catch (error) {
      setLoading(false);
      showErrorModal();
    }
  };

  const onRegisterClick = type => {
    switch (type) {
      case 'person':
        setShowRegisterPerson(true);
        break;
      case 'company':
        setShowRegisterCompany(true);
        break;
      default:
    }
  };

  const onSendClick = async newUser => {
    setLoading(true);
    try {
      const user = await authService.requestNewUser(newUser);
      if (user) {
        setLoading(false);
        setShowRegisterOk(true);
        setShowRegisterPerson(false);
        setShowRegisterCompany(false);
      }
    } catch (error) {
      setLoading(false);
      showErrorModal();
    }
  };

  const showRecoverPassModal = async () => {
    await setShowRecoverModal(true);
    window.$('#inputModal').modal('show');
  };

  const hideRecoverPassModal = () => {
    setShowRecoverModal(false);
    window.$('#inputModal').modal('hide');
    window.$('.modal-backdrop').remove();
    window.$('body').removeClass('modal-open');
  };

  const showRecoverPassOkModal = async () => {
    await setShowRecoverOkModal(true);
    window.$('#simpleModal').modal('show');
  };

  const hideRecoverPassOkModal = () => {
    setShowRecoverOkModal(false);
    window.$('#simpleModal').modal('hide');
    window.$('.modal-backdrop').remove();
    window.$('body').removeClass('modal-open');
  };

  if (currentToken && currentUser && currentUser.roles.includes('admin')) {
    return <Redirect to="/admin-panel" />;
  } else if (currentToken) {
    return <Redirect to="/" />;
  }

  return (
    <div className={`${DisplayUtils.isMobile() ? 'mobile' : 'row m-0'} h-100 login-container`}>
      {showError && <ModalError hideErrorModal={hideErrorModal} />}
      {showRecoverModal && (
        <ModalInput
          title={t('RememberPassModal.title')}
          description={t('RememberPassModal.description')}
          mainBtnText={t('Globals.send')}
          mainAction={onRecover}
          closeModal={hideRecoverPassModal}
        />
      )}
      {showRecoverOkModal && (
        <ModalSimple
          title={t('RememberPassOkModal.title')}
          description={t('RememberPassOkModal.description')}
          mainBtnText={t('Globals.ok')}
          mainAction={hideRecoverPassOkModal}
          closeModal={hideRecoverPassOkModal}
        />
      )}
      {loadingInitial ? (
        <div style={{ height: '100vh' }} className="d-flex justify-content-center w-100 bg-dbs-gray5">
          <Loading transparent />
        </div>
      ) : (
        <Fragment>
          <div className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop col-5 p-0'}  login-image`}>
            <img
              className="login-image-background"
              src={
                DisplayUtils.isMobile() ? '/misc/img/login_background_mobile.jpg' : '/misc/img/login_background_desktop.jpg'
              }
              alt="background"
            />
          </div>
          <div
            className={`${DisplayUtils.isMobile() ? 'mobile' : 'col-7 desktop d-flex align-items-center justify-content-center'} login-form`}
          >
            {loading ? (
              <div style={DisplayUtils.isMobile() ? { height: '600px' } : { width: '100%' }}>
                <Loading transparent />
              </div>
            ) : showRegisterPerson ? (
              <FormRegisterPerson showRegister={setShowRegisterPerson} onSendClick={onSendClick} />
            ) : showRegisterCompany ? (
              <FormRegisterCompany showRegister={setShowRegisterCompany} onSendClick={onSendClick} />
            ) : showRegisterOk ? (
              <RegisterOk showRegisterOk={setShowRegisterOk} />
            ) : (
              <FormLogin
                state={state}
                dispatch={dispatch}
                errorResponse={errorResponse}
                handleSubmit={handleSubmit}
                showRecoverPassModal={showRecoverPassModal}
                isValid={isValid}
                onRegisterClick={onRegisterClick}
              />
            )}
          </div>
        </Fragment>
      )}
    </div>
  );
};

export default withRouter(Login);
