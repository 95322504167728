import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';
import constants from '../utils/constants';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { setCurrentToken } = useContext(AuthContext)
  const isAuth = localStorage.getItem(constants.CURRENT_TOKEN_KEY);
  return <Route {...rest} render={(props) => {
    if (isAuth) {
      return <Component {...props} {...rest} />
    } else {
      setCurrentToken(null)
      return <Redirect to="/" />
    }
  }}/>;
};

export default PrivateRoute;
