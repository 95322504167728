import React, { useState, useContext, useEffect, Fragment } from 'react';
import { SliderProducts, CartModal, Loading, ModalError, ProductSizes, Breadcrumbs, BackBtn } from '../index';
import DisplayUtils from '../../utils/DisplayUtils';
import CartUtils from '../../utils/CartUtils';
import constants from '../../utils/constants';
import { AuthContext } from '../../contexts/AuthContext';
import { useTranslation } from 'react-i18next';
import { withRouter, Redirect } from 'react-router-dom';
import { productsService } from '../../services/index';
import ReactHtmlParser from 'react-html-parser';
import { sanitize } from 'dompurify';
import './ProductDetail.css';

const ProductDetail = ({ location }) => {
  const { t } = useTranslation();
  const [product, setProduct] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const { currentCart, setCurrentCart, modalCart, setModalCart, currentUser } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const [selectedOption, setSelectedOption] = useState(0);
  const [listCategories, setListCategories] = useState([]);
  const [redirectToShop, setRedirectToShop] = useState(null);
  const [selectedSize, setSelectedSize] = useState(null);
  const [imageToShow, setImageToShow] = useState(null);
  const [overImageToShow, setOverImageToShow] = useState(null);
  const [imagesFooter, setImagesFooter] = useState(null);

  useEffect(() => {
    const getProduct = async id => {
      setLoading(true);
      try {
        const response = await productsService.getProductById(id);
        setProduct(response);
        const responseImg = await productsService.getImg();
        setImagesFooter(responseImg[0]);
        const responseCategories = await productsService.getCategories();
        setListCategories(responseCategories[0].subcategories[0].subcategories);
        location.state.item.sizes && setSelectedSize(location.state.item.sizes.id);
        if (location.state.brand) {
          const sizeDefault = response.sizes ? response.sizes.find(item => item.default) : null;
          setSelectedSize(sizeDefault ? sizeDefault.id : response);
          setImageToShow(sizeDefault ? getProductImageSize(sizeDefault) : getProductImage(response));
        } else {
          setImageToShow(
            location.state.item.sizes ? getProductImageSize(location.state.item.sizes) : getProductImage(response)
          );
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        showErrorModal();
      }
    };
    location.state.item.id && getProduct(location.state.item.id);
  }, [location.state.item.id, location.state.brand, location.state.item.sizes]);

  const showErrorModal = async () => {
    await setShowError(true);
    window.$('#errorModal').modal('show');
  };
  const hideErrorModal = () => {
    setShowError(false);
    window.$('#errorModal').modal('hide');
    window.$('.modal-backdrop').remove();
    window.$('body').removeClass('modal-open');
  };

  const addToCart = async (product, productCount, size) => {
    if (modalCart) await setModalCart(false);
    const response = await CartUtils.addToCart(currentCart, setCurrentCart, product, productCount, size);
    setCurrentCart(response);
    response && showCartModal();
  };

  const showCartModal = async () => {
    await setModalCart(true);
    window.$('#cartModal').modal('show');
  };

  const hideCartModal = async () => {
    await setModalCart(false);
    window.$('#cartModal').modal('hide');
    window.$('.modal-backdrop').remove();
    window.$('body').removeClass('modal-open');
  };

  const getProduct = async id => {
    setLoading(true);
    try {
      const response = await productsService.getProductById(id);
      setProduct(response);
      const sizeDefault = response.sizes && response.sizes.find(item => item.default);
      sizeDefault ? setSelectedSize(sizeDefault.id) : setSelectedSize(null);
      setImageToShow(sizeDefault ? getProductImageSize(sizeDefault) : getProductImage(response));
      setLoading(false);
    } catch (error) {
      setLoading(false);
      showErrorModal();
    }
  };

  const redirectTo = () => {
    setRedirectToShop(true);
  };

  if (redirectToShop) {
    return <Redirect to={{ pathname: '/shop' }} />;
  }

  const filterCategories =
    listCategories &&
    listCategories.filter(item => item.id !== Number(process.env.REACT_APP_PRESTASHOP_PROMOTIONS_CAT_ID));

  const getProductImage = product => {
    if (product && product.image) {
      const newArr = product.image.split('/');
      if (
        newArr[newArr.length - 1] === 'undefined.jpg' ||
        newArr[newArr.length - 1] === 'null.jpg' ||
        newArr[newArr.length - 1] === 'undefined.png' ||
        newArr[newArr.length - 1] === 'null.png'
      ) {
        return constants.NO_IMAGE;
      } else {
        return product.image;
      }
    } else if (product && product.images) {
      const newArr = product.images[0].split('/');
      if (
        newArr[newArr.length - 1] === 'undefined.jpg' ||
        newArr[newArr.length - 1] === 'null.jpg' ||
        newArr[newArr.length - 1] === 'undefined.png' ||
        newArr[newArr.length - 1] === 'null.png'
      ) {
        return constants.NO_IMAGE;
      } else {
        return product.images[0];
      }
    } else {
      return constants.NO_IMAGE;
    }
  };

  const getProductImageSize = product => {
    if (product.image) {
      const newArr = product.image.toString().split('/');
      if (
        newArr[newArr.length - 1] === 'undefined.jpg' ||
        newArr[newArr.length - 1] === 'null.jpg' ||
        newArr[newArr.length - 1] === 'undefined.png' ||
        newArr[newArr.length - 1] === 'null.png'
      ) {
        return constants.NO_IMAGE;
      } else {
        return product.image;
      }
    } else {
      return constants.NO_IMAGE;
    }
  };

  const itemOver = item => {
    if (item) {
      setOverImageToShow(getProductImage(item));
    } else {
      setOverImageToShow(null);
    }
  };

  const itemClicked = item => {
    setSelectedSize(item.id);
    setImageToShow(getProductImage(item));
  };

  const showTextTab = () => {
    switch (selectedOption) {
      case 0:
        return product.description ? ReactHtmlParser(sanitize(product.description)) : '';
      case 1:
        return product.howtouse ? ReactHtmlParser(sanitize(product.howtouse)) : '';
      case 2:
        return product.descriptionother ? ReactHtmlParser(sanitize(product.descriptionother)) : '';
      default:
    }
  };

  return (
    <div
      className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} detail-container bg-dbs-white`}
      style={loading ? { minHeight: '100%' } : {}}
    >
      {modalCart && (
        <CartModal cartLocal={currentCart} closeModal={hideCartModal} goCart={hideCartModal} returnShop={true} />
      )}
      {showError && <ModalError hideErrorModal={hideErrorModal} />}
      {loading ? (
        <div style={{ height: '500px' }}>
          <Loading white />
        </div>
      ) : (
        product && (
          <Fragment>
            <div className="row m-0">
              <div
                className={`${
                  DisplayUtils.isMobile() ? 'col-12 mobile' : 'col-5 desktop'
                } detail-images-container p-0 bg-dbs-white d-flex justify-content-center`}
              >
                <div
                  className={`${
                    DisplayUtils.isMobile() ? 'mobile' : 'desktop pl-5'
                  } row w-100 position-absolute mt-3 mx-0" `}
                >
                  {(product.categories &&
                    product.categories[0].id !== Number(process.env.REACT_APP_PRESTASHOP_PROMOTIONS_CAT_ID)) &&
                    product.categories[0].subcategories[0].id !==
                      Number(process.env.REACT_APP_PRESTASHOP_PROMOTIONS_CAT_ID) && (
                      <Breadcrumbs
                        handleChange={redirectTo}
                        categories={filterCategories}
                        product={product.categories && product.categories[0]}
                        detail
                      />
                    )}
                </div>
                <div className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} product-images`}>
                  <div className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} big-image-content bg-white`}>
                    <img
                      alt="big"
                      src={overImageToShow ? overImageToShow : imageToShow}
                      className="big-image"
                      onError={e => {
                        e.target.onerror = null;
                        e.target.src = `${constants.NO_IMAGE}`;
                      }}
                    />
                  </div>
                </div>
              </div>
              <div
                className={`${
                  DisplayUtils.isMobile() ? 'col-12 mobile' : 'col-7 desktop'
                } detail-item-container bg-dbs-white black d-flex`}
              >
                <div className="w-100">
                  <div className="two-lines">
                    <span
                      className={`${
                        DisplayUtils.isMobile() ? 'mobile' : 'desktop'
                      } head-product-name d-block w-100 mb-2`}
                    >
                      {product.name}
                    </span>
                  </div>
                  <div className="two-lines">
                    <span
                      className={`${
                        DisplayUtils.isMobile() ? 'mobile' : 'desktop'
                      } product-description d-block dbs-gray3 w-100`}
                    >
                      {product.descriptionshort
                        ? product.descriptionshort.replace(constants.REMOVE_HTML_PATTERN, '')
                        : ''}
                    </span>
                  </div>
                  <ProductSizes
                    product={product}
                    itemOver={itemOver}
                    itemClicked={itemClicked}
                    addToCart={addToCart}
                    selectedSize={selectedSize}
                    overImageToShow={overImageToShow}
                  />
                  <div>
                    <div
                      className={`${
                        product.descriptionother && product.descriptionother !== '' ? 'three' : ''
                      } menu-mobile`}
                    >
                      <ul>
                        <li
                          className={`${selectedOption === 0 ? 'selected' : ''}`}
                          onClick={() => setSelectedOption(0)}
                        >
                          <span>{t('Products.description').toUpperCase()}</span>
                        </li>
                        <li
                          className={`${selectedOption === 1 ? 'selected' : ''}`}
                          onClick={() => setSelectedOption(1)}
                        >
                          <span>{t('Products.use').toUpperCase()}</span>
                        </li>
                        {product.descriptionother && product.descriptionother !== '' && (
                          <li
                            className={`${selectedOption === 2 ? 'selected' : ''}`}
                            onClick={() => setSelectedOption(2)}
                          >
                            <span>{t('Products.descriptionother').toUpperCase()}</span>
                          </li>
                        )}
                      </ul>
                      <div className="info-text-container">{showTextTab()}</div>
                    </div>
                  </div>
                  <div className="pvp-info-text">{t('Products.pvpInfo')}</div>
                </div>
              </div>
              {product.categories &&
                (product.categories[0].id === Number(process.env.REACT_APP_PRESTASHOP_PROMOTIONS_CAT_ID) ||
                product.categories[0].subcategories[0].id ===
                  Number(process.env.REACT_APP_PRESTASHOP_PROMOTIONS_CAT_ID)) && (<BackBtn />)}
            </div>
            <div className="separator bg-dbs-gray4" />
            {isOpen && (
              <div className="full-screen-container">
                <img
                  alt="closebtn"
                  className="closebtn"
                  src="/misc/icons/close_button.png"
                  onClick={() => setIsOpen(false)}
                />
                <img
                  alt="product"
                  src={product.images && product.images[0] ? product.images[0] : ''}
                  className="full-screen-image"
                />
              </div>
            )}
            {product.related && product.related.length > 0 && (
              <div className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} recommended-slider bg-dbs-white`}>
                <SliderProducts
                  products={product.related}
                  onChangeProduct={getProduct}
                  currentUser={currentUser}
                  showModal={showCartModal}
                />
              </div>
            )}
            <div className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} image-container`}>
              <img
                alt="img"
                src={imagesFooter && imagesFooter.mediaSource}
                onError={e => {
                  e.target.onerror = null;
                  e.target.src = '/misc/img/promos.png';
                }}
              />
            </div>
          </Fragment>
        )
      )}
    </div>
  );
};

export default withRouter(ProductDetail);
