import React, { useState } from 'react';
import DisplayUtils from '../../../utils/DisplayUtils';
import './FilterPromotions.css';

const FilterPromotions = ({ changeCategory, listCategories, actuallyId, PROMOTIONS_ID }) => {

  const [showSelector, setShowSelector] = useState(false);
  const [selectedPromo, setSelectedPromo] = useState("TODAS")

  const selectorOptions = listCategories;

  const showOptions = () => {
    setShowSelector(!showSelector);
  };
  const optionSelected = option => {
    if(option) {
      changeCategory(option.id)
      setSelectedPromo(option.name)
      setShowSelector(false);
    } else {
      changeCategory(PROMOTIONS_ID)
      setSelectedPromo("TODAS")
      setShowSelector(false);
    }
  };

  return(
    DisplayUtils.isMobile() ? (
      <div className='promotions-dropdown-container'>
        <div className='promotions-dropdown p-0 m-0 row w-100 '>
          <div className="col-12 dropdown-button d-flex align-items-center" onClick={showOptions}>
            <span className='dbs-gray2 mr-auto'>
              {selectedPromo.toUpperCase()}
            </span>
            <img
              alt="dropdown-icon"
              className="dropdown-icon"
              src={showSelector ? '/misc/icons/arrow_up_black.svg' : '/misc/icons/arrow_down_black@2x.png'}
            ></img>
          </div>
          {showSelector ? (
            <div className={`${!DisplayUtils.isMobile() ? 'desktop' : 'mobile'} col-12 dropdown-options p-3`}>
              <ul>
                <li className="mb-3" onClick={() => optionSelected(null)}>TODAS</li>
                {selectorOptions.map((option, index) => (
                  <li key={index} className="mb-3" onClick={() => optionSelected(option)}>
                    {option.name.toUpperCase()}
                  </li>
                ))}
              </ul>
            </div>
          ) : null}
        </div>
      </div>
      
    ) : (
      <div className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} row m-0 w-100 filters-box bg-dbs-white`}>
        <div className={`${DisplayUtils.isMobile() ? 'mobile col-12' : 'desktop col-6'}  d-flex categories-box p-0`}>
          <button
            className={`${actuallyId === PROMOTIONS_ID ? 'is-active' : ''} mr-3`}
            key={PROMOTIONS_ID}
            onClick={() => changeCategory(PROMOTIONS_ID)}
          >
            TODAS
          </button>
          {listCategories &&
            listCategories.map(category => (
              <button
                className={`${Number(actuallyId) === category.id ? 'is-active' : ''} mr-3`}
                key={category.id}
                onClick={() => changeCategory(category.id)}
              >
                {category.name.toUpperCase()}
              </button>
            ))}
        </div>
      </div>
    )
  )
  
};

export default FilterPromotions;
