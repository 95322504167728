import React from 'react';
import DisplayUtils from '../../../../utils/DisplayUtils';
import './AdminAddressItem.css';

const AdminAddressItem = ({ address, editingAddress, deleteAddress }) => {
  return (
    <div className={`${DisplayUtils.isMobile() ? 'col-12 mb-4 p-0' : 'col-12 mb-4 p-0'}`}>
      <div className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} address-user-container bg-white`}>
            <div className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} row m-0 address-user-title d-flex align-items-center`}>
                <span className="col-12 p-0">{address.alias}</span>
            </div>
            <div className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} address-user-info`}>
                <span className="d-block">
                    {address.firstname} {address.lastname}
                </span>
                <span className="d-block">{address.address1}</span>
                <span className="d-block">{address.city}</span>
                <span className="d-block">
                    {address.province} {address.postcode}
                </span>
            </div>
            <div className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} actions-container row m-0 d-flex justify-content-end`}>
                <button className="d-flex align-items-center bg-dark-b2b justify-content-center mr-2" onClick={() => editingAddress(address)}>
                    <img alt="edit-icon" src="/misc/icons/edit_white.svg" />
                </button>
                <button className="d-flex align-items-center bg-dark-b2b justify-content-center" onClick={(e) => deleteAddress(e,address.idAddress)}>
                    <img alt="delete-icon" src="/misc/icons/delete@2x.png" />
                </button>
            </div>
        </div>
    </div>
  );
};

export default AdminAddressItem;