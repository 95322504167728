import React from 'react';
import DisplayUtils from '../../../utils/DisplayUtils';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import './BackBtn.css';

const BackBtn = ({ history, white, returnShop }) => {
  const { t } = useTranslation();

  const onBack = () => {
    return returnShop
      ? history.push({
          pathname: '/shop'
        })
      : history.goBack(-1);
  };

  return (
    <div className="back-btn-container w-100">
      <button
        className={`${
          DisplayUtils.isMobile() ? 'mobile' : 'desktop'
        } btn-transparent back-btn d-flex align-items-start`}
        onClick={onBack}
      >
        <img
          src={white ? '/misc/icons/arrow_back_white@2x.png' : '/misc/icons/arrow_back_black@2x.png'}
          alt="back-icon"
          className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} icon-back`}
        />
        <span
          className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} ${
            white ? 'white' : 'black'
          } align-middle back-text`}
        >
          {t('Globals.back').toUpperCase()}
        </span>
      </button>
    </div>
  );
};

export default withRouter(BackBtn);
