import React, { useContext, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import DisplayUtils from '../../../utils/DisplayUtils';
import constants from '../../../utils/constants';
import './OrderSuccess.css';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../../contexts/AuthContext';

const OrderSuccess = ({ history }) => {
  const { t } = useTranslation();
  const screenHeight = window.innerHeight - (DisplayUtils.isMobile() ? 140 : 180);

  const onBack = () => history.push('/');
  const { setCurrentCart } = useContext(AuthContext);

  useEffect(() => {
    setCurrentCart([]);
    localStorage.removeItem(constants.CURRENT_CART_KEY);
  }, []) // eslint-disable-line

  return (
    <div className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} success-detail-container bg-light-b2b `}>
      <div
        className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} row m-0 success-detail-parts  `}
        style={DisplayUtils.isMobile() ? { height: '100%' } : { height: screenHeight }}
      >
        <div
          className={`${
            !DisplayUtils.isMobile() ? 'desktop justify-content-center ' : 'mobile justify-content-start'
          } w-100 bg-light-b2b d-flex align-items-center flex-column box-empty `}
        >
          <img src="/misc/icons/caja-2.svg" alt="bag" className="" />
          <h4 className="black text-center">{t('OrderSuccess.success')}</h4>
          <span>{t('OrderSuccess.description')}</span>
          <button
            className={`${
              DisplayUtils.isMobile() ? 'mobile' : 'desktop'
            } next-btn bg-dark-b2b btn-transparent white mt-4`}
            onClick={onBack}
          >
            {t('Globals.ok')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default withRouter(OrderSuccess);
