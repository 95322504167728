import React, { useReducer,useEffect,useState } from 'react';
import DisplayUtils from '../../../../utils/DisplayUtils';
import { useTranslation } from "react-i18next";
import constants from '../../../../utils/constants';
import { Loading, ModalSimple, AdminAddressItem, ModalDouble } from '../../../index';
import { userService, adminService } from '../../../../services/index';
import './AdminAddresses.css';

const initialState = {
    id: 0,
    formAlias: "",
    formName: "",
    formSurname: "",
    formPhone: "",
    formAddress: "",
    formPostal: "",
    formCity: "",
    formProvince: "",
    formCountry: "",
    errors: {}
};

const reducer = (state, action) => {
  
    state.errors[action.type] = !action.payload;
    switch (action.type) {
        case "formAlias":
            return { ...state, formAlias: action.payload };
        case "formName":
            return { ...state, formName: action.payload };
        case "formSurname":
            return { ...state, formSurname: action.payload };
        case "formPhone":
            return { ...state, formPhone: action.payload };
        case "formAddress":
            return { ...state, formAddress: action.payload };
        case "formPostal":
            return { ...state, formPostal: action.payload };
        case "formCity":
            return { ...state, formCity: action.payload };
        case "formProvince":
            return { ...state, formProvince: action.payload };
        case "formCountry":
            return { ...state, formCountry: action.payload };
        case "id":
            return { ...state, id: action.payload };
      default:
        return initialState;
    }
  };

const AdminAddresses = ({ userId, showAddresses, fromEdit }) => {

    const { t } = useTranslation();

    const [state, dispatch] = useReducer(reducer, initialState);
    const [addresses, setAddresses] = useState(null);
    const [countries, setCountries] = useState([{ name: "Spain" }]);
    const [provinces, setProvinces] = useState([{ name: "Madrid" }]);
    const [loading, setLoading] = useState(false);
    const [createModal, setCreateModal] = useState(false);
    const [saveAllModal, setSaveAllModal] = useState(false);
    const [createError, setCreateError] = useState(false);
    const [editError, setEditError] = useState(false);
    const [editingAddress, setEditingAddress] = useState(null)
    const [deleteAddressId, setDeleteAddressId] = useState(null)
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [reloadAddresses, setReloadAddresses] = useState(true);
    const format = t("Errors.notValid");

    useEffect(() => {
        const getAddresses = async () => {
            try {
                const addresses = await userService.getAddresses(userId);
                setAddresses(addresses);
                setReloadAddresses(false)
              } catch (error) {
                setAddresses(null);
                setReloadAddresses(false)
              }
        }
        reloadAddresses && getAddresses();
    }, [reloadAddresses, userId]);

    useEffect(() => {
        const getCountries = async () => {
            try {
              const newCountries = await userService.getCountries();
              const newArr = [...countries, ...newCountries];
              setCountries(newArr);
            } catch (error) {
              setCountries([{ name: "Spain" }]);
            }
          };
        const getProvinces = async () => {
            try {
              const newProvinces = await userService.getProvinces();
              const newArr = [...provinces, ...newProvinces];
              setProvinces(newArr);
            } catch (error) {
              setProvinces([{ name: "-" }]);
            }
        };
        getCountries();
        getProvinces();
    }, []) // eslint-disable-line

    useEffect(() => {
        if(editingAddress) {
            dispatch({ type: "formAlias", payload: editingAddress.alias })
            dispatch({ type: "formName", payload: editingAddress.firstname })
            dispatch({ type: "formSurname", payload: editingAddress.lastname })
            dispatch({ type: "formPhone", payload: editingAddress.phone })
            dispatch({ type: "formAddress", payload: editingAddress.address1 })
            dispatch({ type: "formPostal", payload: editingAddress.postcode })
            dispatch({ type: "formCity", payload: editingAddress.city })
            dispatch({ type: "formProvince", payload: editingAddress.province })
            dispatch({ type: "formCountry", payload: editingAddress.country })
            dispatch({ type: "id", payload: editingAddress.idAddress })
        }

        return () => {
            state.errors = {};
            dispatch({ type: "initialState" });
        };
    }, [state.errors, editingAddress]);

    const createAddress = async event => {
        setLoading(true);
        dispatch({ type: 'initialState' });
        event.preventDefault();
        const userAddress = {
            alias: state.formAlias,
            firstname: state.formName,
            lastname: state.formSurname,
            phone: state.formPhone,
            address: state.formAddress,
            postcode: state.formPostal,
            city: state.formCity,
            province: state.formProvince,
            country: state.formCountry
        };
        try {
            const data = await adminService.createAddress(userId, userAddress);
            if (data) {
                setReloadAddresses(true)
                setLoading(false);
                setEditingAddress(null);
                setCreateError(false);
            }
        } catch (error) {
            setReloadAddresses(true)
            setLoading(false);
            setCreateError(true);
            showCreateModal();
        }
    };

    const editAddress = async event => {
        setLoading(true);
        dispatch({ type: 'initialState' });
        event.preventDefault();
        const userAddress = {
            alias: state.formAlias,
            firstname: state.formName,
            lastname: state.formSurname,
            phone: state.formPhone,
            address: state.formAddress,
            postcode: state.formPostal,
            city: state.formCity,
            province: state.formProvince,
            country: state.formCountry
        };
        try {
            const data = await adminService.updateAddress(userId, editingAddress.idAddress, userAddress)
            if (data) {
                setReloadAddresses(true)
                setLoading(false);
                setEditingAddress(null);
                setEditError(false);
            }
        } catch (error) {
            setReloadAddresses(true)
            setLoading(false);
            setEditError(true);
            showCreateModal();
        }
    }

    const askForDelete = (event, addressId) => {
        event.preventDefault();
        setDeleteAddressId(addressId);
        showDoubleModal()
    }

    const deleteAddress = async () => {
        hideDoubleModal()
        setLoading(true);
        dispatch({ type: 'initialState' });
        try {
            const data = await adminService.deleteAddress(userId, deleteAddressId)
            if (data) {
                setReloadAddresses(true)
                setLoading(false);
                setEditingAddress(null);
                setEditError(false);
                setDeleteAddressId(null);
            }
        } catch (error) {
            setReloadAddresses(true)
            setLoading(false);
            setEditError(true);
            setDeleteAddressId(null);
            showCreateModal();
        }
    }

    const showCreateModal = async () => {
        await setCreateModal(true);
        window.$('#simpleModal').modal('show');
    };
    
    const hideCreateModal = () => {
        setCreateError(false);
        setEditError(false);
        setCreateModal(false);
        setEditingAddress(null);
        window.$('#simpleModal').modal('hide');
        window.$('.modal-backdrop').remove();
        window.$('body').removeClass('modal-open');
    };

    const showDoubleModal = async () => {
        await setShowDeleteModal(true);
        window.$('#doubleModal').modal('show');
    };
    
    const hideDoubleModal = () => {
        setShowDeleteModal(false);
        setEditingAddress(null);
        setDeleteAddressId(null);
        window.$('#doubleModal').modal('hide');
        window.$('.modal-backdrop').remove();
        window.$('body').removeClass('modal-open');
    };

    const showSaveAllModal = async () => {
        await setSaveAllModal(true);
        window.$('#simpleModal').modal('show');
    }

    const hideSaveAllModal = () => {
        setSaveAllModal(false);
        showAddresses(false);
        window.$('#simpleModal').modal('hide');
        window.$('.modal-backdrop').remove();
        window.$('body').removeClass('modal-open');
    };

    const isValid = () => {
        return Object.values(state).some(item => item === "") || state.formCountry === t("AddressForm.country") || state.formProvince === t("AddressForm.province");
    };

    return loading ? (
        <Loading transparent/>
      ) : (
        <div className={`${DisplayUtils.isMobile() ? 'mobile ' : 'desktop '} admin-addresses-container bg-light-b2b`}>
            {showDeleteModal && (
                <ModalDouble
                    title={t('DeleteAddressModal.title')}
                    description={t('DeleteAddressModal.description')}
                    mainBtnText={t('Globals.yes')}
                    secondaryBtnText={t('Globals.no')}
                    mainAction={deleteAddress}
                    secondaryAction={hideDoubleModal}
                    closeModal={hideDoubleModal}
                />
            )}
            <div className="admin-addresses-content">
                <div className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop pb-3'} admin-addresses-text`}>
                    {!fromEdit ? (editingAddress ? 
                    (
                        <span className="title d-block mb-2">{t('AdminPanel.addresses.edit')}</span>
                    ) : 
                    (
                        <span className="title d-block mb-2">{t('AdminPanel.addresses.new')}</span>
                    )) : (
                        <span className="title d-block mb-2">{t('AdminPanel.edit.addressesTitle')}</span>
                    )}
                </div>
                <div className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} row m-0 admin-addresses-form`}>
                    <div className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} col-12 p-0`}>
                        <input
                            name="formAlias"
                            value={state.formAlias}
                            type="text"
                            placeholder={t('AddressForm.title')}
                            className={`${state.errors.formAlias && 'is-invalid'} form-control w-100`}
                            onBlur={e => dispatch({ type: e.target.name, payload: e.target.value })}
                            onChange={e => dispatch({ type: e.target.name, payload: e.target.value })}
                            autoComplete="off"
                        />
                        {state.errors.formAlias && <span className="red">{t('Errors.empty')}</span>}
                    </div>
                    <div className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} col-12 p-0`}>
                        <input
                            name="formName"
                            value={state.formName}
                            type="text"
                            placeholder={t('RegisterForm.name')}
                            className={`${state.errors.formName && 'is-invalid'} ${state.formName &&
                                !constants.NAME_PATTERN.test(state.formName) &&
                                'is-invalid'} form-control w-100`}
                            onBlur={e => dispatch({ type: e.target.name, payload: e.target.value })}
                            onChange={e => dispatch({ type: e.target.name, payload: e.target.value })}
                            autoComplete="off"
                            />
                        {state.errors.formName ? (
                            <span className="red">{t('Errors.empty')}</span>
                        ) : (
                            state.formName && !constants.NAME_PATTERN.test(state.formName) && <span className="red">{format}</span>
                        )}
                    </div>
                    <div className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} col-12 p-0`}>
                        <input
                            name="formSurname"
                            value={state.formSurname}
                            type="text"
                            placeholder={t('RegisterForm.surname')}
                            className={`${state.errors.formSurname && 'is-invalid'} ${state.formSurname &&
                                !constants.NAME_PATTERN.test(state.formSurname) &&
                                'is-invalid'} form-control w-100`}
                            onBlur={e => dispatch({ type: e.target.name, payload: e.target.value })}
                            onChange={e => dispatch({ type: e.target.name, payload: e.target.value })}
                            autoComplete="off"
                            />
                        {state.errors.formSurname ? (
                            <span className="red">{t('Errors.empty')}</span>
                        ) : (
                            state.formSurname && !constants.NAME_PATTERN.test(state.formSurname) && <span className="red">{format}</span>
                        )}
                    </div>
                    <div className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} col-12 p-0`}>
                        <input
                            name="formPhone"
                            value={state.formPhone}
                            type="text"
                            placeholder={t('RegisterForm.phone')}
                            className={`${state.errors.formPhone && 'is-invalid'} form-control w-100`}
                            onBlur={e => dispatch({ type: e.target.name, payload: e.target.value })}
                            onChange={e => dispatch({ type: e.target.name, payload: e.target.value })}
                        />
                        {state.errors.formPhone && <span className="red">{t('Errors.empty')}</span>}
                    </div>
                    <div className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} col-12 p-0`}>
                        <input
                            name="formAddress"
                            value={state.formAddress}
                            type="text"
                            placeholder={t('AddressForm.address')}
                            className={`${state.errors.formAddress && 'is-invalid'} form-control w-100`}
                            onBlur={e => dispatch({ type: e.target.name, payload: e.target.value })}
                            onChange={e => dispatch({ type: e.target.name, payload: e.target.value })}
                        />
                        {state.errors.formAddress && <span className="red">{t('Errors.empty')}</span>}
                    </div>
                    <div className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} col-12 p-0`}>
                        <input
                            name="formPostal"
                            value={state.formPostal}
                            type="text"
                            placeholder={t('AddressForm.postalCode')}
                            className={`${state.errors.formPostal && 'is-invalid'} form-control w-100`}
                            onBlur={e => dispatch({ type: e.target.name, payload: e.target.value })}
                            onChange={e => dispatch({ type: e.target.name, payload: e.target.value })}
                        />
                        {state.errors.formPostal && <span className="red">{t('Errors.empty')}</span>}
                    </div>
                    <div className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} col-12 p-0`}>
                        <input
                            name="formCity"
                            value={state.formCity}
                            type="text"
                            placeholder={t('AddressForm.city')}
                            className={`${state.errors.formCity && 'is-invalid'} form-control w-100`}
                            onBlur={e => dispatch({ type: e.target.name, payload: e.target.value })}
                            onChange={e => dispatch({ type: e.target.name, payload: e.target.value })}
                        />
                        {state.errors.formCity && <span className="red">{t('Errors.empty')}</span>}
                    </div>
                    <div className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} col-12 p-0`}>
                        <select
                            name="formProvince"
                            className={`${state.formProvince === t("AddressForm.province") || state.formProvince === "" && 'grey'} ${state.formProvince === t("AddressForm.province") && ' grey is-invalid'} form-control w-100`} // eslint-disable-line
                            placeholder={t("AddressForm.province")}
                            onBlur={e => dispatch({ type: e.target.name, payload: e.target.value })}
                            onChange={e => dispatch({ type: e.target.name, payload: e.target.value })}
                            value={state.formProvince ? state.formProvince : t("AddressForm.province")}
                            id="province"
                        >
                            <option className="mb-2" disabled selected>
                                {t("AddressForm.province")}
                            </option>
                            {provinces.map((option, index) => (
                                <option key={index} className="mb-2" value={option.name}>
                                    {option.name}
                                </option>
                            ))}
                        </select>
                        {state.formProvince === t("AddressForm.province") && <span className="red">{t('Errors.empty')}</span>}
                    </div>
                    <div className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} col-12 p-0`}>
                        <select
                            name="formCountry"
                            className={`${state.formCountry === t("AddressForm.country") || state.formCountry === "" && 'grey'} ${state.formCountry === t("AddressForm.country") && ' grey is-invalid'} form-control w-100`} // eslint-disable-line
                            placeholder={t("AddressForm.country")}
                            onBlur={e => dispatch({ type: e.target.name, payload: e.target.value })}
                            onChange={e => dispatch({ type: e.target.name, payload: e.target.value })}
                            value={state.formCountry ? state.formCountry : t("AddressForm.country")}
                            id="country"
                        >
                            <option className="mb-2" disabled selected>
                                {t("AddressForm.country")}
                            </option>
                            {countries.map((option, index) => (
                                <option key={index} className="mb-2" value={option.name}>
                                    {option.name}
                                </option>
                            ))}
                        </select>
                        {state.formCountry === t("AddressForm.country") && <span className="red">{t('Errors.empty')}</span>}
                    </div>
                </div>
                <div className="w-100 row m-0">
                    <button 
                        onClick={ editingAddress ? e => editAddress(e) : e => createAddress(e)} 
                        disabled={isValid()} className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} ${ editingAddress ? ' bg-dark-b2b' : ' bg-secondary-b2b'} white admin-addresses-button btn-transparent align-items-center d-flex justify-content-center`}>
                        {!editingAddress && <img src="/misc/icons/add_round.svg" alt="btn-img" className="icon-add"/>}
                        {editingAddress ? 
                        (
                            <span>{t('Globals.save')}</span>
                        ) : 
                        (
                            <span>{t('AdminPanel.addresses.save')}</span>
                        )}
                    </button>
                    {editingAddress && (
                        <button onClick={e => setEditingAddress(null)} className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} white bg-secondary-b2b admin-addresses-button btn-transparent align-items-center d-flex justify-content-center`}>
                            <span>{t('Globals.cancel')}</span>
                        </button>
                    )}
                </div>
            </div>
            <div className="item-addresses">
                {addresses && addresses.length > 0 ? (
                    addresses.map((address, index) => (
                        <AdminAddressItem key={index} address={address} editingAddress={setEditingAddress} deleteAddress={askForDelete}/>
                    ))
                ) : (
                    <div className="empty-addresses bg-white p-5">
                        <img src="/misc/icons/empty.svg" alt="bag" />
                        <span className="w-100 mt-3">{t("AdminPanel.addresses.empty")}</span>
                    </div>
                )}
            </div>
            <div className="w-100 row m-0 justify-content-center mt-3">
                <button 
                    onClick={() => showSaveAllModal()}
                    disabled={!addresses || addresses.length === 0}
                    className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} bg-dark-b2b white save-all-button btn-transparent align-items-center d-flex justify-content-center`}>
                    {fromEdit ? <span>{t('AdminPanel.edit.updateAddress')}</span> : <span>{t('AdminPanel.addresses.finish')}</span>}
                </button>
            </div>
            <div className="w-100 row m-0 justify-content-center">
                <button 
                    onClick={() => showAddresses(false)}
                    className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} skip-button btn-transparent align-items-center d-flex justify-content-center`}>
                    <span>{t('AdminPanel.addresses.skip')}</span>
                </button>
            </div>
            {createModal && (
                <ModalSimple
                    title={editingAddress ? t('EditAddressModal.title') : t('CreateAddressModal.title')}
                    description={
                        editingAddress ? (
                            editError ? t('EditAddressModal.descriptionError') : t('EditAddressModal.descriptionOk')
                        ) : (
                            createError ? t('CreateAddressModal.descriptionError') : t('CreateAddressModal.descriptionOk')
                        )
                    }
                    mainBtnText={t('Globals.ok')}
                    mainAction={hideCreateModal}
                    closeModal={hideCreateModal}
                />
            )}
            {saveAllModal && (
                <ModalSimple
                    title={t('SaveAllAddressModal.title')}
                    description={t('SaveAllAddressModal.description')}
                    mainBtnText={t('Globals.ok')}
                    mainAction={hideSaveAllModal}
                    closeModal={hideSaveAllModal}
                />
            )}
        </div>
    )
}

export default AdminAddresses;