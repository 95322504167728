import React, { useContext, useEffect, useState } from 'react';
import Slider from 'react-slick';
import DisplayUtils from '../../../../utils/DisplayUtils';
import filtersUtils from '../../../../utils/filtersUtils';
import constants from '../../../../utils/constants';
import { productsService } from '../../../../services/index';
import { SliderProducts } from '../../../index';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../../../contexts/AuthContext';
import { withRouter } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import { sanitize } from "dompurify";
import './BrandDetail.css';
import parse, {attributesToProps} from 'html-react-parser';
import {renderToStaticMarkup} from "react-dom/server";

const BrandDetail = ({ location, history }) => {
  const { t } = useTranslation();
  const brand = location.state.item;

  const { currentUser } = useContext(AuthContext);
  const [listCategories, setListCategories] = useState([]);

  useEffect(() => {
    const getCategories = async () => {
      const responseCategories = await productsService.getCategories();
      setListCategories(responseCategories[0].subcategories[0].subcategories);
    };

    getCategories();
  }, []);

  function videoDescription(item) {
    const options = {
      replace: node => {
        if (!node) return;

        if (node.name === 'source' && node.attribs.src && !constants.PROTOCOLS.test(node.attribs.src)) {
          const props = attributesToProps(node.attribs);
          props.src = constants.URL_IMAGES + props.src
          return <source {...props}/>;
        }
      }
    };

    return renderToStaticMarkup(parse(item, options));
  }

  const settings = {
    dots: true,
    infinite: true,
    arrows: false,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipe: DisplayUtils.isMobile(),
    customPaging: i => <div className="custom-paging"></div>,
  };
  
  const goToShop = async (event, data) => {
    const category = listCategories.find(item => item.id === Number(data));
    if (category) {
      const response = await filtersUtils.handleChangeFilters(event, data, listCategories);
      if (response) {
        localStorage.setItem(constants.CURRENT_FILTERS_KEY, JSON.stringify(response));
        history.push('/shop');
      }
    } else {
      history.push('/shop');
    }
  };

  return (
    <div className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} brand-detail-container bg-dbs-white`}>
      <div className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} brand-detail-content row m-0 w-100`}>
        <div className={`${DisplayUtils.isMobile() ? 'mobile col-12' : 'desktop col-6'} bg-dbs-white p-0`}>
          <Slider {...settings} className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} brand-slider`}>
            {brand.images.slice(1, brand.images.length).map((item, index) => (
              <div
                key={index}
                className={`${DisplayUtils.isMobile() ? 'd-flex justify-content-center' : ''} item-content`}
              >
                <img src={item} alt={index} />
              </div>
            ))}
          </Slider>
        </div>
        <div
          className={`${DisplayUtils.isMobile() ? 'mobile col-12' : 'desktop col-6'} brand-detail-info bg-dbs-white`}
        >
          <img alt="logo-img" className="brand-logo" src={brand.logo} />
          <div className="dbs-black brand-detail-description pt-4">
          {ReactHtmlParser(sanitize(videoDescription(brand.description), { ADD_ATTR: ['target'] }))}
          </div>
          <div className="d-flex justify-content-start">
            <button
              onClick={e => goToShop(e, brand.idCategory)}
              className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} see-btn btn-transparent bg-dbs-blue white`}
            >
              {t('Brands.shop')}
            </button>
          </div>
        </div>
      </div>
      <div className="separator bg-dbs-gray4" />
      {brand.products && brand.products.length > 0 && (
        <div className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} products-slider`}>
          <SliderProducts products={brand.products} brand currentUser={currentUser} />
        </div>
      )}
    </div>
  );
};

export default withRouter(BrandDetail);
