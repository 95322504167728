import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { BackBtn, Loading } from '../index';
import DisplayUtils from '../../utils/DisplayUtils';
import { useTranslation } from 'react-i18next';
import './TermsConditions.css';


const TermsConditions = ({ history }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let time = setTimeout(() => {
      setLoading(false);
    }, 600);
    return () => {
      clearTimeout(time)
    }
  }, []);;

  const onAccept = () => {
    history.goBack()
  }

  return (
    <div className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} terms-container`}>
      {/* <img alt="head-img" className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} head-image`} src="/misc/img/cookies-top.jpg"/> */}
      <BackBtn />
      {loading ? (
        <div style={{ height: '800px' }}>
          <Loading transparent />
        </div>
      ) : (
        <div className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} terms-content`}>
          <div className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop pb-3'} terms-text text-center`}>
            <span className="title d-block my-5">{t('TermsConditions.title')}</span>
            <span className="text d-block mb-3">{t('TermsConditions.text')}</span>
            <span className="text d-block mb-3">{t('TermsConditions.text')}</span>
            <span className="text d-block mb-3">{t('TermsConditions.text')}</span>
          </div>
          <div className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} d-flex justify-content-center`}>
            <button
              onClick={onAccept}
              className={`${
                DisplayUtils.isMobile() ? 'mobile' : 'desktop'
              } align-items-center d-flex justify-content-center btn-transparent bg-secondary-b2b white my-5 terms-btn`}
            >
              <span>{t('Globals.ok')}</span>
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default withRouter(TermsConditions);
