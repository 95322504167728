import React, { useState, useContext } from 'react';
import './TopFilters.css';
import DisplayUtils from '../../../../utils/DisplayUtils';
import { useTranslation } from 'react-i18next';
import { productsService } from '../../../../services/index';
import { AuthContext } from '../../../../contexts/AuthContext';

const TopFilters = ({
  selectLayoutType,
  layoutType,
  openSideFilters,
  getProducts,
  actuallyId,
  promotions,
  goToShop
}) => {
  const { t } = useTranslation();
  const [noSuggestion, setNoSuggestion] = useState(false);
  const [search, setSearch] = useState([]);
  const [inputSearch, setInputSearch] = useState('');
  const {
    currentSearchWord,
    setCurrentSearchWord,
    setSelectedWord,
    promotionsCurrentSearchWord,
    setPromotionsCurrentSearchWord,
    setPromotionsSelectedWord,
  } = useContext(AuthContext);

  const getSuggestion = async (word, id) => {
    try {
      const response = await productsService.getSuggestions(word);
      setSearch(response);
    } catch (error) {
      setSearch('Error get suggestions');
    }
  };
  const onSearch = word => {
    const newWord = word.replace(/[&\/\\#,+()$~%.'":*?<>{};=|!¡¿?\[\]]/g, ''); // eslint-disable-line
    setInputSearch(newWord);
    if (newWord.length >= 3) {
      getSuggestion(newWord, actuallyId);
    } else {
      setSearch([]);
    }
  };

  const searchSuggestion = (selected, word, event) => {
    setSelectedWord(selected);
    const options = selected
      ? { offset: 1, word, selected: selected }
      : { id: actuallyId, offset: 1, word, selected: selected };
      setInputSearch(word);
      setSearch([]);
      setCurrentSearchWord(word);
      getProducts(options);
      setInputSearch('');
      goToShop(event, actuallyId, '/shop')
  };

  const enterKey = key => {
    setNoSuggestion(false);
    if (key === 13 && inputSearch.length >= 3 && search.length > 0) {
      searchSuggestion(null, inputSearch);
    } else if (key === 13 && inputSearch.length > 2) {
      setNoSuggestion(true);
    }
  };

  const removeWordFilter = () => {
    const options = promotions ? { id: actuallyId, offset: 1 } : { id: actuallyId, offset: 1 };
    promotions ? setPromotionsCurrentSearchWord(null) : setCurrentSearchWord(null);
    promotions ? setPromotionsSelectedWord(null) : setSelectedWord(null);
    setInputSearch('');
    getProducts(options);
  };

  return (
    <div className="row top-filters-container m-0">
      {DisplayUtils.isMobile() && (
        <div className="col-6 align-items-center d-flex justify-content-center p-0 pr-2">

        <button
          className="filters-btn "
          onClick={() => openSideFilters(true)}
        >
          {t('Filters.filterMobileBtn').toUpperCase()}
        </button>
        </div>
      )}
      <div
        className={`${
          DisplayUtils.isMobile() ? 'mobile col-6 pr-0 pl-2' : 'desktop col-7 p-0'
        } d-flex justify-content-start search-container`}
      >
        <img alt="search-icon" src="/misc/icons/search_black@2x.png" />
        <input
          type="text"
          className="form-control"
          placeholder={DisplayUtils.isMobile() ? t('Filters.searchMobile') : t('Filters.search')}
          onChange={event => onSearch(event.target.value)}
          value={inputSearch}
          onKeyDown={event => enterKey(event.keyCode)}
        />
      </div>
      <div className={`${DisplayUtils.isMobile() ? 'col-3 p-0' : 'col-5'} d-flex justify-content-end`}>
        {/* {!DisplayUtils.isMobile() && !promotions &&  <ProductsCount productsInfo={productsInfo} black={true}/>} */}
        {!promotions && !DisplayUtils.isMobile() && (
          <div className="d-flex align-items-center">
            <button
              className={`${layoutType !== 'grid' && 'no-selected'} layout-btn p-0 icon-layout-grid`}
              onClick={() => selectLayoutType('grid')}
            />
            <button
              className={`${layoutType !== 'list' && 'no-selected'} layout-btn p-0 icon-layout-list`}
              onClick={() => selectLayoutType('list')}
            />
          </div>
        )}
      </div>

      {search.length > 0 && inputSearch.length >= 3 ? (
        <div
          className={`${
            DisplayUtils.isMobile() ? 'mobile col-12' : 'desktop col-7'
          } d-flex justify-content-start flex-column suggestions-container p-0 bg-white fadeInDown animated `}
        >
          {search.map(word => (
            <div key={word} className="px-3 py-2 dbs-gray2 bg-dbs-gray5" onClick={(e) => searchSuggestion(1, word, e)}>
              {word}
            </div>
          ))}
        </div>
      ) : (
        noSuggestion && (
          <div
            className={`${
              DisplayUtils.isMobile() ? 'mobile col-12' : 'desktop col-5'
            } d-flex justify-content-start flex-column suggestions-container p-0 bg-dbs-gray4 fadeInDown animated `}
          >
            <div className="px-3 pt-2 dbs-gray2">{`${t('Filters.exist')}${inputSearch}`}</div>
            <div className="px-3 pt-1 pb-2 dbs-gray2">{t('Filters.error')}</div>
          </div>
        )
      )}

      {currentSearchWord && !promotions && (
        <div
          className={`${
            DisplayUtils.isMobile() ? 'mobile col-12' : 'desktop col-12'
          } d-flex justify-content-start align-items-center p-1 mt-2 search-word-info`}
        >
          <span className="dbs-gray2">
            {t('Products.searchedInfo')}
            <span className="font-weight-bold dbs-gray2"> "{currentSearchWord}"</span>
          </span>
          <div
            className={`${
              DisplayUtils.isMobile() ? 'mobile' : 'desktop'
            } btn-close d-flex justify-content-center align-items-center ml-2 bg-dbs-gray1`}
            onClick={() => removeWordFilter()}
          >
            <img alt="closebtn" src="/misc/icons/add_white.png" />
          </div>
        </div>
      )}

      {promotionsCurrentSearchWord && promotions && (
        <div
          className={`${
            DisplayUtils.isMobile() ? 'mobile col-12' : 'desktop col-12'
          } d-flex justify-content-start align-items-center p-1 mt-2 search-word-info`}
        >
          <span className="white">
            {t('Products.searchedInfo')}
            <span className="font-weight-bold main-blue"> "{promotionsCurrentSearchWord}"</span>
          </span>
          <div
            className={`${
              DisplayUtils.isMobile() ? 'mobile' : 'desktop'
            } btn-close d-flex justify-content-center align-items-center ml-2`}
            onClick={() => removeWordFilter()}
          >
            <img alt="closebtn" src="/misc/icons/add_black.png" />
          </div>
        </div>
      )}
    </div>
  );
};

export default TopFilters;
