import React, { useState, useEffect, Fragment } from 'react';
import { Loading, ModalError } from '../../index';
import { Link, withRouter } from 'react-router-dom';
import { authService } from '../../../services/index';
import './ResetPass.css';
import DisplayUtils from '../../../utils/DisplayUtils';
import { useTranslation } from 'react-i18next';

const ResetPass = ({ location }) => {
  const { t } = useTranslation();

  const validations = {
    password: value => {
      let message;
      if (!value) {
        message = t('Errors.empty');
      } else if (value.length < 6) {
        message = t('Errors.notValid');
      }
      return message;
    },
    confPass: value => {
      let message;
      if (!value) {
        message = t('Errors.empty');
      } else if (value.length < 6) {
        message = t('Errors.notValid');
      } else if (value !== field.password) {
        message = t('Errors.notEqual');
      }
      return message;
    },
  };
  const [field, setField] = useState({
    password: '',
    confPass: '',
  });
  const [touch, setTouch] = useState({
    password: false,
    confPass: false,
  });
  const [errors, setErrors] = useState({
    password: validations.password(),
    confPass: validations.confPass(),
  });
  const [valueQuery, setValueQuery] = useState('');
  const [loading, setLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const [showPassConf, setShowPassConf] = useState(false);
  const [recoveryOk, setRecoveryOk] = useState(false)

  useEffect(() => {
    const query = location.search.split('?');
    const token = query[1].split('=')[1];

      setValueQuery(token);
    
  }, [location.search]);
  
  const handleChange = e => {
    const { name, value } = e.target;

    setField({
      ...field,
      [name]: value,
    });

    setErrors({
      ...errors,
      [name]: validations[name] && validations[name](value),
    });
  };

  const handleBlur = e => {
    const { name } = e.target;
    setTouch({
      ...touch,
      [name]: true,
    });
  };
  const showErrorModal = async () => {
    await setShowError(true);
    window.$('#errorModal').modal('show');
  };
  const hideErrorModal = () => {
    setShowError(false);
    window.$('#errorModal').modal('hide');
    window.$('.modal-backdrop').remove();
    window.$('body').removeClass('modal-open');
  };

  const onRecoverSubmit = async () => {
    setLoading(true)
    const data = {
      password: field.password,
      token: valueQuery,
    };
    try {
      const response = await authService.reset(data);
      response && setRecoveryOk(true)
      setLoading(false);
    } catch (error) {
      setLoading(false);
      showErrorModal();
    }
  };

  const isValid = () => {
    return Object.keys(field).some(attr => errors[attr]);
  };

  return (
    <div className="recovery-box">
      {showError && <ModalError hideErrorModal={hideErrorModal} />}
      <div
        className={`${
          !DisplayUtils.isMobile() ? 'desktop' : 'mobile'
        } text-box-recovery black row m-0 d-flex justify-content-center `}
      >
        {loading ? (
          <Loading transparent />
        ) : (
          <Fragment>
            <img
              className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} login-logo`}
              src="/misc/icons/logo-4.svg"
              alt="logo"
            />
            {!recoveryOk ? (
              <Fragment>
                <h3 className="d-flex justify-content-center text-center w-100 mb-4">{t('Recover.title')}</h3>
                <span className="d-flex justify-content-center description text-center w-100">
                  {t('Recover.description')}
                </span>
                <div
                  className={`${
                    !DisplayUtils.isMobile() ? 'desktop' : 'mobile'
                  } col-12 d-flex justify-content-center flex-column input-box`}
                >
                  <input
                    name="password"
                    type={showPass ? 'text' : 'password'}
                    placeholder={t('Recover.new')}
                    className={`${touch.password && errors.password ? 'is-invalid' : ''} form-control`}
                    id="inputField"
                    onChange={handleChange}
                    value={field.password}
                    onBlur={handleBlur}
                  />
                  {field.password.length > 0 && (
                    <button className="btn-transparent btn-eye" onClick={() => setShowPass(!showPass)}>
                      <img
                        src={!showPass ? '/misc/icons/eye.svg' : '/misc/icons/no_eye.svg'}
                        alt="back_login"
                        className="icon-login-eye"
                      />
                    </button>
                  )}
                </div>
                {touch.password && errors.password && (
                  <span className="red d-flex justify-content-start text-invalid">{errors.password}</span>
                )}
                <div
                  className={`${
                    !DisplayUtils.isMobile() ? 'desktop' : 'mobile mt-3'
                  } col-12 d-flex justify-content-center flex-column input-box`}
                >
                  <input
                    name="confPass"
                    type={showPassConf ? 'text' : 'password'}
                    placeholder={t('Recover.confirm')}
                    className={`${touch.confPass && errors.confPass ? 'is-invalid' : ''} form-control `}
                    id="inputFieldConf"
                    onChange={handleChange}
                    value={field.confPass}
                    onBlur={handleBlur}
                  />
                  {field.confPass.length > 0 && (
                    <button className="btn-transparent btn-eye" onClick={() => setShowPassConf(!showPassConf)}>
                      <img
                        src={!showPassConf ? '/misc/icons/eye.svg' : '/misc/icons/no_eye.svg'}
                        alt="back_login"
                        className="icon-login-eye"
                      />
                    </button>
                  )}
                </div>
                {touch.confPass && errors.confPass && (
                  <span className="red d-flex justify-content-start text-invalid">{errors.confPass}</span>
                )}

                <div
                  className={`${
                    !DisplayUtils.isMobile() ? 'desktop justify-content-center' : 'mobile justify-content-center'
                  } col-12 d-flex p-0`}
                >
                  <button
                    className={`${
                      !DisplayUtils.isMobile() ? 'desktop ' : 'mobile '
                    } bg-dark-b2b btn-transparent d-flex justify-content-center align-items-center mt-5 send-btn`}
                    onClick={onRecoverSubmit}
                    disabled={isValid()}
                  >
                    <span className="white">{t('Recover.send')}</span>
                  </button>
                </div>
              </Fragment>
            ) : (
              <Fragment>
                <h3 className="d-flex justify-content-center text-center w-100 mb-4">{t('Recover.OkTitle')}</h3>
                <span className="d-flex justify-content-center description text-center w-100">
                  {t('Recover.OkDescription')}
                </span>
                <div
                  className={`${
                    !DisplayUtils.isMobile() ? 'desktop justify-content-center' : 'mobile justify-content-center'
                  } col-12 d-flex p-0`}
                >
                  <Link to="/login">
                    <button
                      className={`${
                        !DisplayUtils.isMobile() ? 'desktop ' : 'mobile '
                      } bg-dark-b2b btn-transparent d-flex justify-content-center align-items-center mt-5 recover-ok-btn`}
                    >
                      <span className="white">{t('Globals.ok')}</span>
                    </button>
                  </Link>
                </div>
              </Fragment>
            )}
          </Fragment>
        )}
      </div>
    </div>
  );
};

export default withRouter(ResetPass);
