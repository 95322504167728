import React from 'react';
import DisplayUtils from '../../../../utils/DisplayUtils';
import './AdminMenu.css';

const AdminMenu = ({ options, selectedOption, optionSelected, logOut }) => {
  return (
    <div className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} white admin-menu-content`}>
      {DisplayUtils.isMobile() ? (
        <div className="menu-admin-mobile">
          <ul>
            <li
              className={`${selectedOption === 0 ? 'main-blue selected' : ''} pb-1`}
              onClick={() => optionSelected(0)}
            >
              <span>{options[0]}</span>
            </li>
            <li
              className={`${selectedOption === 1 ? 'main-blue selected' : ''} pb-1`}
              onClick={() => optionSelected(1)}
            >
              <span>{options[1]}</span>
            </li>
          </ul>
        </div>
      ) : (
        <ul>
          <li className="d-flex align-items-center mb-3" onClick={() => optionSelected(0)}>
            <img alt="orders-icon" src={selectedOption === 0 ? "/misc/icons/ALTA_CLIENTE_BLUE.svg" : "/misc/icons/Alta_cliente.svg"} />
            <span className={selectedOption === 0 ? 'main-blue' : ''}>{options[0]}</span>
          </li>
          <li className="d-flex align-items-center mb-3" onClick={() => optionSelected(1)}>
            <img alt="orders-icon" src={selectedOption === 1 ? "/misc/icons/LISTADO_CLIENTES_BLUE.svg" : "/misc/icons/Listado_clientes.svg"} />
            <span className={selectedOption === 1 ? 'main-blue' : ''}>{options[1]}</span>
          </li>
          <li className="d-flex align-items-center" onClick={() => logOut()}>
            <img alt="logout-icon" src="/misc/icons/logout_white@2x.png" />
            <span>{options[2]}</span>
          </li>
        </ul>
      )}
    </div>
  );
};

export default AdminMenu;
