const initialState = {
  expanded: [],
  preCat: [],
  actuallyCat: [],
  subCategories: [],
  secondNode: [],
  initCategories: [],
  currentPage: 1,
  breadcrumb: [],
};

const handleChangeFilters = (event, data, categories) => {
  const state = initialState;

  if (data === 'shop') {
    state.subCategories = [];
    state.secondNode = [];
    state.breadcrumb = [];
    state.preCat = [];
    state.actuallyCat = [];
    state.expanded = [];
    state.breadcrumb = [];
    state.currentPage = 1;

    return state;
  } else {
    const nodes = data.toString();
    let categoriesInit = [];
    let filterSub = [];

    categories.map(sub => categoriesInit.push(sub.id));
    state.initCategories = categoriesInit;

    const isSameCategory = state.initCategories.filter(n => n.toString() === nodes);
    // -------- Init tree -------- //
    if (state.expanded.length === 0 || isSameCategory.length === 1) {
      const filter = categories.filter(n => n.id.toString() === nodes);

      if (filter[0] && filter[0].subcategories) {
        state.subCategories = filter[0].subcategories;
        state.secondNode = filter[0].subcategories;

        filter[0].subcategories.map(sub => filterSub.push(sub.id.toString()));
      } else {
        state.subCategories = [];
        state.secondNode = [];
      }
      state.breadcrumb = [];
      state.preCat = [];
      state.actuallyCat = filter;
      state.expanded = [nodes];
      state.breadcrumb = [{ name: filter[0].name, id: nodes }];

      // -------- When the move is the same category -------- //
    } else if (state.actuallyCat.find(n => n.id.toString() === nodes)) {
      const filter = state.actuallyCat.filter(n => n.id.toString() === nodes);
      if (filter.length > 0 && filter[0].subcategories) {
        state.subCategories = filter[0].subcategories;
        state.expanded[0] = nodes;
        state.breadcrumb[state.breadcrumb.length - 1].name = filter[0].name;
        state.breadcrumb[state.breadcrumb.length - 1].id = nodes;
      } else {
        state.subCategories = [];
        state.expanded[0] = nodes;
        state.breadcrumb[state.breadcrumb.length - 1].name = filter[0].name;
        state.breadcrumb[state.breadcrumb.length - 1].id = nodes;
      }

      // -------- When the move is forward or backward -------- //
    } else {
      const isMoveBackwardOneNode = state.preCat.length > 0 && state.preCat.find(n => n.id.toString() === nodes);
      // -------- When the move is backward -------- //
      // -------- When the move is backward one node -------- //
      if (isMoveBackwardOneNode) {
        state.expanded.splice(0, 1);
        state.expanded[0] = nodes;

        const filter = state.preCat.filter(n => n.id.toString() === nodes);

        state.breadcrumb.splice(state.breadcrumb.length - 1, 1);
        state.breadcrumb[state.breadcrumb.length - 1].name = filter[0].name;
        state.breadcrumb[state.breadcrumb.length - 1].id = nodes;

        filter && filter[0].subcategories
          ? (state.subCategories = filter[0].subcategories)
          : (state.subCategories = []);

        state.actuallyCat = state.preCat;

        const filterSecond = state.secondNode.filter(n => n.id.toString() === nodes);
        filterSecond[0] && filter[0] ? (state.preCat = []) : (state.preCat = state.secondNode);

        // -------- When the move is backward two nodes-------- //
      } else if (state.subCategories.length === 0) {
        state.expanded.splice(0, 2);
        state.expanded[0] = nodes;

        const filter = state.secondNode.filter(n => n.id.toString() === nodes);
        state.breadcrumb.splice(state.breadcrumb.length - 2, 2);
        state.breadcrumb[state.breadcrumb.length - 1].name = filter[0].name;
        state.breadcrumb[state.breadcrumb.length - 1].id = nodes;

        filter && filter[0].subcategories
          ? (state.subCategories = filter[0].subcategories)
          : (state.subCategories = []);

        state.preCat = [];
        state.actuallyCat = state.secondNode;
        // -------- When the move is forward -------- //
      } else {
        const filterSub = state.subCategories.filter(n => n.id.toString() === nodes);

        if (filterSub[0] && filterSub[0].subcategories) {
          if (state.expanded.length >= 1) {
            state.expanded.unshift(nodes);
            state.breadcrumb.push({ name: filterSub[0].name, id: nodes });
          }

          state.preCat = state.actuallyCat;

          state.actuallyCat = state.subCategories;

          state.subCategories = filterSub[0].subcategories;

          filterSub[0].subcategories.map(sub => filterSub.push(sub.id.toString()));
        } else {
          state.expanded.unshift(nodes);
          state.breadcrumb.push({ name: filterSub[0].name, id: nodes });

          state.preCat = state.actuallyCat;
          state.actuallyCat = state.subCategories;
          state.subCategories = [];
        }
      }
    }
    return state;
  }
};

const updateFilters = (categories, product) => {
  let state = initialState;
  let categoriesInit = [];
  if(product.id === 1){
    product = product.subcategories[0]
  }
  if(product.id === 2){
    product = product.subcategories[0]
  }

  categories.map(sub => categoriesInit.push(sub.id));
  state.initCategories = categoriesInit;
  const filter = categories.filter(n => n.id === product.id);
  state.expanded = [product.id.toString()];
  state.secondNode = filter[0].subcategories;
  state.subCategories = filter[0].subcategories || [];
  state.breadcrumb = [{ name: filter[0].name, id: product.id.toString() }];

  const areSubCategories = item => {
    let itemSub = item;

    while (itemSub) {
      const filter = state.subCategories.filter(n => n.id === itemSub[0].id); // eslint-disable-line
      state.expanded.unshift(filter[0].id.toString());
      state.preCat = state.actuallyCat;
      state.actuallyCat = state.subCategories;
      state.subCategories = filter[0].subcategories || [];
      state.breadcrumb.push({ name: filter[0].name, id: filter[0].id.toString() });
      itemSub = itemSub[0].subcategories;
      return areSubCategories(itemSub);
    }
    return state;
  };

  areSubCategories(product.subcategories);
  return state;
};

export default { handleChangeFilters, updateFilters };
