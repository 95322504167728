import React, { useState, Fragment } from 'react';
import ReactPlayer from 'react-player';
import DisplayUtils from '../../../utils/DisplayUtils';
import './Media.css';

const Media = ({ url, image }) => {
  const [showVideo, setShowVideo] = useState(false);
  const [randomKey, setRandomKey] = useState(0);
  const [isVisible, setIsVisible] = useState(false);

  const config = {
    root: null,
    rootMargin: "0px",
    threshold: 0.5
  }

  const box = document.getElementById("player_react")
  
  const observer = new IntersectionObserver((entries) => entries
    .forEach(({target: {classList}, intersectionRatio}) => {
      if(intersectionRatio > 0.5){
        setIsVisible(true)
      }
      else{
        setIsVisible(false)
      }
    }), config)

  if(box) observer.observe(box)

  const onShowVideo = () => {
    setShowVideo(true);
  };

  const onCloseVideo = () => {
    setShowVideo(false);
  };

  return (
    <div className="media-container bg-dbs-gray4" id="player_react">
      <div
        className={`${
          DisplayUtils.isMobile() ? 'mobile' : 'desktop'
        } media-content d-flex align-items-center justify-content-center`}
      >
        {showVideo ? (
          <Fragment>
            <ReactPlayer
              key={randomKey}
              className="react-player"
              url={url}
              width="100%"
              height="100%"
              playing={showVideo && isVisible}
              config={{
                file: {
                  attributes: {
                    autoPlay: false,
                  },
                },
              }}
              onPause={() => setRandomKey(Math.random())}
            />
            <button className="btn-close btn-transparent align-self-end" onClick={() => onCloseVideo()}>
              <img src="/misc/icons/close_button@2x.png" alt="btn-media" />
            </button>
          </Fragment>
        ) : (
          <Fragment>
            <div className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} img-video-box`}>
              <img src={image} alt="btn-media" />
            </div>
            <button className="btn-play align-self-center btn-transparent" onClick={() => onShowVideo()}>
              <img src="/misc/icons/media_play.svg" alt="btn-media" />
            </button>
          </Fragment>
        )}
      </div>
    </div>
  );
};

export default Media;
