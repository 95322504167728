import React from 'react';
import { Link } from 'react-router-dom';
import DisplayUtils from '../../../utils/DisplayUtils';
import constants from '../../../utils/constants';
import CartUtils from '../../../utils/CartUtils';
import { useTranslation } from 'react-i18next';
import './ListPromotions.css';

const ListPromotions = ({ listProducts }) => {
  const { t } = useTranslation();

  const filterProducts =
    listProducts &&
    listProducts.map(product => {
      if (!product.sizes || !product.sizes.name) {
        return product;
      } else {
        return {
          ...product,
          // id: product.sizes.id ? product.id + '.' + product.sizes.id : product.id,
          name: product.sizes.name ? product.name + ' ' + product.sizes.name : product.name,
          price: product.sizes.price ? product.sizes.price : product.price,
          pricepvp: product.sizes.pricepvp ? product.sizes.pricepvp : product.pricepvp,
          images: product.sizes.image ? [product.sizes.image] : product.images,
          promotion: product.sizes.promotion ? product.sizes.promotion : product.promotion,
          promotionType: product.sizes.promotionType ? product.sizes.promotionType : product.promotionType,
        };
      }
    });

  return (
    <div className="promotions-list-box ">
      {/* <div className="container-products-top bg-dbs-white"></div> */}
      <div
        className="container-products-bottom bg-dbs-white row m-0"
      >
        <div className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} products-promotion`}>
          {filterProducts.map((item, index) => (
            <div key={index} className={`${DisplayUtils.isMobile() ? 'col-6' : 'col-3 '} mt-3`}>
              <div className="link-container">
                <Link to={{ pathname: '/product-detail', state: { item } }}>
                  <div className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} promotions-product`}>
                    <img
                      src={DisplayUtils.getProductImage(item, constants.NO_IMAGE)}
                      className="top-img"
                      alt="top"
                      onError={e => {
                        e.target.onerror = null;
                        e.target.src = `${constants.NO_IMAGE}`;
                      }}
                    />
                  </div>
                  <div className="separator bg-dbs-gray4" />
                  <div className="mt-2 row m-0 align-middle">
                    <div className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} product-name`}>
                      <span className="black row m-0 name-text"> {item.name} </span>
                      <span className="description dbs-gray3">
                        {item.descriptionshort.replace(constants.REMOVE_HTML_PATTERN, '')}
                      </span>
                    </div>
                  </div>
                  {!item.promotion ? (
                    <div
                      className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} product-price p-0 d-flex row m-0`}
                    >
                      <span className="black">{CartUtils.formatPrice(item.price)}</span>
                      <span className="price pvpr col-12 p-0">
                        ({item.pricepvp !== 0 && `${t('Products.pricePvp')} `}{CartUtils.formatPricePvpr(item.pricepvp.toFixed(2))})
                      </span>
                    </div>
                  ) : (
                    <div
                      className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} product-price p-0 d-flex row m-0`}
                    >
                      <span className="mr-2 price-before"> {CartUtils.formatPrice(item.price)} </span>
                      <span className="black">
                        {' '}
                        {t('Products.now')}
                        {CartUtils.formatPrice(
                          CartUtils.getPricePromotion(item.price, item.promotion, item.promotionType)
                        )}
                      </span>
                      <span className="price pvpr col-12 p-0">
                      ({item.pricepvp !== 0 && `${t('Products.pricePvp')} `}{CartUtils.formatPricePvpr(item.pricepvp.toFixed(2))})
                      </span>
                    </div>
                  )}
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ListPromotions;
