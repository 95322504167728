import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import DisplayUtils from '../../../utils/DisplayUtils';
import './NotFound.css';

const NotFound = () => {
  const { t } = useTranslation();

  return (
    <div className="not-found-container w-100 h-100 d-flex align-items-center bg-light-b2b">
      <div className={`${!DisplayUtils.isMobile() ? 'desktop' : 'mobile'} not-found-content w-100  `}>
        <div className="row m-0 w-100 d-flex justify-content-center">
          <img
            className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} logo `}
            src="/misc/icons/logo-dbs-black.svg"
            alt="logo"
          />
          <span
            className={`${
              !DisplayUtils.isMobile() ? 'desktop' : 'mobile'
            } col-12 title d-flex justify-content-center main-blue`}
          >
            {t('NotFound.404')}
          </span>
          <span
            className={`${
              !DisplayUtils.isMobile() ? 'desktop' : 'mobile'
            } col-12 subtitle d-flex justify-content-center black`}
          >
            {t('NotFound.title')}
          </span>
          <span
            className={`${
              !DisplayUtils.isMobile() ? 'desktop' : 'mobile'
            } col-12 description d-flex justify-content-center text-center `}
          >
            {t('NotFound.description')}
          </span>
        </div>
        <div className="row m-0 w-100 d-flex justify-content-center mt-5">
          <Link to={'/'}>
            <button
              className={`${
                DisplayUtils.isMobile() ? 'mobile' : 'desktop'
              } btn-home btn-transparent mt-3 d-flex justify-content-center align-items-center`}
            >
              <span>{t('NotFound.back').toUpperCase()}</span>
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
