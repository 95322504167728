import http from './base-http-service';
import constants from '../utils/constants';

const sendSupport = async (message) => {
  const config = { headers: { Authorization: localStorage.getItem(constants.CURRENT_TOKEN_KEY) } };
  try {
    const response = await http.post('/users/support', message, config);
    return response.data;
  } catch (error) {
    throw error;
  }
};
const getSupport = async () => {
  try {
    const response = await http.get('/media/support');
    return response.data;
  } catch (error) {
    throw error;
  }
};

const getCountries = async () => {
  const config = { headers: { Authorization: localStorage.getItem(constants.CURRENT_TOKEN_KEY) } };
  try{
    const response = await http.get('/users/countries', config)
    return response.data
  } catch(error) {
    throw error;
  }
}

const getProvinces = async () => {
  const config = { headers: { Authorization: localStorage.getItem(constants.CURRENT_TOKEN_KEY) } };
  try{
    const response = await http.get('/users/provinces', config)
    return response.data
  } catch(error) {
    throw error;
  }
}

const getAddresses = async (userId) => {
  const config = { headers: { Authorization: localStorage.getItem(constants.CURRENT_TOKEN_KEY) } };
  try {
    const response = await http.get(`/users/${userId}/address`, config);
    if (response.status === 200) {
      return response.data;
    } else {
      throw response.data;
    }
  } catch (error) {
    throw error.response.data;
  }
}

export default {
    sendSupport,
    getCountries,
    getProvinces,
    getAddresses,
    getSupport
};