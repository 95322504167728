import constants from '../utils/constants';

const updateLocalCart = async (setCart, cart) => {
  await setCart(cart);
  localStorage.setItem(constants.CURRENT_CART_KEY, JSON.stringify(cart));
  return cart;
};

const addToCart = async (currentCart, setCurrentCart, product, quantity, size) => {
  let cart = currentCart;
  !cart && (cart = []);
  let productIndex = cart.findIndex(item => {
    if (!size) {
      return item.id === product.id;
    } else {
      return item.id === product.id + '.' + size.id;
    }
  });
  if (productIndex < 0) {
    const newProduct = [
      {
        ...product,
        id: size ? product.id + '.' + size.id : product.id,
        quantity: quantity,
      },
      ...cart,
    ];
    cart = newProduct;
  } else {
    cart[productIndex].quantity += quantity;
  }
  await updateLocalCart(setCurrentCart, cart);
  return cart;
};

const updateQuantity = (currentCart, setCurrentCart, product, quantity) => {
  let productIndex = currentCart.findIndex(item => item.id === product.id);
  if (productIndex >= 0) {
    currentCart[productIndex].quantity = quantity;
    updateLocalCart(setCurrentCart, currentCart);
  }
};

const deleteToCart = (currentCart, setCurrentCart, product) => {
  const deletedProduct = currentCart.filter(item => item.id !== product.id);
  updateLocalCart(setCurrentCart, deletedProduct);
};

const getProductQuantity = (currentCart, product) => {
  let productIndex = currentCart.findIndex(item => item.id === product.id);
  if (productIndex >= 0) {
    return currentCart[productIndex].quantity;
  }
};
const getExpiration = item => {
  const nowDate = new Date();
  const newSession = new Date(item);
  return nowDate.setHours(0, 0, 0, 0) > newSession.setHours(0, 0, 0, 0);
};

const getPricePromotion = (price, promotion, type, expiration) => {
  const expired = expiration && getExpiration(expiration);
  if (!type || expired) {
    return price.toFixed(2);
  } else if (type === 'percentage') {
    return (price - price * promotion).toFixed(2);
  } else {
    return (price - promotion).toFixed(2);
  }
};
const getPriceNotTax = price => {
  return (price - parseInt(price) * 0.21).toFixed(2);
};

const getPriceWithTax = price => {
  return (parseFloat(price) + parseFloat(price) * 0.21).toFixed(2);
};

const getTotalCart = currentCart => {
  return currentCart && currentCart.length > 0
    ? currentCart
        .map(
          product =>
            product.quantity *
            getPricePromotion(product.price, product.promotion, product.promotionType, product.expiration)
        )
        .reduce((a, b) => a + b)
    : 0;
};

const formatPrice = price => 
  price <= 0 ? '-- €' : 
  `${Number(parseFloat(price).toFixed(2)).toLocaleString('es', { minimumFractionDigits: 2 })} €`;

const formatPricePvpr = price => 
  price <= 0 ? 'profesional' : 
  `${Number(parseFloat(price).toFixed(2)).toLocaleString('es', { minimumFractionDigits: 2 })} €`;


export default {
  getExpiration,
  addToCart,
  getPriceNotTax,
  updateQuantity,
  deleteToCart,
  getProductQuantity,
  getTotalCart,
  getPricePromotion,
  getPriceWithTax,
  formatPrice,
  formatPricePvpr,
};
