import React, { useState, useEffect, useContext, Fragment } from 'react';
import { CartCount, RegisterBtn } from '../../index';
import DisplayUtils from '../../../utils/DisplayUtils';
import CartUtils from '../../../utils/CartUtils';
import constants from '../../../utils/constants';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../../contexts/AuthContext';
import './ProductSizesItem.css';

const ProductSizesItem = ({
  product,
  size,
  itemClicked,
  selectedSize,
  itemOver,
  addToCart,
  productSize,
  overImageToShow,
}) => {
  const { t } = useTranslation();
  const [productCount, setProductCount] = useState(1);
  const [expired, setExpired] = useState(false);
  const { currentUser } = useContext(AuthContext);

  useEffect(() => {
    const getExpired = async () => {
      const promotionExpired = await CartUtils.getExpiration(size.expiration);
      setExpired(promotionExpired);
    };
    size && size.promotion && size.expiration && getExpired();
  }, [size]);

  const editQuantity = quantity => {
    setProductCount(quantity);
  };

  const getProductImage = () => {
    if (product) {
      if (product.images && product.images[0]) {
        const newArr = product.images[0].split('/');
        if (
          newArr[newArr.length - 1] === 'undefined.jpg' ||
          newArr[newArr.length - 1] === 'null.jpg' ||
          newArr[newArr.length - 1] === 'undefined.png' ||
          newArr[newArr.length - 1] === 'null.png'
        ) {
          return constants.NO_IMAGE;
        } else {
          return product.images[0];
        }
      } else {
        return constants.NO_IMAGE;
      }
    } else {
      if (size.image) {
        const newArr = size.image.split('/');
        if (
          newArr[newArr.length - 1] === 'undefined.jpg' ||
          newArr[newArr.length - 1] === 'null.jpg' ||
          newArr[newArr.length - 1] === 'undefined.png' ||
          newArr[newArr.length - 1] === 'null.png'
        ) {
          return constants.NO_IMAGE;
        } else {
          return size.image;
        }
      } else {
        return constants.NO_IMAGE;
      }
    }
  };

  const formatToCart = (size, productCount) => {
    if (productSize) {
      const filterProduct = {
        ...productSize,
        name: productSize.name + ' ' + size.name,
        price: size.price,
        pricepvp: size.pricepvp,
        images: [size.image],
        promotion: size.promotion,
        promotionType: size.promotionType,
      };

      addToCart(filterProduct, productCount, size);
    } else {
      addToCart(product, productCount);
    }
  };

  const desktopListSize = () => (
    <div
      className={`${
        size.id === selectedSize && !overImageToShow ? 'selected' : ''
      } row w-100 m-0 product-size-content d-flex align-items-center`}
      onClick={() => itemClicked(size)}
      onMouseOver={() => itemOver(size)}
      onMouseLeave={() => itemOver(null)}
    >
      <div className="col-1 img-size p-0">
        <img
          src={getProductImage()}
          alt="img-product"
          onError={e => {
            e.target.onerror = null;
            e.target.src = `${constants.NO_IMAGE}`;
          }}
        />
      </div>
      <div className="justify-content-start col-3 d-flex align-items-center p-0">
        <span className="size-name w-100">{size.name}</span>
      </div>
      {!currentUser ? (
        <div className="col-8 h-100 m-0 d-flex align-items-center justify-content-end">
          <RegisterBtn position="text-left" />
        </div>
      ) : (
        typeof size.price === 'number' && (
          <div className="col-8 h-100 m-0 d-flex align-items-center justify-content-end">
            <div className="d-flex justify-content-end">
              {!size.promotion || expired ? (
                <span className="desktop size-price">{CartUtils.formatPrice(size.price)}</span>
              ) : (
                <div>
                  <span className="size-price-before d-flex d-block align-items-center justify-content-center">
                    {CartUtils.formatPrice(size.price.toFixed(2))}
                  </span>
                  <span className="size-price d-flex d-block align-items-center justify-content-center">
                    {CartUtils.formatPrice(
                      CartUtils.getPricePromotion(size.price.toFixed(2), size.promotion, size.promotionType)
                    )}
                  </span>
                </div>
              )}
              <span className="desktop size-price-pvpr d-flex align-items-center justify-content-center">
                ({size.pricepvp !== 0 && `${t('Products.pricePvp')} `}{CartUtils.formatPricePvpr(size.pricepvp)})
              </span>
            </div>
            <div className="d-inline-flex align-items-center">
              <CartCount quantity={productCount} editQuantity={editQuantity} />
            </div>
            <button
              disabled={productCount < 1}
              className="cart-btn d-flex align-items-center justify-content-center"
              onClick={() => formatToCart(size, productCount)}
            >
              {t('Products.add')}
            </button>
          </div>
        )
      )}
    </div>
  );

  const desktopListProduct = () => (
    <div className="selected row w-100 m-0 product-size-content d-flex align-items-center">
      <div className="col-1 img-size p-0">
        <img
          src={getProductImage()}
          alt="img-product"
          onError={e => {
            e.target.onerror = null;
            e.target.src = `${constants.NO_IMAGE}`;
          }}
        />
      </div>
      <div className="justify-content-start col-3 d-flex align-items-center p-0">
        <span className="size-name w-100">{product.name}</span>
      </div>
      {!currentUser ? (
        <div className="col-8 h-100 m-0 d-flex align-items-center justify-content-end">
          <RegisterBtn position="text-left" />
        </div>
      ) : (
        typeof product.price === 'number' && (
          <div className="col-8 h-100 m-0 d-flex align-items-center justify-content-end">
            <div className="d-flex justify-content-end">
              {!product.promotion || expired ? (
                <span className="desktop size-price">{CartUtils.formatPrice(product.price)}</span>
              ) : (
                <div>
                  <span className="size-price-before d-flex d-block align-items-center justify-content-center">
                    {product.price && CartUtils.formatPrice(product.price.toFixed(2))}
                  </span>
                  <span className="size-price d-flex d-block align-items-center justify-content-center">
                    {product.price &&
                      CartUtils.formatPrice(
                        CartUtils.getPricePromotion(product.price.toFixed(2), product.promotion, product.promotionType)
                      )}
                  </span>
                </div>
              )}
              <span className="desktop size-price-pvpr d-flex align-items-center justify-content-center">
                 ({product.pricepvp !== 0 && `${t('Products.pricePvp')} `}{CartUtils.formatPricePvpr(product.pricepvp)})
              </span>
            </div>
            <div className="d-inline-flex align-items-center">
              <CartCount quantity={productCount} editQuantity={editQuantity} />
            </div>
            <button
              disabled={productCount < 1}
              className="cart-btn d-flex align-items-center justify-content-center"
              onClick={() => formatToCart(size, productCount)}
            >
              {t('Products.add')}
            </button>
          </div>
        )
      )}
    </div>
  );

  const mobileListSize = () => (
    <div
      className={`${
        size.id === selectedSize ? 'selected' : ''
      } row w-100 m-0 product-size-content-mobile d-flex align-items-center`}
      onClick={() => itemClicked(size)}
    >
      <div className="col-1 img-size-mobile p-0">
        <img src={getProductImage()} alt="img-product" />
      </div>
      <div className="justify-content-start col-7 d-flex align-items-center p-0">
        <div className="row m-0 w-100">
          <span className="size-name-mobile col-12 pt-0 pb-0">{size.name}</span>
          <div className="col-12 p-0">
            {!currentUser ? (
              <div className="col-12 h-100 m-0 d-flex align-items-center justify-content-end">
                <RegisterBtn position="text-left" />
              </div>
            ) : typeof size.price === 'number' && (!size.promotion || expired) ? (
              <div>
                <span className="size-price-mobile-no-offer col-12">{CartUtils.formatPrice(size.price)}</span>
                <span className="size-price-pvpr-mobile"> ({size.pricepvp !== 0 && `${t('Products.pricePvp')} `}{CartUtils.formatPricePvpr(size.pricepvp)})</span>
              </div>
            ) : (
              <Fragment>
                <div>
                  <span className="size-price-before-mobile">{CartUtils.formatPrice(size.price.toFixed(2))}</span>
                  <span className="size-price-mobile">
                    {CartUtils.formatPrice(
                      CartUtils.getPricePromotion(size.price.toFixed(2), size.promotion, size.promotionType)
                    )}
                  </span>
                </div>

                <span className="size-price-pvpr-mobile"> ({size.pricepvp !== 0 && `${t('Products.pricePvp')} `}{CartUtils.formatPricePvpr(size.pricepvp)})</span>
              </Fragment>
            )}
          </div>
        </div>
      </div>
      {currentUser && (
        <div className="col-4 p-0 h-100 m-0 d-flex align-items-center justify-content-end">
          <div className="row m-0 w-100">
            <div className="col-12 p-0 d-flex align-items-center justify-content-center">
              <CartCount quantity={productCount} editQuantity={editQuantity} />
            </div>
            <div className="col-12 p-0 d-flex align-items-center justify-content-center mt-2">
              <button
                disabled={productCount < 1}
                className="cart-btn-mobile d-flex align-items-center justify-content-center"
                onClick={() => formatToCart(size, productCount)}
              >
                {t('Products.add')}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );

  const mobileListProduct = () => (
    <div className="selected row w-100 m-0 product-size-content-mobile d-flex align-items-center">
      <div className="col-1 img-size-mobile p-0">
        <img src={getProductImage()} alt="img-product" />
      </div>
      <div className="justify-content-start col-7 d-flex align-items-center p-0">
        <div className="row m-0 w-100">
          <span className="size-name-mobile col-12 pt-0 pb-0">{product.name}</span>
          <div className="col-12 p-0">
            {!currentUser ? (
              <div className="col-12 h-100 m-0 d-flex align-items-center justify-content-end">
                <RegisterBtn position="text-left" />
              </div>
            ) : typeof product.price === 'number' && (!product.promotion || expired) ? (
              <div>
                <span className="size-price-mobile-no-offer col-12">{CartUtils.formatPrice(product.price)}</span>
                <span className="size-price-pvpr-mobile">({product.pricepvp !== 0 && `${t('Products.pricePvp')} `}{CartUtils.formatPricePvpr(product.pricepvp)})</span>
              </div>
            ) : (
              <Fragment>
                <div>
                  <span className="size-price-before-mobile">
                    {product.price && CartUtils.formatPrice(product.price.toFixed(2))}
                  </span>
                  <span className="size-price-mobile">
                    {product.price &&
                      CartUtils.formatPrice(
                        CartUtils.getPricePromotion(product.price.toFixed(2), product.promotion, product.promotionType)
                      )}
                  </span>
                </div>

                <span className="size-price-pvpr-mobile">({product.pricepvp !== 0 && `${t('Products.pricePvp')} `}{CartUtils.formatPricePvpr(product.pricepvp)})</span>
              </Fragment>
            )}
          </div>
        </div>
      </div>
      {currentUser && (
        <div className="col-4 p-0 h-100 m-0 d-flex align-items-center justify-content-end">
          <div className="row m-0 w-100">
            <div className="col-12 p-0 d-flex align-items-center justify-content-center">
              <CartCount quantity={productCount} editQuantity={editQuantity} />
            </div>
            <div className="col-12 p-0 d-flex align-items-center justify-content-center mt-2">
              <button
                disabled={productCount < 1}
                className="cart-btn-mobile d-flex align-items-center justify-content-center"
                onClick={() => formatToCart(size, productCount)}
              >
                {t('Products.add')}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );

  return DisplayUtils.isMobile()
    ? product
      ? mobileListProduct()
      : mobileListSize()
    : product
    ? desktopListProduct()
    : desktopListSize();
};

export default ProductSizesItem;
