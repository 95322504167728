import React from 'react';
import CartUtils from '../../../../utils/CartUtils';
import constants from '../../../../utils/constants';
import './OrderProductDetailItem.css';
import DisplayUtils from '../../../../utils/DisplayUtils';

const OrderProductDetailItem = ({ product }) => (
  <div>
    <div className="row order-product-detail-item">
      <div className="desktop justify-content-between d-flex align-items-center col-12 p-0">
        <div className="col-1 img-item p-0">
          <img
            src={DisplayUtils.getProductImage(product, constants.NO_IMAGE)}
            alt="img"
            onError={e => {
              e.target.onerror = null;
              e.target.src = `${constants.NO_IMAGE}`;
            }}
          />
        </div>
        <div className="col-4 p-0">
          <span className="desktop product-name font-weight-bold d-block w-100">{product.name}</span>
          <span className="desktop product-name d-block w-100 dbs-gray3">
            {product.descriptionshort.replace(constants.REMOVE_HTML_PATTERN, '')}
          </span>
        </div>
        <div className="col-2 p-0 d-flex justify-content-center">
          <span className="desktop product-price-pvpr">({CartUtils.formatPricePvpr(product.originalPvp.toFixed(2))})</span>
        </div>
        <div className="col-1 p-0 d-flex justify-content-center">
          <span className="desktop product-quantity">{product.quantity}</span>
        </div>
        <div className="col-2 p-0 d-flex justify-content-center">
          <span className="desktop product-price" style={{ fontWeight: 300}}>{CartUtils.formatPrice(product.priceOrder.toFixed(2))}</span>
        </div>
        <div className="col-2 p-0 d-flex justify-content-center">
          <span className="desktop product-price">
            {CartUtils.formatPrice((product.priceOrder * product.quantity).toFixed(2))}
          </span>
        </div>
      </div>
    </div>
  </div>
);

export default OrderProductDetailItem;
