import React, { useReducer, useState, useEffect } from 'react';
import DisplayUtils from '../../../../utils/DisplayUtils';
import constants from '../../../../utils/constants';
import { useTranslation } from 'react-i18next';
import { Loading, ModalSimple, ModalRegister } from '../../../index';
import { adminService } from '../../../../services/index';
import './EditUser.css';

const initialState = {
  formAlias: '',
  formName: '',
  formSurname: '',
  formCommerce: '',
  formCif: '',
  formPhone: '',
  formEmail: '',
  formConfEmail: '',
  errors: {},
};

const reducer = (state, action) => {
  state.errors[action.type] = !action.payload;
  switch (action.type) {
    case 'formAlias':
      return { ...state, formAlias: action.payload };
    case 'formName':
      return { ...state, formName: action.payload };
    case 'formSurname':
      return { ...state, formSurname: action.payload };
    case 'formCommerce':
      return { ...state, formCommerce: action.payload };
    case 'formCif':
      return { ...state, formCif: action.payload };
    case 'formPhone':
      return { ...state, formPhone: action.payload };
    case 'formEmail':
      return { ...state, formEmail: action.payload };
    case 'formConfEmail':
      return { ...state, formConfEmail: action.payload };
    default:
      return initialState;
  }
};

const EditUser = ({ user, closeEdit, openAddresses }) => {
  const { t } = useTranslation();

  const [state, dispatch] = useReducer(reducer, initialState);
  const [loading, setLoading] = useState(false);
  const [editErrorModal, setEditErrorModal] = useState(false);
  const [editOkModal, setEditOkModal] = useState(false);

  const emptyError = t('Errors.empty');
  const format = t('Errors.notValid');
  const emailNotEqual = t('Errors.emailNotEqual');

  useEffect(() => {
    const fillData = user => {
      setLoading(true);
      dispatch({ type: 'formAlias', payload: user.alias ? user.alias : '' });
      dispatch({ type: 'formName', payload: user.firstname ? user.firstname : '' });
      dispatch({ type: 'formSurname', payload: user.lastname ? user.lastname : '' });
      dispatch({ type: 'formCommerce', payload: user.company ? user.company : '' });
      dispatch({ type: 'formCif', payload: user.taxId ? user.taxId : '' });
      dispatch({ type: 'formPhone', payload: user.phone ? user.phone : '' });
      dispatch({ type: 'formEmail', payload: user.email ? user.email : '' });
      dispatch({ type: 'formConfEmail', payload: user.email ? user.email : '' });
      setLoading(false);
    };
    fillData(user);
  }, [user]);

  const isValid = () => {
    return (
      Object.values(state).some(item => item === '') ||
      !constants.EMAIL_PATTERN.test(state.formEmail) ||
      state.formEmail !== state.formConfEmail ||
      !constants.NAME_PATTERN.test(state.formName) ||
      !constants.NAME_PATTERN.test(state.formSurname)
    );
  };

  const handleSubmit = async event => {
    setLoading(true);
    dispatch({ type: 'initialState' });
    event.preventDefault();
    const editedUser = {
      alias: state.formAlias,
      id: user.key,
      firstname: state.formName,
      lastname: state.formSurname,
      company: state.formCommerce,
      taxId: state.formCif,
      phone: state.formPhone,
      email: state.formEmail,
    };
    try {
      const data = await adminService.updateUser(editedUser);
      if (data) {
        setLoading(false);
        showEditOkModal();
      }
    } catch (error) {
      setLoading(false);
      showEditErrorModal();
    }
  };

  const goToAddresses = () => {
    hideEditOkModal();
    openAddresses(user.key);
  };

  const skipAddresses = () => {
    hideEditOkModal();
    closeEdit();
  };

  const showEditOkModal = async () => {
    await setEditOkModal(true);
    window.$('#registerModal').modal('show');
  };

  const hideEditOkModal = () => {
    setEditOkModal(false);
    window.$('#registerModal').modal('hide');
    window.$('.modal-backdrop').remove();
    window.$('body').removeClass('modal-open');
  };

  const showEditErrorModal = async () => {
    await setEditErrorModal(true);
    window.$('#simpleModal').modal('show');
  };

  const hideEditErrorModal = () => {
    setEditErrorModal(false);
    window.$('#simpleModal').modal('hide');
    window.$('.modal-backdrop').remove();
    window.$('body').removeClass('modal-open');
  };

  const inputs = [
    'formAlias',
    'formName',
    'formSurname',
    'formCommerce',
    'formCif',
    'formPhone',
    'formEmail',
    'formConfEmail',
  ];

  return loading ? (
    <Loading transparent />
  ) : (
    <div className={`${DisplayUtils.isMobile() ? 'mobile ' : 'desktop '} admin-edit-container bg-light-b2b`}>
      <button
        className={`${
          DisplayUtils.isMobile() ? 'mobile' : 'desktop'
        } btn-transparent back-btn d-flex align-items-start`}
        onClick={closeEdit}
      >
        <img
          src="/misc/icons/arrow_back_black@2x.png"
          alt="back-icon"
          className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} icon-back`}
        />
        <span className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} black align-middle back-text`}>
          {t('Globals.back').toUpperCase()}
        </span>
      </button>

      <div className="admin-edit-content">
        <div className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop pb-3'} admin-edit-text`}>
          <span className="title d-block mb-2">
            {t('AdminPanel.edit.title')} {user.firstname}
          </span>
        </div>
        <div className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} row m-0 admin-edit-form`}>
          {inputs.map((item, index) => (
            <div className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} col-12 p-0`} key={index}>
              <input
                name={item}
                value={state[item]}
                type={index > 7 ? 'password' : 'text'}
                placeholder={t(`RegisterForm.${item}`)}
                className={`${state.errors[item] && 'is-invalid'} 
              ${index <= 2 && state[item] && !constants.NAME_PATTERN.test(state[item]) && 'is-invalid'}
                form-control w-100`}
                onBlur={e => dispatch({ type: e.target.name, payload: e.target.value })}
                onChange={e => dispatch({ type: e.target.name, payload: e.target.value })}
                autoComplete="off"
              />
              {state.errors[item] ? (
                <span className="red">{t('Errors.empty')}</span>
              ) : (
                index <= 2 &&
                state[item] &&
                !constants.NAME_PATTERN.test(state[item]) && <span className="red">{format}</span>
              )}
              {(item === 'formEmail' || item === 'formConfEmail') &&
                (state.errors[item] ? (
                  <span className="red">{emptyError}</span>
                ) : state[item] && !constants.EMAIL_PATTERN.test(state[item]) ? (
                  <span className="red">{format}</span>
                ) : (
                  state.formEmail !== state.formConfEmail && <span className="red">{emailNotEqual}</span>
                ))}
            </div>
          ))}
        </div>
        <button
          onClick={e => handleSubmit(e)}
          disabled={isValid()}
          className={`${
            DisplayUtils.isMobile() ? 'mobile' : 'desktop ml-auto'
          } white bg-dark-b2b admin-edit-button btn-transparent align-items-center d-flex justify-content-center`}
        >
          <span>{t('AdminPanel.edit.save')}</span>
        </button>
      </div>
      {editErrorModal && (
        <ModalSimple
          title={t('EditClientErrorModal.title')}
          description={t('EditClientErrorModal.description')}
          mainBtnText={t('Globals.ok')}
          mainAction={hideEditErrorModal}
          closeModal={hideEditErrorModal}
        />
      )}
      {editOkModal && (
        <ModalRegister
          title={t('EditClientOkModal.title')}
          description={t('EditClientOkModal.description')}
          mainBtnText={t('EditClientOkModal.next')}
          mainAction={goToAddresses}
          secondaryBtnText={t('EditClientOkModal.skip')}
          secondaryAction={skipAddresses}
          closeModal={skipAddresses}
        />
      )}
    </div>
  );
};

export default EditUser;
