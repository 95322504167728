import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image , Font} from "@react-pdf/renderer";
import regular from "../../../utils/fonts/Roboto-Regular.ttf";
import bold from "../../../utils/fonts/Roboto-Bold.ttf";
import CartUtils from '../../../utils/CartUtils';
import constants from '../../../utils/constants';
import { useTranslation } from 'react-i18next';

Font.register({ 
    family: 'Roboto', fonts: [ { src: regular }, { src: bold, fontWeight: 'bold' } ]
});

const styles = StyleSheet.create({
  page: {
    fontFamily: "Roboto",
    backgroundColor: '#FFF',
    paddingTop: 35,
    paddingBottom: 98,
    paddingHorizontal: 35,
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 8,
    bottom: 0,
    left: 0,
    right: 35,
    top: 20,
    textAlign: 'right',
    color: 'grey',
  },
  thanks: {
    flexDirection: 'row',
    height: "80px",
    thanksContainer: {
      flexGrow: 2,
      lineHeight: 2.2,
      display: "flex",
      justifyContent: "center",
      alignSelf: "center",
      thanksUser: {
        fontSize: 16,
        fontWeight: "bold",
      },
      thanksInfo: {
        fontSize: 13,
      }
    },
    headerLoginLogo: {
      flexGrow: 1,
      display: "flex",
      alignItems: "end",
      img: {
        width: "150px",
        height: "80px",
        objectFit: "contain"
      }
    }
  },
  dataSummary: {
    flexDirection: 'row',
    marginTop: "24px",
    dataContainer: {
      borderColor: "black",
      borderWidth: 1,
      borderStyle: "solid",
      flexGrow: 1,
      height: "105px",
      padding: "12px 0px 12px 12px",
      title: {
        color: "black",
        fontSize: 10,
        fontWeight: "bold"
      },
      addressInfo: {
        marginTop: "12px",
        detailInfo: {
          fontSize: 9.5,
          lineHeight: 1.25,
        }
      },
      statusInfo: {
        marginTop: "12px",
        detailInfo: {
          fontSize: 10,
          lineHeight: 1.25,
        }
      }
    },
    dataContainerCenter: {
      borderColor: "black",
      borderWidth: 1,
      borderStyle: "solid",
      flexGrow: 0.5,
      height: "105px",
      padding: "12px 0px 12px 12px",
      marginRight: "8px",
      marginLeft: "8px",
      title: {
        color: "black",
        fontSize: 10,
        fontWeight: "bold"
      },
      summaryInfo: {
        marginTop: "12px",
        infoRow: {
          flexDirection: 'row',
          title: {
            fontSize: 9.5,
            lineHeight: 1.25,
            width: "52px",
            textAlign: "left",
            marginRight: "20px",
          },
          data: {
            flexGrow: 1,
            fontSize: 9.5,
            lineHeight: 1.25,
            bold: {
              fontWeight: "bold",
              flexGrow: 1,
              fontSize: 9.5,
              lineHeight: 1.25,
            },
            extraBold: {
              fontWeight: "bold",
              flexGrow: 1,
              fontSize: 11.5,
              lineHeight: 1.25,
            }
          }
        }
      },
        moreInfo: {
          position: 'absolute',
          fontSize: 7.2,
          bottom: 6,
          left: "12px",
          right: 0,
          color: '#a9a9ab',
        }
    },
  },
  detailsTitle: {
    color: "black",
    fontSize: 10,
    fontWeight: "bold",
    margin: "12px 12px"
  },
  orderTotals: {
    flexDirection: 'row',
    height: 80,
    borderBottomColor: "#e3e2e4",
    borderBottomWidth: 0.5,
    borderBottomStyle: "solid",
    borderTopColor: "black",
    borderTopWidth: 0.5,
    borderTopStyle: "solid",
    display: "flex",
    alignItems: "center",
    paddingLeft: 40,
    infoRowTotals: {
      flexDirection: 'row',
      color: "black",
      titleTotals: {
        fontSize: 9.5,
        lineHeight: 1.5,
        width: "55px",
        marginRight: "10px",
      },
      dataTotals: {
        fontSize: 9.5,
        lineHeight: 1.25,
        textAlign: "right",
        width: "30px",
      }
    },
    prices: {
      flexGrow: 1,
      infoRowTotals: {
        flexDirection: 'row',
        color: "black",
        fontWeight: "bold",
        display: "flex",
        justifyContent: "flex-end",
        titleTotals: {
          fontSize: 9.5,
          lineHeight: 3,
          width: "60px",
          textAlign: "right",
          total: {
            fontSize: 11,
            lineHeight: 1.5,
            width: "60px",
            textAlign: "right",
          }
        },
        dataTotals: {
          fontSize: 9.5,
          lineHeight: 1.25,
          textAlign: "right",
          width: "100px",
          total: {
            fontSize: 14,
            lineHeight: 1.25,
            textAlign: "right",
            width: "100px",
          }
        }
      },
    }
  },
  orderTotalsInfo: {
    fontSize: 7.2,
    color: '#a9a9ab',
    textAlign: "right",
    marginTop: 10,
  },
  footer: {
    height: 65,
    position: 'absolute',
    fontSize: 8,
    bottom: 0,
    left: 0,
    right: 0,
    paddingHorizontal: 35,
    contact: {
      flexDirection: 'row',
      fontSize: 8,
      color: 'black',
      fontWeight: "bold",
      display: "flex",
      alignItems: "end",
      justifyContent: "center",
      borderBottomColor: "#a9a9ab",
      borderBottomWidth: 1,
      borderBottomStyle: "solid",
      paddingTop: 10,
      paddingBottom: 10,
      img: {
        width: "40px",
        objectFit: "contain",
        marginRight: 10
      }
    },
    info: {
      fontSize: 7.2,
      color: '#a9a9ab',
      textAlign: "center",
      marginTop: 10
    }
  },
  detailsHeader: {
    height: "12px",
    flexDirection: 'row',
    fontSize: 9.5,
    marginTop: "12px",
    img: {
      width: "35px",
      objectFit: "contain",
      marginRight: 10
    },
    texts: {
      width: "240px",
    },
    prices: {
      flexDirection: 'row',
      pvpr: {
        color: "#a9a9ab",
        width: "60px",
        textAlign: "center",
      },
      quantity: {
        color: "black",
        width: "60px",
        textAlign: "center",
      },
      price: {
        color: "black",
        width: "60px",
        textAlign: "center",
      },
      priceTotal: {
        fontWeight: "bold",
        color: "black",
        width: "60px",
        textAlign: "center",
      },
    }
  },
  itemContainer: {
    flexDirection: 'row',
    borderTopColor: "#e3e2e4",
    borderTopWidth: 0.5,
    borderTopStyle: "solid",
    height: 40,
    texts: {
      display: "flex",
      justifyContent: "center",
      alignSelf: "center",
      width: "240px",
      title: {
        color: "black",
        fontWeight: "bold",
        fontSize: 9.5,
        maxLines: 1,
        textOverflow: "ellipsis"
      },
      description: {
        color: "#a9a9ab",
        fontSize: 9,
        maxLines: 1,
        textOverflow: "ellipsis"
      },
    },
    prices: {
      flexDirection: 'row',
      display: "flex",
      justifyContent: "center",
      alignSelf: "center",
      pvpr: {
        color: "#a9a9ab",
        fontSize: 9.5,
        width: "60px",
        textAlign: "center",
      },
      quantity: {
        color: "black",
        fontSize: 9.5,
        width: "60px",
        textAlign: "center",
      },
      price: {
        color: "black",
        fontSize: 9.5,
        width: "60px",
        textAlign: "center",
      },
      priceTotal: {
        fontWeight: "bold",
        color: "black",
        fontSize: 9.5,
        width: "60px",
        textAlign: "center",
      },
    },
    img: {
      width: "35px",
      height: "35px",
      objectFit: "contain",
      marginRight: 10
    }
  },
});

const getProductImage = (item) => {
  if(item.images && item.images[0]) {
    const newArr = item.images[0].split('/')
    if(newArr[newArr.length -1] === 'undefined.jpg' || newArr[newArr.length -1] === 'null.jpg' || newArr[newArr.length -1] === 'undefined.png' || newArr[newArr.length -1] === 'null.png'){
      return constants.NO_IMAGE + '?noCache=' + Math.random().toString()
    } else {
      return item.images[0] + '?noCache=' + Math.random().toString()
    }
  } else {
    return constants.NO_IMAGE + '?noCache=' + Math.random().toString()
  }
}

const InvoiceTableRow = ({item, index }) => {
  return (
    <View key={index} style={styles.itemContainer} break={index !== 0 && index % 16 === 0}>
        <Image style={styles.itemContainer.img} src={getProductImage(item)} source={{
          header: { 'Access-Control-Allow-Origin': '*' }
        }}/>
        <View style={styles.itemContainer.texts}>
          <Text style={styles.itemContainer.texts.title}>{item.name}</Text>
          <Text style={styles.itemContainer.texts.description}>{item.descriptionshort ? item.descriptionshort.replace(constants.REMOVE_HTML_PATTERN, '') : ""}</Text>
        </View>
        <View style={styles.itemContainer.prices}>
          <Text style={styles.itemContainer.prices.pvpr}>({CartUtils.formatPricePvpr(item.pricepvp.toFixed(2))})</Text> 
          <Text style={styles.itemContainer.prices.quantity}>{item.quantity}</Text>
          <Text style={styles.itemContainer.prices.price}>{CartUtils.formatPrice(item.priceOrder.toFixed(2))}</Text>
          <Text style={styles.itemContainer.prices.priceTotal}>{CartUtils.formatPrice((item.priceOrder * item.quantity).toFixed(2))}</Text>
        </View>
    </View>
  )
};

const PdfOrder = ({ order, getDate }) => {

  const { t } = useTranslation();

  const filterProducts =
  order.products &&
  order.products.map(product => {
    if (!product.sizes || !product.sizes.name) {
      return product;
    } else {
      return {
        ...product,
        id: product.sizes.id ? product.id + '.' + product.sizes.id : product.id,
        name: product.sizes.name ? product.name + ' ' + product.sizes.name : product.name,
        price: product.sizes.price ? product.sizes.price : product.price,
        pricepvp: product.sizes.pricepvp ? product.sizes.pricepvp : product.pricepvp,
        images: product.sizes.image ? [product.sizes.image] : product.images,
        promotion: product.sizes.promotion ? product.sizes.promotion : product.promotion,
        promotionType: product.sizes.promotionType ? product.sizes.promotionType : product.promotionType,
      };
    }
  });

  const firstPageItems = filterProducts.slice(0,11);
  const nextItems = filterProducts.slice(11);

  const totalUnits =
    filterProducts && filterProducts.length > 0
      ? filterProducts.map(product => product.quantity).reduce((a, b) => a + b)
      : 0;

  const getBreak = () => {
    return (filterProducts.length > 8 && filterProducts.length < 11) || (nextItems.length % 16 === 0)
  }

  const listBrands = filterProducts && filterProducts.length > 0 && filterProducts.map(product => product.nameManufacturer)
    
  const totalBrands = listBrands ? listBrands.filter((item,index) => listBrands.indexOf(item) === index) : []

    return (
        <Document>
            <Page size="A4" style={styles.page} >
                <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
                    `Página ${pageNumber} de ${totalPages}`
                )} fixed />
                <View style={styles.thanks}>
                    <View style={styles.thanks.thanksContainer}>
                        <Text style={styles.thanks.thanksContainer.thanksUser}>{`${order.address.firstname} ${order.address.lastname}`}</Text>
                        <Text style={styles.thanks.thanksContainer.thanksInfo}>{t('Account.orders.OrderDetailThanks').toUpperCase()}</Text>
                    </View>
                    <View style={styles.thanks.headerLoginLogo}>
                        <Image style={styles.thanks.headerLoginLogo.img} src='/misc/icons/logo-5.png' />
                    </View>
                </View>
                <View style={styles.dataSummary}>
                    <View style={styles.dataSummary.dataContainer}>
                        <Text style={styles.dataSummary.dataContainer.title}>{t('Account.orders.orderDetailAddress').toUpperCase()}</Text>
                        <View style={styles.dataSummary.dataContainer.addressInfo}>
                            <Text style={styles.dataSummary.dataContainer.addressInfo.detailInfo}>{order.address.alias}</Text>
                            <Text style={styles.dataSummary.dataContainer.addressInfo.detailInfo}>{order.address.address}</Text>
                            <Text style={styles.dataSummary.dataContainer.addressInfo.detailInfo}>{order.address.city} {order.address.postcode}</Text>
                            <Text style={styles.dataSummary.dataContainer.addressInfo.detailInfo}>{order.address.state} {order.address.country}</Text>
                        </View>
                    </View>
                    <View style={styles.dataSummary.dataContainerCenter}>
                        <Text style={styles.dataSummary.dataContainerCenter.title}>{t('Account.orders.orderDetailSummary').toUpperCase()}</Text>
                        <View style={styles.dataSummary.dataContainerCenter.summaryInfo}>
                            <View style={styles.dataSummary.dataContainerCenter.summaryInfo.infoRow}>
                                <Text style={styles.dataSummary.dataContainerCenter.summaryInfo.infoRow.title}>{t('Account.orders.orderDetailNumber')}</Text>
                                <Text style={styles.dataSummary.dataContainerCenter.summaryInfo.infoRow.data.extraBold}>{order.reference}</Text>
                            </View>
                            <View style={styles.dataSummary.dataContainerCenter.summaryInfo.infoRow}>
                                <Text style={styles.dataSummary.dataContainerCenter.summaryInfo.infoRow.title}>{t('Account.orders.orderDetailDate')}</Text>
                                <Text style={styles.dataSummary.dataContainerCenter.summaryInfo.infoRow.data}>{getDate(order.date)}</Text>
                            </View>
                            <View style={styles.dataSummary.dataContainerCenter.summaryInfo.infoRow}>
                                <Text style={styles.dataSummary.dataContainerCenter.summaryInfo.infoRow.title}>{t('Account.orders.orderDetailBruto')}</Text>
                                <Text style={styles.dataSummary.dataContainerCenter.summaryInfo.infoRow.data.bold}>{CartUtils.formatPrice(order.totalPaidTaxExcl.toFixed(2))}*</Text>
                            </View>
                        </View>
                        <Text style={styles.dataSummary.dataContainerCenter.moreInfo}>{t('Account.orders.orderDetailInfo1')}</Text>
                    </View>
                    <View style={styles.dataSummary.dataContainer}>
                        <Text style={styles.dataSummary.dataContainer.title}>{t('Account.orders.orderDetailStatus').toUpperCase()}</Text>
                        <View style={styles.dataSummary.dataContainer.statusInfo}>
                            <Text style={styles.dataSummary.dataContainer.statusInfo.detailInfo}>{order.state}</Text>
                        </View>
                    </View>
                </View>
                <Text style={styles.detailsTitle}>{t('Account.orders.orderDetailTitle').toUpperCase()}</Text>
                <View style={styles.detailsHeader}>
                    <View style={styles.detailsHeader.img} />
                    <Text style={styles.detailsHeader.texts}>{t('Account.orders.orderDetailDescription')}</Text>
                    <View style={styles.detailsHeader.prices}>
                      <Text style={styles.detailsHeader.prices.pvpr}>{t('Account.orders.orderDetailPvpr')}</Text>
                      <Text style={styles.detailsHeader.prices.quantity}>{t('Account.orders.orderDetailQuantity')}</Text>
                      <Text style={styles.detailsHeader.prices.price}>{t('Account.orders.orderDetailPrice')}</Text>
                      <Text style={styles.detailsHeader.prices.priceTotal}>{t('Account.orders.orderDetailPriceTotal')}</Text>
                    </View>
                </View>
                {firstPageItems.map((item,index) => (
                    <InvoiceTableRow item={item} key={index} />
                ))}
                {nextItems.map((item,index) => (
                    <InvoiceTableRow item={item} key={index} />
                ))}
                <View style={styles.orderTotals} break={getBreak()}>
                    <View>
                        <View style={styles.orderTotals.infoRowTotals}>
                            <Text style={styles.orderTotals.infoRowTotals.titleTotals}>{t('CartDetail.brands').toUpperCase()}</Text>
                            <Text style={styles.orderTotals.infoRowTotals.dataTotals}>{totalBrands.length}</Text>
                        </View>
                        <View style={styles.orderTotals.infoRowTotals}>
                            <Text style={styles.orderTotals.infoRowTotals.titleTotals}>{t('CartDetail.articles').toUpperCase()}</Text>
                            <Text style={styles.orderTotals.infoRowTotals.dataTotals}>{filterProducts.length}</Text>
                        </View>
                        <View style={styles.orderTotals.infoRowTotals}>
                            <Text style={styles.orderTotals.infoRowTotals.titleTotals}>{t('CartDetail.units').toUpperCase()}</Text>
                            <Text style={styles.orderTotals.infoRowTotals.dataTotals}>{totalUnits}</Text>
                        </View>
                    </View>
                    <View style={styles.orderTotals.prices}>
                        <View style={styles.orderTotals.prices.infoRowTotals}>
                            <Text style={styles.orderTotals.prices.infoRowTotals.titleTotals}>{t('Account.orders.orderDetailSubtotal').toUpperCase()}</Text>
                            <Text style={styles.orderTotals.prices.infoRowTotals.dataTotals}>{CartUtils.formatPrice(order.totalPaidTaxExcl.toFixed(2))}*</Text>
                        </View>
                        <View style={styles.orderTotals.prices.infoRowTotals}>
                            <Text style={styles.orderTotals.prices.infoRowTotals.titleTotals.total}>{t('Account.orders.orderDetailTotal').toUpperCase()}</Text>
                            <Text style={styles.orderTotals.prices.infoRowTotals.dataTotals.total}>{CartUtils.formatPrice(order.totalPaidTaxIncl.toFixed(2))}</Text>
                        </View>
                    </View>
                </View>
                <Text style={styles.orderTotalsInfo}>{t('Account.orders.orderDetailInfo1')}</Text>
                <View style={styles.footer}>
                    <View style={styles.footer.contact}>
                        <Image style={styles.footer.contact.img} src='/misc/icons/logo-dbs-black.png' />
                        <Text>{t('Account.orders.OrderDetailFooter')}</Text>
                    </View>
                    <View style={styles.footer.info}>
                        <Text>{t('Account.orders.orderDetailInfo2')}</Text>
                    </View>
                </View>
            </Page>
        </Document>
    );
};

export default PdfOrder;
