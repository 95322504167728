import React from 'react';
import { useTranslation } from 'react-i18next';
import DisplayUtils from '../../../../utils/DisplayUtils';
import './SideFilters.css';
import 'react-input-range/lib/css/index.css';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';

const SideFilters = ({
  categories,
  openSideFilters,
  enabled,
  handleChange,
  state
}) => {
  const { t } = useTranslation();

  const getTreeItemsFromData = treeItems => {
    return treeItems && treeItems.map(treeItemData => {
      let children = undefined;
      if (treeItemData.subcategories && treeItemData.subcategories.length > 0) {
        children = getTreeItemsFromData(treeItemData.subcategories);
      }
      
      return (
        <TreeItem
          key={treeItemData.id.toString()}
          nodeId={treeItemData.id.toString()}
          label={
            <div>
              {treeItemData.name}
            </div>
          }
          children={children}
        />
      );
    });
  };

  const DataTreeView = ({ treeItems }) => {
    return (
      <TreeView
        className="first-node white"
        expanded={state.expanded}
        onNodeSelect={handleChange}
        disableSelection={!enabled}
      >
        {getTreeItemsFromData(treeItems)}
      </TreeView>
    );
  };

  return (
    <div className="row side-filters-container m-0">
      <div className="categories-container w-100">
        {DisplayUtils.isMobile() && (
          <div className="mb-3">
            
            <img
              alt="close"
              src="/misc/icons/add_black.png"
              className="close-filters"
              onClick={() => openSideFilters(false)}
            />
          </div>
        )}
        <DataTreeView treeItems={categories} />
      </div>
      {DisplayUtils.isMobile() && (
        <div className="w-100 white d-flex justify-content-center mt-5">
          <button className="btn-transparent mobile bg-secondary-b2b btn-filters" onClick={() => openSideFilters(false)}>
            {t('Filters.apply')}
          </button>
        </div>
      )}
    </div>
  );
};

export default SideFilters;
