import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import DisplayUtils from '../../../utils/DisplayUtils';
import './homeSlider.css';

const HomeSlider = ({ images, shop, goToShop, home }) => {
  const settings = {
    dots: true,
    infinite: true,
    arrows: !DisplayUtils.isMobile(),
    autoplay: !!home,
    speed: 300,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipe: DisplayUtils.isMobile(),
    customPaging: i => <div className="custom-paging"></div>,
  };

  const getRoute = item => item.split('?')[0];
  const itemId = item => item.split('=')[1];

  return (
    <div className="home-slider-container slider-product">
      <Slider {...settings} className={`
      ${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} 
      ${home && 'bg-dbs-gray5 home-slider-home'} 
      home-slider`}>
        {images &&
          images.map((item, index) => (
            <div
              key={index}
              className={`${DisplayUtils.isMobile() ? 'd-flex justify-content-center' : ''} item-content`}
            >
              {item.mediaContent ? (
                <Link
                  to={{
                    pathname: getRoute(item.mediaContent),
                    state: { item: { id: itemId(item.mediaContent) } },
                  }}
                >
                  <img
                    src={item.mediaSource}
                    alt={index}
                    onClick={shop && (event => goToShop(event, itemId(item.mediaContent), getRoute(item.mediaContent)))}
                  />
                  {DisplayUtils.isMobile() && home && (
                    <div className="mt-3">
                      <span>{''}</span>
                    </div>
                  )}
                </Link>
              ) : (
                <Fragment>
                  <Link to="#">
                    <img src={item.mediaSource} alt={index} />
                    {DisplayUtils.isMobile() && home && (
                      <div className="mt-3">
                        <span>{''}</span>
                      </div>
                    )}
                  </Link>
                </Fragment>
              )}
            </div>
          ))}
      </Slider>
    </div>
  );
};

export default HomeSlider;
