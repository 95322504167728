import React, { useReducer, useState } from 'react';
import DisplayUtils from '../../../../utils/DisplayUtils';
import constants from '../../../../utils/constants';
import { Terms } from '../../../index';
import { useTranslation } from 'react-i18next';
import './FormRegisterPerson.css';

const initialState = {
  formDni: '',
  formName: '',
  formFirstSurname: '',
  formSecondSurname: '',
  formCommerce: '',
  formAddress: '',
  formTown: '',
  formPostal: '',
  formProvince: '',
  formPhone: '',
  formEmail: '',
  errors: {},
};

const reducer = (state, action) => {
  state.errors[action.type] = !action.payload;
  switch (action.type) {
    case 'formDni':
      return { ...state, formDni: action.payload };
    case 'formName':
      return { ...state, formName: action.payload };
    case 'formFirstSurname':
      return { ...state, formFirstSurname: action.payload };
    case 'formSecondSurname':
      return { ...state, formSecondSurname: action.payload };
    case 'formCommerce':
      return { ...state, formCommerce: action.payload };
    case 'formAddress':
      return { ...state, formAddress: action.payload };
    case 'formTown':
      return { ...state, formTown: action.payload };
    case 'formPostal':
      return { ...state, formPostal: action.payload };
    case 'formProvince':
      return { ...state, formProvince: action.payload };
    case 'formPhone':
      return { ...state, formPhone: action.payload };
    case 'formEmail':
      return { ...state, formEmail: action.payload };
    default:
      return initialState;
  }
};

const FormRegisterPerson = ({ showRegister, onSendClick }) => {
  const { t } = useTranslation();

  const [state, dispatch] = useReducer(reducer, initialState);
  const [termsChecked, setTermsChecked] = useState(false);
  const [showTerms, setShowTerms] = useState(false);
  const emptyError = t('Errors.empty');
  const format = t('Errors.notValid');

  const isValid = () => {
    const validateState = Object.keys(state)
        .filter(item => item !== 'formSecondSurname')
        .reduce((acc, item) => {
          acc[item] = state[item];
          return acc;
        }, {});
    return (
      Object.values(validateState).some(item => item === '') ||
      !constants.EMAIL_PATTERN.test(state.formEmail) ||
      !termsChecked
    );
  };
  const onShowTerms = () => setShowTerms(!showTerms);
  const acceptTerms = () => {
    setTermsChecked(true)
    setShowTerms(!showTerms);
  }

  const generateUser = () => {
    const newUser = {
      type: "person",
      dni: state.formDni,
      name: state.formName,
      firstSurname: state.formFirstSurname,
      secondSurname:state.formSecondSurname,
      company: state.formCommerce,
      address: state.formAddress,
      town: state.formTown,
      postalCode: state.formPostal,
      province: state.formProvince,
      phone: state.formPhone,
      email: state.formEmail
    }
    onSendClick(newUser)
  }

  return (
    <div className={`${DisplayUtils.isMobile() ? 'mobile p-3' : 'desktop p-4'} register-container`}>
      {!showTerms ? (
        <div className="register-content">
          <img
            alt="back-btn"
            className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} back-btn`}
            src="/misc/icons/arrow_back2_black.svg"
            onClick={() => showRegister(false)}
          />
          <div className='d-flex justify-content-center'>
            <img className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} login-logo`} src='/misc/icons/logo-4.svg' alt="logo" />
          </div>
          <div className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop pb-3'} register-text`}>
            <span className="title d-block mb-3">{t('RegisterForm.titlePerson')}</span>
            <span className="text d-block mb-3">{t('RegisterForm.descriptionPerson')}</span>
          </div>
          <div className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} row m-0 register-form`}>
            <div className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} col-12 p-0`}>
              <p className="placeholder-form dbs-gray2">{t('RegisterForm.person.dni').toUpperCase()}</p>
              <input
                name="formDni"
                value={state.formDni}
                type="text"
                className={`${state.errors.formDni && 'is-invalid'} form-control w-100`}
                onBlur={e => dispatch({ type: e.target.name, payload: e.target.value })}
                onChange={e => dispatch({ type: e.target.name, payload: e.target.value })}
              />
              {state.errors.formDni && <span className="red">{t('Errors.empty')}</span>}
            </div>
            <div className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} col-12 p-0`}>
              <p className="placeholder-form dbs-gray2">{t('RegisterForm.person.name').toUpperCase()}</p>
              <input
                name="formName"
                value={state.formName}
                type="text"
                className={`${state.errors.formName && 'is-invalid'} form-control w-100`}
                onBlur={e => dispatch({ type: e.target.name, payload: e.target.value })}
                onChange={e => dispatch({ type: e.target.name, payload: e.target.value })}
              />
              {state.errors.formName && <span className="red">{t('Errors.empty')}</span>}
            </div>
            <div className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} col-12 p-0`}>
              <p className="placeholder-form dbs-gray2">{t('RegisterForm.person.firstSurname').toUpperCase()}</p>
              <input
                name="formFirstSurname"
                value={state.formFirstSurname}
                type="text"
                className={`${state.errors.formFirstSurname && 'is-invalid'} form-control w-100`}
                onBlur={e => dispatch({ type: e.target.name, payload: e.target.value })}
                onChange={e => dispatch({ type: e.target.name, payload: e.target.value })}
              />
              {state.errors.formFirstSurname && <span className="red">{t('Errors.empty')}</span>}
            </div>
            <div className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} col-12 p-0`}>
              <p className="placeholder-form dbs-gray2">{t('RegisterForm.person.secondSurname').toUpperCase()}</p>
              <input
                name="formSecondSurname"
                value={state.formSecondSurname}
                type="text"
                className={`form-control w-100`}
                onBlur={e => dispatch({ type: e.target.name, payload: e.target.value })}
                onChange={e => dispatch({ type: e.target.name, payload: e.target.value })}
              />
            </div>
            <div className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} col-12 p-0`}>
              <p className="placeholder-form dbs-gray2">{t('RegisterForm.person.salonName').toUpperCase()}</p>
              <input
                name="formCommerce"
                value={state.formCommerce}
                type="text"
                className={`${state.errors.formCommerce && 'is-invalid'} form-control w-100`}
                onBlur={e => dispatch({ type: e.target.name, payload: e.target.value })}
                onChange={e => dispatch({ type: e.target.name, payload: e.target.value })}
              />
              {state.errors.formCommerce && <span className="red">{t('Errors.empty')}</span>}
            </div>
            <div className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} col-12 p-0`}>
              <p className="placeholder-form dbs-gray2">{t('RegisterForm.person.address').toUpperCase()}</p>
              <input
                name="formAddress"
                value={state.formAddress}
                type="text"
                className={`${state.errors.formAddress && 'is-invalid'} form-control w-100`}
                onBlur={e => dispatch({ type: e.target.name, payload: e.target.value })}
                onChange={e => dispatch({ type: e.target.name, payload: e.target.value })}
              />
              {state.errors.formAddress && <span className="red">{t('Errors.empty')}</span>}
            </div>
            <div className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} col-12 p-0`}>
              <p className="placeholder-form dbs-gray2">{t('RegisterForm.person.town').toUpperCase()}</p>
              <input
                name="formTown"
                value={state.formTown}
                type="text"
                className={`${state.errors.formTown && 'is-invalid'} form-control w-100`}
                onBlur={e => dispatch({ type: e.target.name, payload: e.target.value })}
                onChange={e => dispatch({ type: e.target.name, payload: e.target.value })}
              />
              {state.errors.formTown && <span className="red">{t('Errors.empty')}</span>}
            </div>
            <div className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} col-6 pl-0 pr-1`}>
              <p className="placeholder-form dbs-gray2">{t('RegisterForm.person.postal').toUpperCase()}</p>
              <input
                name="formPostal"
                value={state.formPostal}
                type="number"
                className={`${state.errors.formPostal && 'is-invalid'} form-control w-100`}
                onBlur={e => dispatch({ type: e.target.name, payload: e.target.value })}
                onChange={e => dispatch({ type: e.target.name, payload: e.target.value })}
              />
              {state.errors.formPostal && <span className="red">{t('Errors.empty')}</span>}
            </div>
            <div className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} col-6 pr-0 pl-1`}>
              <p className="placeholder-form dbs-gray2">{t('RegisterForm.person.province').toUpperCase()}</p>
              <input
                name="formProvince"
                value={state.formProvince}
                type="text"
                className={`${state.errors.formProvince && 'is-invalid'} form-control w-100`}
                onBlur={e => dispatch({ type: e.target.name, payload: e.target.value })}
                onChange={e => dispatch({ type: e.target.name, payload: e.target.value })}
              />
              {state.errors.formProvince && <span className="red">{t('Errors.empty')}</span>}
            </div>
            <div className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} col-12 p-0`}>
              <p className="placeholder-form dbs-gray2">{t('RegisterForm.person.phone').toUpperCase()}</p>
              <input
                name="formPhone"
                value={state.formPhone}
                type="number"
                className={`${state.errors.formPhone && 'is-invalid'} form-control w-100`}
                onBlur={e => dispatch({ type: e.target.name, payload: e.target.value })}
                onChange={e => dispatch({ type: e.target.name, payload: e.target.value })}
              />
              {state.errors.formPhone && <span className="red">{t('Errors.empty')}</span>}
            </div>
            <div className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} col-12 p-0`}>
              <p className="placeholder-form dbs-gray2">{t('RegisterForm.person.email').toUpperCase()}</p>
              <input
                name="formEmail"
                value={state.formEmail}
                type="text"
                className={`${state.errors.formEmail && 'is-invalid'} form-control w-100`}
                onBlur={e => dispatch({ type: e.target.name, payload: e.target.value })}
                onChange={e => dispatch({ type: e.target.name, payload: e.target.value })}
              />
              {state.errors.formEmail ? (
                <span className="red">{emptyError}</span>
              ) : (
                state.formEmail &&
                !constants.EMAIL_PATTERN.test(state.formEmail) && <span className="red">{format}</span>
              )}
            </div>
            <div className="w-100 d-flex justify-content-center mb-3">
              <div className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} mt-4 round-check`}>
                <label className="container d-flex align-items-center">
                  <input type="checkbox" checked={termsChecked} onChange={() => setTermsChecked(!termsChecked)} />
                  <span className="checkmark"></span>
                  <span className="text-box">
                    {t('RegisterForm.accept')}{' '}
                    <span className="btn-transparent terms-btn" onClick={onShowTerms}>
                      {t('RegisterForm.terms')}
                    </span>
                  </span>
                </label>
              </div>
            </div>
          </div>
          <button
            onClick={() => generateUser()}
            disabled={isValid()}
            className={`${
              DisplayUtils.isMobile() ? 'mobile' : 'desktop m-auto'
            } white bg-dbs-gray1 register-button align-items-center d-flex justify-content-center btn-transparent`}
          >
            <span>{t('RegisterForm.register')}</span>
          </button>
        </div>
      ) : (
        <Terms onShowTerms={onShowTerms} acceptTerms={acceptTerms} />
      )}
    </div>
  );
};

export default FormRegisterPerson;
