import React from 'react';
import DisplayUtils from '../../../../utils/DisplayUtils';
import CartUtils from '../../../../utils/CartUtils';
import { useTranslation } from 'react-i18next';
import constants from '../../../../utils/constants';
import './OrderProductItem.css';

const OrderProductItem = ({ product }) => {
  const { t } = useTranslation();

  return DisplayUtils.isMobile() ? (
    <div className="row order-product-item">
      <div className="col-1 img-item p-0">
        <img src={DisplayUtils.getProductImage(product, constants.NO_IMAGE)} alt="img" onError={(e)=>{e.target.onerror = null; e.target.src=`${constants.NO_IMAGE}`}} />
      </div>
      <div className="mobile col-11 align-self-center">
        <span className="mobile product-name">{product.name}</span>
        <div className="d-flex justify-content-between align-items-center my-1">
          <span className="mobile product-price">{CartUtils.formatPrice(product.priceOrder.toFixed(2))}</span>
          <div className="d-flex align-items-center">
            <span className="mobile product-count">{t('Products.quantity')}</span>
            <span className="mobile product-quantity">{product.quantity}</span>
          </div>
        </div>
        <span className="mobile product-price-pvpr d-block">({t('Products.pricePvp')} {CartUtils.formatPricePvpr(product.originalPvp.toFixed(2))})</span>
      </div>
    </div>
  ) : (
    <div>
      <div className="row order-product-item">
        <div className="desktop justify-content-between d-flex align-items-center col-12 p-0">
          <div className="col-1 img-item p-0">
            <img src={DisplayUtils.getProductImage(product, constants.NO_IMAGE)} alt="img-product" onError={(e)=>{e.target.onerror = null; e.target.src=`${constants.NO_IMAGE}`}}/>
          </div>
          <div className="col-5 p-0">
            <span className="desktop product-name">{product.name}</span>
          </div>
          <div className="col-2 p-0 d-flex justify-content-center">
            <span className="desktop product-price-pvpr">({CartUtils.formatPricePvpr(product.originalPvp.toFixed(2))})</span>
          </div>
          <div className="col-2 p-0 d-flex justify-content-center">
            <span className="desktop product-price">{CartUtils.formatPrice(product.priceOrder.toFixed(2))}</span>
          </div>
          <div className="col-2 p-0 d-flex justify-content-center">
            <span className="desktop product-quantity">{product.quantity}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderProductItem;
