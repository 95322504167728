import React, {useRef} from 'react'
import DisplayUtils from '../../../../utils/DisplayUtils';
import constants from '../../../../utils/constants';
import { useTranslation } from 'react-i18next';

const FormLogin = ({ state, dispatch, errorResponse,handleSubmit,showRecoverPassModal, isValid, onRegisterClick }) => {
  const { t } = useTranslation();
  const errorEmail = useRef(null);

    function emailValidate(e) {
        if (state.email && !constants.EMAIL_PATTERN.test(state.email)) {
            e.target.classList.add('is-invalid');
            e.target.classList.remove('is-valid');
            errorEmail.current.classList.remove('login-email-hidden');
        } else if (state.email && constants.EMAIL_PATTERN.test(state.email)) {
            e.target.classList.add('is-valid');
            e.target.classList.remove('is-invalid');
            errorEmail.current.classList.add('login-email-hidden');
        } else {
            e.target.classList.remove('is-valid');
            e.target.classList.remove('is-invalid');
            errorEmail.current.classList.add('login-email-hidden');
        }

        dispatch({ type: e.target.name, payload: e.target.value });
    }

    return (
    <div className="row m-0 form-row d-flex justify-content-center">
      <div className="col-12 p-0">
        <span className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} d-block title`}>{t('Login.title')}</span>
        <div className='d-flex justify-content-center'>
          <img className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} login-logo`} src='/misc/icons/logo-4.svg' alt="logo" />
        </div>
      </div>
      <div className={`${DisplayUtils.isMobile() ? 'my-4' : 'mt-5 mb-4'} row m-0 w-100 inputs-container`}>
        <div className={`${DisplayUtils.isMobile() ? '' : 'pr-2'} col-12 p-0 usename-input`}>
          <p className="placeholder-form dbs-gray2">{t('Login.email')}</p>
          <input
            name="email"
            value={state.email}
            type="text"
            className={`form-control w-100`}
            onBlur={e => emailValidate(e)}
            onChange={e => {
                dispatch({ type: e.target.name, payload: e.target.value });
            }}
          />
        </div>
          <span className={`red mb-2 error ${state.errors.email ? '' : 'login-hidden'}`} >{t('Errors.userRequired')}</span>
          <span ref={errorEmail} className="red mb-2 error login-email-hidden">{t('Errors.notValid')}</span>
        <div className={`${DisplayUtils.isMobile() ? '' : 'pr-2'} mt-2 col-12 p-0 password-input`}>
          <p className="placeholder-form dbs-gray2">{t('Login.password')}</p>
          <input
            name="password"
            value={state.password}
            type="password"
            className={`${state.errors.password && 'is-invalid'} ${state.password.length > 5 &&
              'is-valid'} form-control w-100`}
            onBlur={e => dispatch({ type: e.target.name, payload: e.target.value })}
            onChange={e => dispatch({ type: e.target.name, payload: e.target.value })}
          />
        </div>
        {state.errors.password && <span className="red error">{t('Errors.passRequired')}</span>}
        {errorResponse && <span className="red error">{errorResponse}</span>}
      </div>
      <div className="w-100 mb-4 d-flex justify-content-center">
        <div className={`${DisplayUtils.isMobile() ? '' : ''} recover`} onClick={() => showRecoverPassModal()}>
          <span>{t('Login.recover')}</span>
        </div>
      </div>
      <div
        className={`${
          DisplayUtils.isMobile() ? 'd-flex justify-content-center mobile' : ' d-flex justify-content-center desktop'
        } row m-0 w-100`}
      >
        <button onClick={e => handleSubmit(e)} className="btn btn-login btn-block" disabled={isValid()}>
          {t('Login.login')}
        </button>
      </div>
      <div className="col-12 p-0 mt-5 box-register">
        <span className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} d-block title mt-4 w-100`}>
          {t('Login.title2')}
        </span>
        <span className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} d-block subtitle my-2 px-3 w-100`}>
          {t('Login.subtitle2')}
        </span>
      </div>
      <div className="buttons-container row m-0 w-100 justify-content-between">
        <div className={`${DisplayUtils.isMobile() ? 'd-flex justify-content-center my-4 mobile' : 'mt-4 mb-3 d-flex justify-content-center desktop'} col-6 pl-0 pr-3`}>
          <button className="btn btn-register btn-block" onClick={() => onRegisterClick("person")}>
            {t('Login.registerPerson')}
          </button>
        </div>
        <div className={`${DisplayUtils.isMobile() ? 'd-flex justify-content-center my-4 mobile' : 'mt-4 mb-3 d-flex justify-content-center desktop'} col-6 pr-0 pl-3`}>
          <button className="btn btn-register btn-block" onClick={() => onRegisterClick("company")}>
            {t('Login.registerCompany')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default FormLogin;
