import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import DisplayUtils from '../../../utils/DisplayUtils';
import './Footer.css';

const Footer = () => {
  const { t } = useTranslation();

  const footerWeb = () => {
    return (
      <div className="footer-container bg-dark-b2b">
        <div className="row p-0 m-0 h-100 w-100 d-flex footer-info">
          <div className='col-6 pl-5 p-0 align-items-center d-flex'>
            <img className="footer-logo" src="/misc/icons/Logo_DBS.svg" alt="logo" />
            <span className='ml-3'>
              {t('Footer.rights')}
            </span>
          </div>
          <div className='col-6 pr-5 p-0 justify-content-end d-flex align-items-center links-info'>
            <Link to="/cookies" className='desktop'>
              <span className=''>
                {t('Cookies.head')}
              </span>
            </Link>
            <div className="separator" />
            <Link to="/privacy" className='desktop'>
              <span className=''>
                {t('Privacy.head')}
              </span>
            </Link>
            <div className="separator" />
            <Link to="/terms-conditions" className='desktop'>
              <span className=''>
                {t('TermsConditions.head')}
              </span>
            </Link>
          </div>
        </div>
      </div>
    )
  }

  const footerMobile = () => {
    return (
      <div className="footer-container mobile bg-dark-b2b">
        <div className="row p-0 m-0 h-100 w-100 d-flex align-items-end footer-content-mobile">
          <div className='col-1 pl-3 p-0'>
              <img className="footer-logo" src="/misc/icons/Logo_DBS.svg" alt="logo" />
          </div>
          <div className='col-11 flex-column pr-3 p-0 d-flex footer-info'>
            <span className='d-flex justify-content-end'>
              {t('Footer.rights')}
            </span>
            <Link to="/cookies" className='mobile'>
              <span className='mt-1 d-flex justify-content-end'>
                {t('Cookies.head')}
              </span>
            </Link>
            <Link to="/privacy" className='mobile'>
              <span className='mt-1 d-flex justify-content-end'>
                {t('Privacy.head')}
              </span>
            </Link>
            <Link to="/terms-conditions" className='mobile'>
              <span className='mt-1 d-flex justify-content-end'>
                {t('TermsConditions.head')}
              </span>
            </Link>
          </div>
        </div>
      </div>
    )
  }

  return (
    DisplayUtils.isMobile() ? footerMobile() : footerWeb()
  );
};

export default Footer;
