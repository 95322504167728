import React from "react";
import { ProductItem } from "../../../index";
import { useTranslation } from 'react-i18next';

const ListProducts = ({ renderProducts, layout }) => {

    const { t } = useTranslation();

    return (
        <div className="row m-0">
            {layout === 'list' && (
                <div className="row w-100 m-0 mb-1">
                    <div className="col-10 p-0">
                        <div className="row w-100 m-0">
                            <div className="col-2 p-0"/>
                            <div className="col-10 d-flex align-items-center justify-content-end p-0">
                                <div className="product-info-header p-0"/>
                                <div className="prices-container d-flex justify-content-between">
                                    <div className='desktop d-flex justify-content-center product-price-header pvpr'>{t('Products.pricePvp')}</div>
                                    <div className='desktop d-flex justify-content-center product-price-header'>{t('Products.priceHeader')}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {renderProducts && renderProducts.map((item, index) => (
                <ProductItem key={index} item={item} layout={layout} />
            ))}
        </div >
    );
};


export default ListProducts;