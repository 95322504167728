import React, { useState, useEffect, Fragment, useCallback, useContext, useReducer, useRef, useLayoutEffect } from 'react';
import './Products.css';
import {
  Pagination,
  SideFilters,
  TopFilters,
  ListProducts,
  Loading,
  ModalError,
  Breadcrumbs,
  HomeSlider,
} from '../../index';
import { withRouter } from 'react-router-dom';
import DisplayUtils from '../../../utils/DisplayUtils';
import { productsService } from '../../../services/index';
import constants from '../../../utils/constants';
import { useLocalStorage } from '../../../hooks/useLocalStorage';
import { useTranslation } from 'react-i18next';
import EmptyBox from '../../shared/widget/emptyBox/EmptyBox';
import { AuthContext } from '../../../contexts/AuthContext';

const initialState = {
  expanded: [],
  preCat: [],
  actuallyCat: [],
  subCategories: [],
  secondNode: [],
  initCategories: [],
  currentPage: 1,
  breadcrumb: [],
};

const Products = ({ location, updateImage, image }) => {
  const { t } = useTranslation();

  const [currentPage, setCurrentPage] = useState(1);
  const [layout, setLayout] = useState('grid');
  const [mobileFilters, setMobileFilters] = useState(false);
  const [loading, setLoading] = useState(true);
  const [productsLoading, setProductsLoading] = useState(true);
  const [listProducts, setListProducts] = useState([]);
  const [totalProducts, setTotalProducts] = useState(0);
  const [actuallyId, setActuallyId] = useState(null);
  const [initialCat, setInitialCat] = useState(true);
  const [showError, setShowError] = useState(false);
  const [fromHome, setFromHome] = useState(location.state);
  const [someId, setSomeId] = useState(false);
  const [listCategories, setListCategories] = useState(null);
  const [shopCat, setShopCat] = useState(null);
  const { currentSearchWord, setCurrentSearchWord, selectedWord, setSelectedWord } = useContext(AuthContext);
  const productPerPage = 48;
  const [headerShow, setHeaderShow] = useState(false)
  const [heightImg, setHeightImg] = useState(0)
  const scrollRef = useRef();
  const headRef = useRef(null)
  scrollRef.current = headerShow;

  const [filters, setFilters] = useLocalStorage(constants.CURRENT_FILTERS_KEY, null);
  const getProducts = options => {
    setCurrentPage(options.offset);
    filters.currentPage = options.offset;
    setFilters(filters);
    setFromHome(null);
  };

  useLayoutEffect(()=>{
    if (headRef.current) {
      let heightTop = 44;

      if (window.innerWidth <= 800) {
        heightTop = 29;
      }

      setHeightImg(headRef.current.clientHeight - heightTop)
       
    }
}, [headRef.current, headRef.current ? headRef.current.clientHeight : 0]) // eslint-disable-line

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > (heightImg - 80)) {
        setHeaderShow(true);
      } else {
        setHeaderShow(false);
      }
    };
   
    document.addEventListener('scroll', handleScroll);

    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  });

  const onSetFilters = useCallback(
    item => {
       setFilters(item);
    },
    [setFilters]
  );
  const reducer = (state, action) => {
    state.currentPage = currentPage;
    state[action.type] = action.payload;
    onSetFilters(state);
    return state;
  };
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (filters) {
      dispatch({ type: 'expanded', payload: filters.expanded });
      dispatch({ type: 'subCategories', payload: filters.subCategories });
      dispatch({ type: 'preCat', payload: filters.preCat });
      dispatch({ type: 'actuallyCat', payload: filters.actuallyCat });
      dispatch({ type: 'secondNode', payload: filters.secondNode });
      dispatch({ type: 'initCategories', payload: filters.initCategories });
      dispatch({ type: 'currentPage', payload: filters.currentPage });
      dispatch({ type: 'breadcrumb', payload: filters.breadcrumb });
    } else {
      onSetFilters(initialState);
    }
  }, [filters, onSetFilters]);

  useEffect(() => {
    const getCategories = async () => {
      try {
        const response = await productsService.getCategories();
        if (response) {
          setListCategories(response[0].subcategories[0].subcategories);
          setLoading(false);
          setShopCat(response[0].subcategories[0]);
        }
      } catch (error) {
        setLoading(false);
        showErrorModal();
      }
    };

    getCategories();
  }, []);

  useEffect(() => {
    let unmounted = false;

    const getProducts = async (id, offset, word) => {
      const options = {
        id: selectedWord === 1 ? null : id,
        offset,
        word,
        selected: selectedWord,
      };
      setProductsLoading(true);
      try {
        const response = await productsService.getProducts(options);
        if (response) {
          setListProducts(response.productsInfo);
          setTotalProducts(response.productsInfo.length);
          setProductsLoading(false);
        }
      } catch (error) {
        setProductsLoading(false);
        showErrorModal();
      }
    };


    if (!unmounted) {
      if (fromHome && fromHome.item) {
        setCurrentSearchWord(null);
        setSelectedWord(null);
        !actuallyId && actuallyId !== fromHome.item.id && getProducts(fromHome.item.id);
        setActuallyId(fromHome.item.id);

        initialState.expanded = [];
        initialState.breadcrumb = [];
        initialState.preCat = [];
        initialState.actuallyCat = [];
        initialState.subCategories = [];
        initialState.secondNode = [];
        initialState.initCategories = [];
        initialState.currentPage = 1;

        onSetFilters(initialState);
      } else {
        if (filters) {
          if (currentSearchWord) {
            getProducts(filters.expanded[0], filters.currentPage, currentSearchWord);
          } else if (!initialCat) {
            getProducts(filters.expanded[0]);
          } 
          const filterCategory = filters.actuallyCat.filter(item => item.id === Number(filters.expanded[0]));
          filterCategory.length > 0 ? updateImage(filterCategory) : shopCat && updateImage(shopCat);
          setCurrentPage(filters.currentPage);
          setActuallyId(filters.expanded[0]);
          setLayout(filters.layout || 'grid');
          setInitialCat(false);
        } else {
          setCurrentPage(1);
          onSetFilters(initialState);
        }
      }
    }
    return () => {
      unmounted = true;
    };
  }, [selectedWord, currentSearchWord, actuallyId, filters, initialCat, fromHome, shopCat, updateImage, setCurrentSearchWord, setSelectedWord ]); // eslint-disable-line
 
  const goToShop = (event, category, route) => {
    if (route === '/shop' && !category) {
      event && event.preventDefault();
      event && event.stopPropagation();

      setActuallyId(category);
      setCurrentPage(1);
      filters.currentPage = 1;
      filters.breadcrumb = [];
      filters.expanded = [];
      filters.preCat = [];
      filters.actuallyCat = [];
      filters.subCategories = [];
      filters.secondNode = [];
      filters.initCategories = [];
      onSetFilters(filters);
    } else if (route === '/shop' && category) {
      const nodes = loadNode(category.toString());
      updateListProducts(nodes);
    }
  };

  const loadNode = nodes => {
    let categoriesInit = [];
    let filterSub = [];

    filterCategories.map(sub => categoriesInit.push(sub.id));
    dispatch({ type: 'initCategories', payload: categoriesInit });

    const isSameCategory = state.initCategories.filter(n => n.toString() === nodes);
    // -------- Init tree -------- //

    if (state.expanded.length === 0 || isSameCategory.length === 1) {
      const filter = filterCategories.filter(n => n.id.toString() === nodes);

      if (filter[0] && filter[0].subcategories) {
        dispatch({ type: 'subCategories', payload: filter[0].subcategories });
        dispatch({ type: 'secondNode', payload: filter[0].subcategories });

        filter[0].subcategories.map(sub => filterSub.push(sub.id.toString()));
      } else {
        dispatch({ type: 'subCategories', payload: [] });
        dispatch({ type: 'secondNode', payload: [] });
      }
      dispatch({ type: 'breadcrumb', payload: [] });
      dispatch({ type: 'preCat', payload: [] });
      dispatch({ type: 'actuallyCat', payload: filter });
      dispatch({ type: 'expanded', payload: [nodes] });
      dispatch({ type: 'breadcrumb', payload: [{ name: filter[0].name, id: nodes }] });

      // -------- When the move is the same category -------- //
    } else if (state.actuallyCat.find(n => n.id.toString() === nodes)) {
      const filter = state.actuallyCat.filter(n => n.id.toString() === nodes);
      if (filter.length > 0 && filter[0].subcategories) {
        dispatch({ type: 'subCategories', payload: filter[0].subcategories });
        state.expanded[0] = nodes;
        state.breadcrumb[state.breadcrumb.length - 1].name = filter[0].name;
        state.breadcrumb[state.breadcrumb.length - 1].id = nodes;
      } else {
        dispatch({ type: 'subCategories', payload: [] });
        state.expanded[0] = nodes;
        state.breadcrumb[state.breadcrumb.length - 1].name = filter[0].name;
        state.breadcrumb[state.breadcrumb.length - 1].id = nodes;
      }

      // -------- When the move is forward or backward -------- //
    } else {
      const isMoveBackwardOneNode = state.preCat.length > 0 && state.preCat.find(n => n.id.toString() === nodes);
      // -------- When the move is backward -------- //
      // -------- When the move is backward one node -------- //
      if (isMoveBackwardOneNode) {
        state.expanded.splice(0, 1);
        state.expanded[0] = nodes;

        const filter = state.preCat.filter(n => n.id.toString() === nodes);

        state.breadcrumb.splice(state.breadcrumb.length - 1, 1);
        state.breadcrumb[state.breadcrumb.length - 1].name = filter[0].name;
        state.breadcrumb[state.breadcrumb.length - 1].id = nodes;

        filter && filter[0].subcategories
          ? dispatch({ type: 'subCategories', payload: filter[0].subcategories })
          : dispatch({ type: 'subCategories', payload: [] });

        dispatch({ type: 'actuallyCat', payload: state.preCat });

        const filterSecond = state.secondNode.filter(n => n.id.toString() === nodes);
        filterSecond[0] && filter[0]
          ? dispatch({ type: 'preCat', payload: [] })
          : dispatch({ type: 'preCat', payload: state.secondNode });

        // -------- When the move is backward two nodes-------- //
      } else if (state.subCategories.length === 0) {
        state.expanded.splice(0, 2);
        state.expanded[0] = nodes;

        const filter = state.secondNode.filter(n => n.id.toString() === nodes);
        state.breadcrumb.splice(state.breadcrumb.length - 2, 2);
        state.breadcrumb[state.breadcrumb.length - 1].name = filter[0].name;
        state.breadcrumb[state.breadcrumb.length - 1].id = nodes;

        filter && filter[0].subcategories
          ? dispatch({ type: 'subCategories', payload: filter[0].subcategories })
          : dispatch({ type: 'subCategories', payload: [] });

        dispatch({ type: 'preCat', payload: [] });
        dispatch({ type: 'actuallyCat', payload: state.secondNode });
        // -------- When the move is forward -------- //
      } else {
        const filterSub = state.subCategories.filter(n => n.id.toString() === nodes);

        if (filterSub[0] && filterSub[0].subcategories) {
          if (state.expanded.length >= 1) {
            state.expanded.unshift(nodes);
            state.breadcrumb.push({ name: filterSub[0].name, id: nodes });
          }

          dispatch({ type: 'preCat', payload: state.actuallyCat });

          dispatch({ type: 'actuallyCat', payload: state.subCategories });

          dispatch({
            type: 'subCategories',
            payload: filterSub[0].subcategories,
          });

          filterSub[0].subcategories.map(sub => filterSub.push(sub.id.toString()));
        } else {
          state.expanded.unshift(nodes);
          state.breadcrumb.push({ name: filterSub[0].name, id: nodes });

          dispatch({ type: 'preCat', payload: state.actuallyCat });
          dispatch({ type: 'actuallyCat', payload: state.subCategories });
          dispatch({ type: 'subCategories', payload: [] });
        }
      }
    }

    return nodes;
  }

  const handleChange = async (event, data) => {
    if (data === 'shop') {
      dispatch({ type: 'breadcrumb', payload: [] });
      dispatch({ type: 'preCat', payload: [] });
      dispatch({ type: 'actuallyCat', payload: [] });
      dispatch({ type: 'expanded', payload: [] });
      dispatch({ type: 'initCategories', payload: [] });
      dispatch({ type: 'breadcrumb', payload: [] });
      dispatch({ type: 'subCategories', payload: [] });
      dispatch({ type: 'secondNode', payload: [] });
      updateListProducts(null);
    } else {
      const nodes = loadNode(data.toString());
      updateListProducts(nodes);
    }
  };

  const showErrorModal = async () => {
    await setShowError(true);
    window.$('#errorModal').modal('show');
  };
  const hideErrorModal = () => {
    setShowError(false);
    window.$('#errorModal').modal('hide');
    window.$('.modal-backdrop').remove();
    window.$('body').removeClass('modal-open');
  };

  const selectLayoutType = type => {
    setLayout(type);
    filters.layout = type;
    onSetFilters(filters);
  };

  const showSideFilters = show => {
    setMobileFilters(show);
  };
  const updateListProducts = category => {
    fromHome && setFromHome(null);
    currentSearchWord && setCurrentSearchWord(null);
    selectedWord && setSelectedWord(null);
    const filterCategory = filters && filters.actuallyCat.filter(item => item.id === Number(category));
    filterCategory && updateImage(filterCategory);
    if (category !== actuallyId) {
      setActuallyId(category);
      setCurrentPage(1);
      filters.currentPage = 1;
      onSetFilters(filters);
    } else {
      setSomeId(true);
      setActuallyId(category);
    }
  };

  const breadcrumb = JSON.parse(localStorage.getItem(constants.CURRENT_FILTERS_KEY));

  const filterCategories =
    listCategories &&
    listCategories.filter(item => item.id !== Number(process.env.REACT_APP_PRESTASHOP_PROMOTIONS_CAT_ID));

  const filterProducts =
    listProducts &&
    listProducts.map(product => {
      if (!product.sizes || !product.sizes.name) {
        return product;
      } else {
        return {
          ...product,
          name: product.sizes.name ? product.name + ' ' + product.sizes.name : product.name,
          price: product.sizes.price ? product.sizes.price : product.price,
          pricepvp: product.sizes.pricepvp ? product.sizes.pricepvp : product.pricepvp,
          images: product.sizes.image ? [product.sizes.image] : product.images,
          promotion: product.sizes.promotion ? product.sizes.promotion : product.promotion,
          promotionType: product.sizes.promotionType ? product.sizes.promotionType : product.promotionType,
        };
      }
    });

  const indexOfLastProduct = currentPage * productPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productPerPage;

  const paginate = pageNumber => {
    switch (pageNumber) {
      case 'right':
        setCurrentPage(currentPage + 1);
        filters.currentPage = currentPage + 1;
        break;
      case 'left':
        setCurrentPage(currentPage - 1);
        filters.currentPage = currentPage - 1;
        break;
      default:
        setCurrentPage(pageNumber);
        filters.currentPage = pageNumber;
        break;
    }
    const list = document.getElementById('list-products');
    list && list.scrollIntoView({ behavior: 'smooth' });

    onSetFilters(filters);
  };

  const renderProducts = filterProducts && filterProducts.slice(indexOfFirstProduct, indexOfLastProduct);
  return loading && !heightImg ? (
    <div className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} box-loading`}>
      <Loading white />
    </div>
  ) : (
    <div>

      {!shopCat || !shopCat.medias || shopCat.medias.length < 1 ? (

        <img
          ref={headRef}
          alt="head-img"
          className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} ${mobileFilters ? 'head-image-shop-filters': ''} head-image-shop`}
          src="/misc/img/shop-image.png"
        />
      ) : (
        <div ref={headRef} className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} ${mobileFilters ? 'head-image-shop-filters': ''} head-image-shop`}>
          <HomeSlider images={image ? image : shopCat.medias} shop goToShop={goToShop} />
        </div>
      )}
      {DisplayUtils.isMobile() && mobileFilters ? (
        <div className="filters-container-mobile bg-dbs-gray5" style={{ paddingTop: `${(heightImg + 6)}px`}}>
          <SideFilters
            categories={filterCategories}
            openSideFilters={showSideFilters}
            enabled={!productsLoading}
            someId={someId}
            handleChange={handleChange}
            state={filters || initialState}
          />
        </div>
      ) : (
        heightImg &&
        <div className="products-container row h-100 bg-dbs-gray" style={{ paddingTop: DisplayUtils.isMobile() ? `${(heightImg + 6)}px` : `${heightImg}px` }} >
          {!DisplayUtils.isMobile() && (
            <div className={`${headerShow ? "scroll-top-menu animated fadeInDown" : 
                "scroll-normal-menu"} col-3 filters-container-desktop bg-dbs-gray5`}>
              <SideFilters
                categories={filterCategories}
                enabled={!productsLoading}
                someId={someId}
                handleChange={handleChange}
                state={filters || initialState}
              />
            </div>
          )}
          <div
           
            className={`${
              DisplayUtils.isMobile() ? 'col-12 pt-3 mobile ' : `col-9 desktop ${headerShow ? "scroll-top-products" : "scroll-normal-products"}`
            }  shop-container bg-dbs-white`}
          >
            {productsLoading ? (
              <div className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} box-loading-products`}>
                <Loading white />
              </div>
            ) : (
              <Fragment>
                {showError && <ModalError hideErrorModal={hideErrorModal} />}
                <div className={`${DisplayUtils.isMobile() ? 'mb-3' : 'mb-5'} d-block pl-1`} id="list-products">
                  {DisplayUtils.isMobile() ? (
                    <Fragment>
                      <div className="my-3">
                        <Breadcrumbs handleChange={handleChange} breadcrumb={breadcrumb && breadcrumb.breadcrumb} />
                      </div>
                      <TopFilters
                        selectLayoutType={selectLayoutType}
                        layoutType={layout}
                        openSideFilters={showSideFilters}
                        getProducts={getProducts}
                        actuallyId={actuallyId}
                        promotions={false}
                        goToShop={goToShop}
                      />
                    </Fragment>
                  ) : (
                    <Fragment>
                      <TopFilters
                        selectLayoutType={selectLayoutType}
                        layoutType={layout}
                        openSideFilters={showSideFilters}
                        getProducts={getProducts}
                        actuallyId={actuallyId}
                        promotions={false}
                        goToShop={goToShop}
                      />
                      <div className="mt-5">
                        <Breadcrumbs handleChange={handleChange} breadcrumb={breadcrumb && breadcrumb.breadcrumb} />
                      </div>
                    </Fragment>
                  )}
                </div>
                {renderProducts && renderProducts.length > 0 ? (
                  <Fragment>
                    <ListProducts renderProducts={renderProducts} layout={layout} />

                    <div className="row m-0 justify-content-center align-items-center">
                      <Pagination
                        ItemsPerPage={productPerPage}
                        totalItems={totalProducts}
                        paginate={paginate}
                        currentPage={currentPage}
                      />
                    </div>
                    <div className="pvpr-info">
                      <div className="col-12">
                        {t('Products.pvpInfo')}
                      </div>
                    </div>
                  </Fragment>
                ) : (
                  <EmptyBox inputSearch={currentSearchWord} />
                )}
              </Fragment>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default withRouter(Products);
