import React, { useState, useEffect, Fragment, useCallback } from 'react';
import { Link } from 'react-router-dom';
import DisplayUtils from '../../../utils/DisplayUtils';
import './Breakings.css';

const Breakings = ({ news, setShowBreakings, hiddenNews }) => {
  const [newShow, setNewShow] = useState(null);
  const [show, setShow] = useState(news[0]);
  const [intervalID, setIntervalId] = useState(1);

  const onChange = useCallback(
    item => {
      if (newShow) {
        newShow.splice(0, 1);
        newShow.push(item);
        setNewShow(newShow);
        setShow(newShow[0]);
      }
    },
    [newShow]
  );

  const handleChangeBreaking = (event, item) => {
    const { name } = event.currentTarget;
    switch (name) {
      case 'pause':
        setIntervalId(null);
        break;
      case 'next':
        setIntervalId(1);
        onChange(item);
        break;
      case 'close':
        setShowBreakings(false);
        break;
      default:
    }
  };

  useEffect(() => {
    if (!newShow) {
      setNewShow(news);
    } else {
      setNewShow(newShow);
      setShow(newShow[0]);
    }

    const id = setInterval(() => {
      if (intervalID) {
        show && onChange(show);
      }
    }, 4000);

    return () => {
      clearInterval(id);
    };
  }, [newShow, news, intervalID, show, onChange]);

  const getRoute = item => item.split('?')[0];
  const itemId = item => item.split('=')[1];
  return (
    newShow && (
      <div
        className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} ${
          hiddenNews ? 'hiddenNews' : 'showNews'
        } breaking-container bg-dbs-blue white`}
      >
        <div className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} news-box`}>
          {show && show.mediaContent ? (
            <Link
              to={{
                pathname: getRoute(show.mediaContent),
                state: { item: { id: itemId(show.mediaContent) } },
              }}
            >
              {show.mediaTitle}
            </Link>
          ) : (
            show.mediaTitle
          )}
        </div>
        <div className="box-buttons">
          {!DisplayUtils.isMobile() && (
            <Fragment>
              <button name="pause" onClick={event => handleChangeBreaking(event)}>
                <span>||</span>
              </button>
              <button name="next" onClick={event => handleChangeBreaking(event, show)}>
                <img src="/misc/icons/arrow_forward_white.png" alt="forward" className="next" />
              </button>
            </Fragment>
          )}
          {!DisplayUtils.isMobile() ? (
            <button name="close" onClick={event => handleChangeBreaking(event)}>
              <img src="/misc/icons/clear_white.png" alt="close" className="close-breaking" />
            </button>
          ) : (
            <Fragment>
              <button name="next" onClick={event => handleChangeBreaking(event, show)} className="next-mobile">
                <img src="/misc/icons/arrow_forward_white.png" alt="forward" className="next" />
              </button>
              <button name="close" onClick={event => handleChangeBreaking(event)} className="close-breaking-mobile">
                <img src="/misc/icons/clear_white.png" alt="close" />
              </button>
            </Fragment>
          )}
        </div>
      </div>
    )
  );
};
export default Breakings;
