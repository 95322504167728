import React, { useState, useContext, Fragment, useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import {
  BackBtn,
  ProfileMenu,
  OrderHistory,
  Addresses,
  ProfileInfo,
  ModalDouble,
  ModalError,
  ModalSimple,
} from '../index';
import DisplayUtils from '../../utils/DisplayUtils';
import { useTranslation } from 'react-i18next';
import { authService } from '../../services/index';
import { userService } from '../../services/index';
import { AuthContext } from '../../contexts/AuthContext';
import './Profile.css';

const Profile = ({ history }) => {
  const { t } = useTranslation();

  const options = [
    t('Account.menu.orders'),
    t('Account.menu.addresses'),
    t('Account.menu.profile'),
    t('Account.menu.logout'),
  ];

  const [selectedOption, setSelectedOption] = useState(0);
  const [overOption, setOverOption] = useState(0);
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [showRecoverOkModal, setShowRecoverOkModal] = useState(false);
  const [showDeleteAccountOkModal, setShowDeleteAccountOkModal] = useState(false);
  const [showResignModal, setShowResignModal] = useState(false);
  const [showError, setShowError] = useState(false);
  const { currentUser } = useContext(AuthContext)

  const screenHeight = window.innerHeight - (DisplayUtils.isMobile() ? 140 : 160);

  const optionSelected = (option) => {
    setSelectedOption(option);
  };

  const optionOver = (option) => {
    setOverOption(option);
  };

  const logOut = () => {
    showDoubleModal();
  };

  const showDoubleModalResign = async () => {
    await setShowResignModal(true);
    window.$('#doubleModal').modal('show');
  };
  const showDoubleModal = async () => {
    await setShowLogoutModal(true);
    window.$('#doubleModal').modal('show');
  };

  const hideDoubleModalResign = async () => {
    await setShowResignModal(false);
    window.$('#doubleModal').modal('hide');
    window.$('.modal-backdrop').remove();
    window.$('body').removeClass('modal-open');
  };
  const hideDoubleModal = async () => {
    await setShowLogoutModal(false);
    window.$('#doubleModal').modal('hide');
    window.$('.modal-backdrop').remove();
    window.$('body').removeClass('modal-open');
  };

  const onRecover = async (email) => {
    try {
      const response = await authService.recover({ email });
      response && showRecoverPassOkModal();
    } catch (error) {
      showErrorModal();
    }
  };

  const showRecoverPassOkModal = async () => {
    await setShowRecoverOkModal(true);
    window.$('#simpleModal').modal('show');
  };

  const hideRecoverPassOkModal = () => {
    setShowRecoverOkModal(false);
    window.$('#simpleModal').modal('hide');
    window.$('.modal-backdrop').remove();
    window.$('body').removeClass('modal-open');
  };

  const showDeleteOkModal = async () => {
    await setShowDeleteAccountOkModal(true);
    window.$('#simpleModal').modal('show');
  };

  const hideDeleteOkModal = () => {
    setShowDeleteAccountOkModal(false);
    window.$('#simpleModal').modal('hide');
    window.$('.modal-backdrop').remove();
    window.$('body').removeClass('modal-open');
  };

  const closeSession = () => {
    hideDoubleModal();
    authService.logout();
  };
  const showErrorModal = useCallback(async () => {
    await setShowError(true);
    window.$('#errorModal').modal('show');
  }, []);
  const hideErrorModal = () => {
    setShowError(false);
    window.$('#errorModal').modal('hide');
    window.$('.modal-backdrop').remove();
    window.$('body').removeClass('modal-open');
  };

  const getSelectedComponent = () => {
    switch (selectedOption) {
      case 0:
        return <OrderHistory showErrorModal={showErrorModal} />;
      case 1:
        return <Addresses />;
      case 2:
        return <ProfileInfo onRecover={onRecover} showDoubleModal={showDoubleModalResign} />;
      default:
        return <div>{options[selectedOption]}</div>;
    }
  };

  const sendDeleteAccount = async () => {
    hideDoubleModalResign()
    if(currentUser) {
      const message = { 
        name: `${currentUser.firstname} ${currentUser.lastname}`, 
        issue: "Solicitud Baja", 
        message: `El usuario ${currentUser.firstname} ${currentUser.lastname} con email ${currentUser.email} y teléfono ${currentUser.phone} ha solicitado la baja en la plataforma DBS.` };
      try {
        const response = await userService.sendSupport(message);
        if (response) {
          showDeleteOkModal()
        }
      } catch (error) {
        showErrorModal();
      }
    } else {
      showErrorModal();
    }
  }

  return (
    <div className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} profile-container bg-dbs-white`}>
      {showResignModal && (
        <ModalDouble
          title={t('Account.profile.resignTitle')}
          description={t('Account.profile.resignDescription')}
          mainBtnText={t('Globals.yes')}
          secondaryBtnText={t('Globals.no')}
          mainAction={sendDeleteAccount}
          secondaryAction={hideDoubleModalResign}
          closeModal={hideDoubleModalResign}
        />
      )}
      {showLogoutModal && (
        <ModalDouble
          title={t('LogoutModal.title')}
          description={t('LogoutModal.description')}
          mainBtnText={t('Globals.yes')}
          secondaryBtnText={t('Globals.no')}
          mainAction={closeSession}
          secondaryAction={hideDoubleModal}
          closeModal={hideDoubleModal}
        />
      )}
      {showRecoverOkModal && (
        <ModalSimple
          title={t('RememberPassOkModal.title')}
          description={t('RememberPassOkModal.description')}
          mainBtnText={t('Globals.ok')}
          mainAction={hideRecoverPassOkModal}
          closeModal={hideRecoverPassOkModal}
        />
      )}
      {showDeleteAccountOkModal && (
        <ModalSimple
          title={t('DeleteAccountOkModal.title')}
          description={t('DeleteAccountOkModal.description')}
          mainBtnText={t('Globals.ok')}
          mainAction={hideDeleteOkModal}
          closeModal={hideDeleteOkModal}
        />
      )}
      {showError && <ModalError hideErrorModal={hideErrorModal} />}
      <div className="row m-0 profile-content" style={DisplayUtils.isMobile() ? {} : { height: screenHeight }}>
        <div
          style={DisplayUtils.isMobile() ? {} : { height: screenHeight }}
          className={`${DisplayUtils.isMobile() ? 'mobile col-12 p-0' : 'desktop col-3 p-0'} profile-menu bg-dbs-gray5`}
        >
          {DisplayUtils.isMobile() && (
            <Fragment>
              <BackBtn />
            </Fragment>
          )}
          <ProfileMenu
            options={options}
            selectedOption={selectedOption}
            optionSelected={optionSelected}
            overOption={overOption}
            optionOver={optionOver}
            logOut={logOut}
          />
        </div>
        <div
          style={DisplayUtils.isMobile() ? {} : { height: screenHeight }}
          className={`${
            DisplayUtils.isMobile() ? 'mobile col-12 p-0' : 'desktop col-9 p-0'
          } components-container bg-dbs-white`}
        >
          {getSelectedComponent()}
        </div>
      </div>
      {!DisplayUtils.isMobile() && <BackBtn />}
    </div>
  );
};

export default withRouter(Profile);
