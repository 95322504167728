import React, { useEffect, useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import { Products, BackBtn } from '../index';
import DisplayUtils from '../../utils/DisplayUtils';
import './Shop.css';
import { useLocalStorage } from '../../hooks/useLocalStorage';

const Shop = () => {
  const [image, setImage] = useLocalStorage('medias', null);

  useEffect(() => {
    setImage(null);
  }, []); // eslint-disable-line

  const updateImage = useCallback(
    item => {
      if (item[0] && item[0].medias.length > 0) {
        setImage(item[0].medias);
      } else {
        setImage(null);
      }
    },
    [setImage]
  );

  return (
    <div className="shop-container bg-dbs-gray5">
      {DisplayUtils.isMobile() && <BackBtn white={true} />}

      <Products updateImage={updateImage} image={image} />
    </div>
  );
};

export default withRouter(Shop);
