import React from 'react';
import './SliderProducts.css';
import Slider from 'react-slick';
import { RegisterBtn } from '../../index';
import DisplayUtils from '../../../utils/DisplayUtils';
import { useTranslation } from 'react-i18next';
import constants from '../../../utils/constants';
import CartUtils from '../../../utils/CartUtils';
import { Link } from 'react-router-dom';

const SliderProducts = ({ products, onChangeProduct, brand, currentUser }) => {
  const { t } = useTranslation();

  const largeCarouselSettings = {
    infinite: false,
    speed: 300,
    slidesToShow: products && products.length >= 5 ? 5 : products.length,
    slidesToScroll: 1,
    arrows: products && products.length >= 5,
    swipe: false,
  };

  const mediumCarouselSettings = {
    infinite: true,
    slidesToShow: DisplayUtils.isTablet() ? 3 : 2,
    slidesToScroll: 1,
    arrows: false,
    swipe: true,
    centerMode: true,
    centerPadding: 20,
  };

  const goTo = (event, data) => {
    if(!brand){
      event.stopPropagation();
      event.preventDefault();
      onChangeProduct(data)
    }
  }

  const filterProducts = products && products.map(product => {
    if(!product.sizes ||!product.sizes.name ){
      return product
    } else {
      return {
        ...product,
        name: product.sizes.name ? product.name + " " + product.sizes.name : product.name,
        price: product.sizes.price ? product.sizes.price : product.price,
        pricepvp: product.sizes.pricepvp ? product.sizes.pricepvp : product.pricepvp,
        images: product.sizes.image ? [product.sizes.image] : product.images,
        promotion: product.sizes.promotion ? product.sizes.promotion : product.promotion,
        promotionType: product.sizes.promotionType ? product.sizes.promotionType : product.promotionType
      }
    }
  })

  const carouselProduct = filterProducts.map((item, index) => (
    <Link to={{
      pathname: '/product-detail',
      state: { item, brand },
    }} key={index}>
    <div key={index} className="" onClick={(event) => goTo(event, item.id)}>
      <div className="px-1">
        <div className="recommended-product-box bg-dbs-white">
          <div className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} recommended-image bg-dbs-white`}>
            <img src={DisplayUtils.getProductImage(item, constants.NO_IMAGE)} className="top-img" alt="top" onError={e => {
                e.target.onerror = null;
                e.target.src = `${constants.NO_IMAGE}`;
              }}/>
          </div>
          <div className="separator bg-dbs-gray4" />
          <div className="product-info">
            <span className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} product-name dbs-black`}>
              {item.name}
            </span>
            <span className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} product-description dbs-gray3`}>
              {item.descriptionshort.replace(constants.REMOVE_HTML_PATTERN, '')}
            </span>
            {!currentUser ? (
              <RegisterBtn  position="text-left" />
            ) : (
              <div className="d-flex justify-content-between">
                <span className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} d-block product-price dbs-black`}>
                  {CartUtils.formatPrice(item.price)}
                </span>
                <span className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} d-block product-pvp dbs-gray3`}>
                  ({(item.pricepvp && item.pricepvp !== 0) ? t('Products.pricePvp') : ''}
                  {(item.pricepvp) ? CartUtils.formatPricePvpr(item.pricepvp.toFixed(2)) : 'profesional'})
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
    </Link>
  ));

  return (
    <div className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} recommended-container bg-dbs-white`}>
      {brand ? (
        <div className={`${DisplayUtils.isMobile() ? 'mobile mb-3' : 'desktop mb-3'} section-title-brand`}>
          <span className="black">{t('Products.products')}</span>
          <span className="main-blue">{t('Products.recommended3')}</span>
        </div>
      ) : (
        <div className={`${DisplayUtils.isMobile() ? 'mobile mb-3' : 'desktop mb-3'} section-title`}>
          <span className="black">
            {t('Products.recommended')}
            {t('Products.recommended2')}
            {/* <strong>{t('Products.recommended2')}</strong> */}
          </span>
        </div>
      )}
      <Slider {...(DisplayUtils.isMobile() ? mediumCarouselSettings : largeCarouselSettings)}>{carouselProduct}</Slider>
    </div>
  );
};

export default SliderProducts;
