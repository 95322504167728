import React from 'react';
import DisplayUtils from '../../../../utils/DisplayUtils';
import { useTranslation } from 'react-i18next';
import './EmptyBox.css';

const EmptyBox = ({ inputSearch }) => {
  const { t } = useTranslation();
  return (
    <div className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} empty-box-products bg-dbs-white`}>
      <img src="/misc/icons/busqueda.svg" alt="icon" />
      {inputSearch ? (
        <div>
          <span className="px-3 pt-2">
            {t('Filters.exist')}
            <strong>{inputSearch}</strong>
          </span>
          <span className="px-3 pt-1 pb-2">{t('Filters.error')}</span>
        </div>
      ) : (
        <span className="px-3 pt-2">
          {t('Filters.noData')}
        </span>
      )}
      
    </div>
  );
};

export default EmptyBox;
