import React, { Fragment } from 'react';
import DisplayUtils from '../../../../utils/DisplayUtils';
import { useTranslation } from 'react-i18next';
import './ModalSimpleImage.css';

const ModalSimpleImage = ({
  imageUrl,
  title,
  description,
  shop,
  description2,
  mainBtnText,
  mainAction,
  closeModal,
}) => {
  const { t } = useTranslation();

  return (
    <div className="simpleModalImage-box d-flex align-items-center">
      <div
        className={`${!DisplayUtils.isMobile() ? 'desktop' : 'mobile'} modal fade`}
        id="simpleModalImage"
        role="dialog"
        aria-labelledby="myModalLabel"
        tabIndex="-1"
        aria-hidden="true"
      >
        <div
          className={`${!DisplayUtils.isMobile() ? 'desktop' : 'mobile m-0'} simple-modal-image-dialog `}
          role="document"
        >
          <div className={`${!DisplayUtils.isMobile() ? 'desktop' : 'mobile'} simple-modal-image-content bg-light-b2b`}>
            <div
              className={`${
                !DisplayUtils.isMobile() ? 'desktop' : 'mobile'
              } simple-modal-image-body bg-light-b2b black`}
            >
              {!DisplayUtils.isMobile() && (
                <div
                  className={`${
                    DisplayUtils.isMobile() ? 'mobile' : 'desktop'
                  } btn-close d-flex justify-content-center align-items-center`}
                  onClick={() => closeModal()}
                >
                  <img alt="closebtn" src="/misc/icons/add_black.png" />
                </div>
              )}
              <div className={`${!DisplayUtils.isMobile() ? 'desktop' : 'mobile'} text-box black`}>
                <img
                  className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} anonymous-logo`}
                  src="/misc/icons/logo-4.svg"
                  alt="logo"
                />
                <div className="d-flex justify-content-center title mb-2">{title}</div>
                <div className="mt-2 description">
                  <span>{description}</span>
                  {shop && (
                    <Fragment>
                      <p className="mb-0">
                        <strong>{shop}</strong>
                      </p>
                      <span>{description2}</span>
                    </Fragment>
                  )}
                </div>
              </div>
              {DisplayUtils.isMobile() ? (
                <div className="row m-0 p-2">
                  <div
                    className={`${
                      !DisplayUtils.isMobile() ? ' desktop' : ' mobile'
                    } d-flex justify-content-center col-6  p-2`}
                  >
                    <button
                      className={`${
                        DisplayUtils.isMobile() ? 'mobile' : 'desktop'
                      }  bg-secondary-b2b btn-transparent d-flex justify-content-center align-items-center retry-btn`}
                      onClick={() => mainAction()}
                    >
                      <span className="white">{mainBtnText}</span>
                    </button>
                  </div>
                  <div
                    className={`${
                      !DisplayUtils.isMobile() ? 'desktop' : 'mobile'
                    } col-6 d-flex justify-content-center p-2`}
                  >
                    <button
                      className={`${
                        !DisplayUtils.isMobile() ? 'desktop' : 'mobile'
                      } bg-dark-b2b btn-modal d-flex justify-content-center align-items-center secondary-btn`}
                      onClick={() => closeModal()}
                    >
                      <span className="white">{t('Errors.cancel')}</span>
                    </button>
                  </div>
                </div>
              ) : (
                <div className="row m-0 p-2">
                  <div
                    className={`${
                      !DisplayUtils.isMobile() ? 'desktop' : 'mobile'
                    } col-12 d-flex p-0 justify-content-center`}
                  >
                    <button
                      className={`${
                        DisplayUtils.isMobile() ? 'mobile' : 'desktop'
                      } btn-modal bg-secondary-b2b btn-transparent d-flex justify-content-center align-items-center ok-btn`}
                      onClick={() => mainAction()}
                    >
                      <span className="white">{mainBtnText}</span>
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalSimpleImage;
