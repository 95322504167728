import React from 'react';
import Slider from 'react-slick';
import DisplayUtils from '../../../utils/DisplayUtils';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './Brands.css';

const Brands = ({ brands }) => {
  const { t } = useTranslation();

  const largeCarouselSettings = {
    dots: DisplayUtils.isMobile(),
    infinite: true,
    slidesToShow: !DisplayUtils.isMobile() ? 4 : DisplayUtils.isTablet() ? 2 : 1,
    slidesToScroll: 1,
    arrows: !DisplayUtils.isMobile(),
    swipe: DisplayUtils.isMobile(),
    customPaging: i => (
      <div className="custom-paging"
      >
      </div>
    )
  };

  return (
    brands && brands.length > 0 &&
      <div className="brands-container bg-dbs-gray5">
        <div className={`${DisplayUtils.isMobile() ? 'p-4' : 'p-4'} brands-title`}>
          <span className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} title-blue`}>{t('Brands.title').toUpperCase()}</span>
        </div>
        <div className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} brands-content m-0`}>
          <Slider className="slider-brands" {...largeCarouselSettings}>
            {brands.map(item => (
              <div className={`${DisplayUtils.isMobile() ? 'mobile ' : 'desktop'} p-0`} key={item.id}>
                <div className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} brands-list p-0 col-12`}>
                  <Link to={{ pathname: '/brand-detail', state: { item } }}>
                    <img src={item.images[0]} alt="img-brand" className="photo-img" />
                  </Link>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
  );
};

export default Brands;
