export const local = {
  API_URL: process.env.REACT_APP_ENVIRONMENT_LOCAL,
};

export const development = {
  API_URL: process.env.REACT_APP_ENVIRONMENT_DEV,
};

export const staging = {
  API_URL: process.env.REACT_APP_ENVIRONMENT_STAGING,
};

export const production = {
  API_URL: process.env.REACT_APP_ENVIRONMENT_PROD,
};

export default local;
