import http from './base-http-service';
import constants from '../utils/constants';

const getMedia = async () => {
  const config = { headers: { Authorization: localStorage.getItem(constants.CURRENT_TOKEN_KEY) } };
  try {
    const response = await http.get('/media/home', config);
    return response.data;
  } catch (error) {
    throw error;
  }
};
const getPromos = async () => {
  const config = { headers: { Authorization: localStorage.getItem(constants.CURRENT_TOKEN_KEY) } };
  try {
    const response = await http.get('/media/welcome-pop-up', config);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const getBrands = async () => {
  const config = { headers: { Authorization: localStorage.getItem(constants.CURRENT_TOKEN_KEY) } };
  try {
    const response = await http.get('/products/manufacturer', config);
    return response.data;
  } catch (error) {
    throw error;
  }
}
const getNews = async () => {
  const config = { headers: { Authorization: localStorage.getItem(constants.CURRENT_TOKEN_KEY) } };
  try {
    const response = await http.get('/media/breaking-news', config);
    return response.data;
  } catch (error) {
    throw error;
  }
}

export default {
  getMedia,
  getBrands,
  getNews,
  getPromos
};
