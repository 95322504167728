import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { CartCount, CartModal, RegisterBtn } from '../../../index';
import DisplayUtils from '../../../../utils/DisplayUtils';
import CartUtils from '../../../../utils/CartUtils';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../../../contexts/AuthContext';
import constants from '../../../../utils/constants';
import './ProductItem.css';

const ProductItem = ({ item, layout }) => {
  const { currentCart, setCurrentCart, modalCart, setModalCart, currentUser } = useContext(AuthContext);

  const { t } = useTranslation();

  const [productCount, setProductCount] = useState(1);
  const [expired, setExpired] = useState(false);

  useEffect(() => {
    const getExpired = async () => {
      const promotionExpired = await CartUtils.getExpiration(item.expiration);
      setExpired(promotionExpired);
    };
    item.promotion && item.expiration && getExpired();
  }, [item.promotion, item.expiration]);

  const editQuantity = quantity => {
    setProductCount(quantity);
  };

  const addToCart = async (product, quantity) => {
    modalCart && (await hideCartModal());
    const response = await CartUtils.addToCart(currentCart, setCurrentCart, product, productCount, product.sizes);
    setCurrentCart(response);
    editQuantity(1);
    response && showCartModal();
  };

  const showCartModal = async () => {
    await setModalCart(true);
    window.$('#cartModal').modal('show');
  };

  const hideCartModal = () => {
    setModalCart(false);
    window.$('#cartModal').modal('hide');
    window.$('.modal-backdrop').remove();
    window.$('body').removeClass('modal-open');
  };

  const goCart = () => {
    hideCartModal();
  };

  const gridItem = () => {
    return (
      <Link className="" to={{ pathname: '/product-detail', state: { item: item } }}>
        <div className="box-content grid-layout">
          <div className="col-12 p-0 bg-white img-container">
            <img
              className={DisplayUtils.isMobile() ? 'img-grid-mobile' : 'img-grid'}
              src={DisplayUtils.getProductImage(item, constants.NO_IMAGE)}
              alt="img-product"
              onError={e => {
                e.target.onerror = null;
                e.target.src = `${constants.NO_IMAGE}`;
              }}
            />
            {item.promotion && !expired && (
              <div className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} bg-secondary-b2b promotion-box `}>
                <span className="white">{t('Products.promotion')}</span>
              </div>
            )}
          </div>
          <div className="separator bg-dbs-gray4" />
          <div className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} product-info`}>
            <span className="product-name black">{item.name}</span>
            <span className="product-description grid mb-1 dbs-gray3">
              {item.descriptionshort.replace(constants.REMOVE_HTML_PATTERN, '')}
            </span>
          </div>
          {!currentUser ? (
            <RegisterBtn position="text-left" />
          ) : (
            typeof(item.price) === 'number' &&
            (item.promotion && !expired ? (
              <div className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} product-price`}>
                <span className="mr-2 price-before"> {CartUtils.formatPrice(item.price.toFixed(2))} </span>
                <span className="black">
                  {t('Products.now')}
                  {CartUtils.formatPrice(
                    CartUtils.getPricePromotion(item.price.toFixed(2), item.promotion, item.promotionType)
                  )}
                </span>
                <span className="price pvpr d-block mt-1">
                  ({item.pricepvp !== 0 && `${t('Products.pricePvp')} `}{CartUtils.formatPricePvpr(item.pricepvp.toFixed(2))})
                </span>
              </div>
            ) : (
              <div className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} product-price`}>
                <span className="mr-1 price black"> {CartUtils.formatPrice(item.price.toFixed(2))} </span>
                <span className="price pvpr">
                  ({item.pricepvp !== 0 && `${t('Products.pricePvp')} `}{CartUtils.formatPricePvpr(item.pricepvp.toFixed(2))})
                </span>
              </div>
            ))
          )}
        </div>
      </Link>
    );
  };

  const listItem = () => {
    return (
      <div className="row list-layout">
        {modalCart && (
          <CartModal closeModal={hideCartModal} cartLocal={currentCart} productsCart={currentCart} goCart={goCart} />
        )}
        <div className="separator bg-dbs-gray4" />
        <Link className="col-10 h-100 p-0" to={{ pathname: '/product-detail', state: { item: item } }}>
          <div className="row m-0">
            <div className="col-2 img-list p-0">
              <img
                className=""
                src={DisplayUtils.getProductImage(item, constants.NO_IMAGE)}
                alt="img-product"
                onError={e => {
                  e.target.onerror = null;
                  e.target.src = `${constants.NO_IMAGE}`;
                }}
              />
            </div>
            <div className="col-10 d-flex align-items-center justify-content-end p-0">
              <div className="product-info list p-0">
                <span className="product-name list align-middle">{item.name}</span>
                <span className="product-description list align-middle dbs-gray3">
                  {item.descriptionshort.replace(constants.REMOVE_HTML_PATTERN, '')}
                </span>
              </div>
              {!currentUser ? (
                <div className="prices-container list d-flex justify-content-between">
                  <RegisterBtn position="text-center" />
                </div>
              ) : (
                typeof(item.price) === 'number' &&
                (!item.promotion || expired ? (
                  <div className="prices-container list d-flex justify-content-between">
                    <span className="price pvpr">({CartUtils.formatPricePvpr(item.pricepvp.toFixed(2))})</span>
                    <span className="price"> {CartUtils.formatPrice(item.price.toFixed(2))} </span>
                  </div>
                ) : (
                  <div className="prices-container list d-flex justify-content-between">
                    <span className="price pvpr">({CartUtils.formatPricePvpr(item.pricepvp.toFixed(2))})</span>
                    <span className="price before">
                      <span className="price">
                        {CartUtils.formatPrice(
                          CartUtils.getPricePromotion(item.price.toFixed(2), item.promotion, item.promotionType)
                        )}{' '}
                      </span>{' '}
                      {CartUtils.formatPrice(item.price.toFixed(2))}{' '}
                    </span>
                  </div>
                ))
              )}
            </div>
          </div>
        </Link>
        {currentUser && (
          <div className="col-2 h-100 p-0 m-0 d-flex align-items-center justify-content-end">
            <CartCount quantity={productCount} editQuantity={editQuantity} />
            <button
              disabled={productCount < 1}
              className="cart-btn d-flex align-items-center justify-content-center"
              onClick={() => addToCart(item, productCount, item.sizes)}
            >
              {t('Products.add')}
            </button>
          </div>
        )}
      </div>
    );
  };

  return (
    <div
      className={`${
        layout === 'grid' ? (DisplayUtils.isMobile() ? 'col-6 p-0 pl-2 pr-2' : 'col-3') : 'col-12'
      } box-product`}
    >
      {layout === 'grid' ? gridItem() : listItem()}
    </div>
  );
};

export default ProductItem;
