import React, { useReducer, useState, useContext, useEffect, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { BackBtn, ModalSimple, ModalError, Loading } from '../index';
import DisplayUtils from '../../utils/DisplayUtils';
import constants from '../../utils/constants';
import { useTranslation } from 'react-i18next';
import { userService } from '../../services/index';
import { AuthContext } from '../../contexts/AuthContext';
import './ContactUs.css';
const initialState = {
  formSelector: null,
  formName: '',
  formEmail: '',
  formMessage: '',
  errors: {},
};
const reducer = (state, action) => {
  state.errors[action.type] = !action.payload;
  switch (action.type) {
    case 'formSelector':
      return { ...state, formSelector: action.payload };
    case 'formName':
      return { ...state, formName: action.payload };
    case 'formEmail':
      return { ...state, formEmail: action.payload };
    case 'formMessage':
      return { ...state, formMessage: action.payload };
    default:
      return initialState;
  }
};
const ContactUs = () => {
  const { t } = useTranslation();
  const { currentUser } = useContext(AuthContext);
  const [state, dispatch] = useReducer(reducer, initialState);
  const [showSelector, setShowSelector] = useState(false);
  const [sendOkModal, setSendOkModal] = useState(false);
  const [showError, setShowError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [loadingGet, setLoadingGet] = useState(true);
  const selectorOptions = [
    t('ContactUs.firstOption'),
    t('ContactUs.secondOption'),
    t('ContactUs.thirdOption'),
    t('ContactUs.fourthOption'),
    t('ContactUs.fifthOption'),
    t('ContactUs.sixthOption'),
  ];
  const showOptions = () => {
    setShowSelector(!showSelector);
  };
  const optionSelected = option => {
    dispatch({ type: 'formSelector', payload: option });
    setShowSelector(false);
  };

  useEffect(() => {
    const getMedia = async () => {
      try {
        const response = await userService.getSupport();
        setData(response[0]);
        setLoadingGet(false);
      } catch (e) {
        setLoadingGet(false);
        showErrorModal();
      }
    };

    getMedia();
  }, []);
  const isValid = () => {
    if (currentUser) {
      const validateState = Object.keys(state)
        .filter(item => item !== 'formEmail')
        .reduce((acc, item) => {
          acc[item] = state[item];
          return acc;
        }, {});
      return !Object.values(validateState).some(item => item === '') && state.formSelector != null;
    } else {
      return (
        !Object.values(state).some(item => item === '') &&
        state.formSelector != null &&
        constants.EMAIL_PATTERN.test(state.formEmail)
      );
    }
  };
  const onSendClick = async () => {
    setLoading(true);
    const message = currentUser
      ? { name: state.formName, issue: state.formSelector, message: state.formMessage.replace(/\n/g, "<br />") }
      : { name: state.formName, email: state.formEmail, issue: state.formSelector, message: state.formMessage.replace(/\n/g, "<br />") };
    try {
      const response = await userService.sendSupport(message);
      if (response) {
        setLoading(false);
        showSendOkModal();
      }
    } catch (error) {
      setLoading(false);
      showErrorModal();
    }
    dispatch({ type: 'initialState' });
  };
  const showSendOkModal = async () => {
    await setSendOkModal(true);
    window.$('#simpleModal').modal('show');
  };
  const hideSendOkModal = () => {
    setSendOkModal(false);
    window.$('#simpleModal').modal('hide');
    window.$('.modal-backdrop').remove();
    window.$('body').removeClass('modal-open');
  };
  const showErrorModal = async () => {
    await setShowError(true);
    window.$('#errorModal').modal('show');
  };
  const hideErrorModal = () => {
    setShowError(false);
    window.$('#errorModal').modal('hide');
    window.$('.modal-backdrop').remove();
    window.$('body').removeClass('modal-open');
  };

  return (
    <Fragment>
      {loadingGet ? (
        <div style={{ height: '100vh' }} className="bg-dbs-white">
          <Loading transparent />
        </div>
      ) : (
        <div className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} contactus-container`}>
          {sendOkModal && (
            <ModalSimple
              title={t('ContactOkModal.title')}
              description={t('ContactOkModal.description')}
              mainBtnText={t('Globals.ok')}
              mainAction={hideSendOkModal}
              closeModal={hideSendOkModal}
            />
          )}
          {showError && <ModalError hideErrorModal={hideErrorModal} />}
          <img
            alt="head-img"
            className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} head-image`}
            src={data.mediaSource}
            onError={(e)=>{e.target.onerror = null; e.target.src=`${'/misc/img/promos.png'}`}}
          />
          <BackBtn white={true} />
          {loading ? (
            <div style={{ height: '800px' }}>
              <Loading transparent />
            </div>
          ) : (
            <div className={`${DisplayUtils.isMobile() ? 'mobile mt-5' : 'desktop'} contactus-content`}>
              <div className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop pb-3'} contactus-text text-center`}>
                <span className="title d-block mb-2">{data.mediaTitle}</span>
                <span className="text d-block mb-3 dbs-gray2">
                  {data.mediaContent.replace(constants.REMOVE_HTML_PATTERN, '')}
                  <strong className="main-blue">{" "}{data.highlight}</strong>
                </span>
              </div>
              <div className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} row m-0 contactus-form`}>
                <div className="row m-0 w-100">
                  <div className={`${DisplayUtils.isMobile() ? 'col-12' : 'col-6 pr-2'} contactus-name p-0`}>
                    <p className="placeholder-form dbs-gray2">{t('ContactUs.name')}</p>
                    <input
                      name="formName"
                      value={state.formName}
                      type="text"
                      className={`${state.errors.formName && 'is-invalid'} w-100`}
                      onBlur={e => dispatch({ type: e.target.name, payload: e.target.value })}
                      onChange={e => dispatch({ type: e.target.name, payload: e.target.value })}
                    />
                    {state.errors.formName && <span className="red">{t('Errors.empty')}</span>}
                  </div>
                  <div
                    className={`${
                      DisplayUtils.isMobile() ? 'col-12 mobile' : 'col-6 pl-2 desktop'
                    } contactus-dropdown p-0`}
                  >
                    <p className="placeholder-form dbs-gray2">{t('ContactUs.subject')}</p>
                    <div className="dropdown-button d-flex align-items-center" onClick={showOptions}>
                      <span className={`${!state.formSelector ? 'placeholder' : 'dbs-gray2'} mr-auto`}>
                        {/* {state.formSelector ? state.formSelector : t('ContactUs.subjectSelect')} */}
                        {state.formSelector ? state.formSelector : ''}
                      </span>
                      <img
                        alt="dropdown-icon"
                        className="dropdown-icon"
                        src={showSelector ? '/misc/icons/arrow_up_black.svg' : '/misc/icons/arrow_down_black@2x.png'}
                      ></img>
                    </div>
                    {showSelector ? (
                      <div className={`${!DisplayUtils.isMobile() ? 'desktop' : 'mobile'} dropdown-options p-3`}>
                        <ul>
                          {selectorOptions.map((option, index) => (
                            <li key={index} className="mb-2" onClick={() => optionSelected(option)}>
                              {option}
                            </li>
                          ))}
                        </ul>
                      </div>
                    ) : null}
                  </div>
                  {!currentUser && (
                    <div className={`${DisplayUtils.isMobile() ? 'col-12' : 'col-12'} contactus-name p-0`}>
                      <p className="placeholder-form dbs-gray2">{t('ContactUs.email')}</p>
                      <input
                        name="formEmail"
                        value={state.formEmail}
                        type="text"
                        className={`${state.errors.formEmail && 'is-invalid'} ${state.formEmail &&
                          !constants.EMAIL_PATTERN.test(state.formEmail) &&
                          'is-invalid'} ${constants.EMAIL_PATTERN.test(state.formEmail) && 'is-valid'} w-100`}
                        onBlur={e => dispatch({ type: e.target.name, payload: e.target.value })}
                        onChange={e => dispatch({ type: e.target.name, payload: e.target.value })}
                      />
                      {state.errors.formEmail && <span className="red">{t('Errors.empty')}</span>}
                    </div>
                  )}
                  <div className="col-12 p-0">
                    <p className="placeholder-form dbs-gray2">{t('ContactUs.message')}</p>
                    <textarea
                      name="formMessage"
                      value={state.formMessage}
                      className={`${state.errors.formMessage && 'is-invalid'} w-100`}
                      onBlur={e => dispatch({ type: e.target.name, payload: e.target.value })}
                      onChange={e => dispatch({ type: e.target.name, payload: e.target.value })}
                    />
                    {state.errors.formMessage && <span className="red">{t('Errors.empty')}</span>}
                  </div>
                  <div className="d-flex justify-content-center align-items-center w-100">
                    <button
                      onClick={() => onSendClick()}
                      disabled={!isValid()}
                      className={`${
                        DisplayUtils.isMobile() ? 'mobile' : 'desktop'
                      } contactus-button align-items-center d-flex justify-content-center btn-transparent bg-dbs-gray1 ${
                        isValid() ? 'valid' : 'invalid'
                      }`}
                    >
                      <span>{t('Globals.sendMessage')}</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </Fragment>
  );
};
export default withRouter(ContactUs);
