import React from 'react';
import Slider from 'react-slick';
import DisplayUtils from '../../../utils/DisplayUtils';
import constants from '../../../utils/constants';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import './CarouselProducts.css';

const CarouselProducts = ({ backgroundColor, recommended, showTitle }) => {
  const { t } = useTranslation();

  const largeCarouselSettings = {
    dots: DisplayUtils.isMobile(),
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: !DisplayUtils.isMobile(),
    swipe: DisplayUtils.isMobile(),
    centerMode: true,
    variableWidth: true,
    customPaging: i => (
      <div className="custom-paging"
      >
      </div>
    )
  };

  const getRoute = (item) => item.split('?')[0];
  const itemId = (item) => item.split('=')[1];

  const goRoute = (item) => {
    const route = getRoute(item);
    route === '/shop' && localStorage.removeItem(constants.CURRENT_FILTERS_KEY);
  };

  const carouselProduct = recommended && recommended.map((item, index) => (
    <Link
      key={index}
      className=""
      to={{
        pathname: getRoute(item.mediaContent),
        state: { item: { id: itemId(item.mediaContent), title: item.mediaTitle } },
      }}
    >
      <div className={`${DisplayUtils.isMobile() ? 'p-0 px-2' : ''} col`} onClick={goRoute(item.mediaContent)}>
        <div className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} promotions-product`}>
          <img src={item.mediaSource} className="top-img" alt="top" />
          {/* {!DisplayUtils.isMobile() && item.images[1] ? (
                        <img src={item.images[1]} className="hover-img" alt="top" />
                    ) : null} */}
        </div>
        <div className="mt-3">
          <span className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} product-name dbs-gray2 mr-3`}>
            {item.mediaTitle}
          </span>
        </div>
      </div>
    </Link>
  ));

  return (
    <div className={`carousel-main-container ${backgroundColor ? backgroundColor : 'bg-dbs-gray5'} ${DisplayUtils.isMobile() ? 'mobile' : 'desktop'}`}>
      <div className="carousel-products-container">
        <div className={`row m-0 carousel-products-padding ${showTitle ? '' : 'carousel-products-padding-top'}`}>
          <div className="col-12 p-0">
            {showTitle && (
              <div className={`${DisplayUtils.isMobile() ? 'p-4 mobile' : 'desktop'} section-title`}>
                <span className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} title`}>
                  {t('Home.carouselTitle')}
                </span>
                <span className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} description d-flex flex-column`}>
                  {t('Home.carouselDescription')}
                </span>
              </div>
            )}
            <div className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} promotions`}>
              <Slider className="pl-2 pr-0" {...largeCarouselSettings}>
                {carouselProduct}
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CarouselProducts;
