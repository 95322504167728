import React, { useState, useContext, useEffect } from 'react';
import { CartCount } from '../../index';
import { AuthContext } from '../../../contexts/AuthContext';
import DisplayUtils from '../../../utils/DisplayUtils';
import CartUtils from '../../../utils/CartUtils';
import constants from '../../../utils/constants';
import './CartItem.css';

const CartItem = ({ product, updateTotal }) => {

  const { currentCart, setCurrentCart } = useContext(AuthContext);
  const [productQuantity, setProductQuantity] = useState(null);

  useEffect(() => {
    let unmounted = false;
    const getPrice = async () => {
      const response = await CartUtils.getProductQuantity(currentCart, product);
      setProductQuantity(response)
    }
    !unmounted && getPrice()
    return () => {
      unmounted = true
    }
  }, [product, currentCart])

  const deleteItem = (product) => {
    CartUtils.deleteToCart(currentCart, setCurrentCart, product);
    updateTotal();
  };

  const editQuantity = (quantity) => {
    setProductQuantity(quantity);
    CartUtils.updateQuantity(currentCart, setCurrentCart, product, quantity);
    updateTotal();
  };

  return (
    <div className="row cart-item">
      <div className="col-1 img-item p-0">
        <img className="" src={DisplayUtils.getProductImage(product, constants.NO_IMAGE)} alt="img-product" onError={(e)=>{e.target.onerror = null; e.target.src=`${constants.NO_IMAGE}`}} />
      </div>
      <div
        className={`${
          DisplayUtils.isMobile() ? 'mobile pr-0 col-4' : 'desktop justify-content-start col-5'
        }  d-flex align-items-center p-0`}
      >
        <span className={`${DisplayUtils.isMobile() ? 'mobile d-flex flex-column' : 'desktop'} product-name list`}>
          {product.name}
        </span>
      </div>
      <div className={`${DisplayUtils.isMobile() ? 'mobile col-7 p-0' : 'desktop pl-2 col-6'} h-100 m-0 d-flex align-items-center justify-content-between box-info`}>
        <div className={`${DisplayUtils.isMobile() ? 'mobile mobile-flex' : 'desktop d-flex justify-content-between'} `}>
          <span className={`${DisplayUtils.isMobile() ? 'mobile text-center' : 'desktop'} product-price list`}>
            {CartUtils.formatPrice(CartUtils.getPricePromotion(product.price, product.promotion, product.promotionType, product.expiration))}
          </span>
        </div>
        <div className="d-inline-flex align-items-center">
          <CartCount quantity={productQuantity} editQuantity={editQuantity} />
        </div>
        <button className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} cart-btn d-flex align-items-center justify-content-center`} onClick={() => deleteItem(product)} />
      </div>
    </div>
  );
};

export default CartItem;