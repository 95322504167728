import http from './base-http-service';
import constants from '../utils/constants';

const getCategories = async (promotions) => {
  const config = { headers: { Authorization: localStorage.getItem(constants.CURRENT_TOKEN_KEY) } };
  const url = promotions ? '/products/categories/promotions' : '/products/categories';
  try {
    const response = await http.get(url, config);
    if (response.status === 200) {
      return response.data;
    } else {
      throw response.data;
    }
  } catch (error) {
    throw error.response.data;
  }
};

const getProducts = async (options) => {
  const configProduct = {
    headers: { Authorization: localStorage.getItem(constants.CURRENT_TOKEN_KEY) },
    params: { categoryId: options.id ? options.id : 2, limit: 48, offset: (options.offset-1)*48, suggestion: options.word, selected: options.selected },
  };
  try {
    const response = await http.get('/products', configProduct);
    if (response.status === 200) {
      return response.data;
    } else {
      throw response.data;
    }
  } catch (error) {
    throw error.response.data;
  }
};
const getProductById = async (id) => {
  const config = { headers: { Authorization: localStorage.getItem(constants.CURRENT_TOKEN_KEY) } };
  try {
    const response = await http.get(`/products/${id}`, config);
    if (response.status === 200) {
      return response.data;
    } else {
      throw response.data;
    }
  } catch (error) {
    throw error.response.data;
  }
};

const getSuggestions = async (word) => {
  const configProduct = {
    headers: { Authorization: localStorage.getItem(constants.CURRENT_TOKEN_KEY) },
  };
  try {
    const response = await http.get(`/products/suggestions/${word}`, configProduct);
    if (response.status === 200) {
      return response.data;
    } else {
      throw response.data;
    }
  } catch (error) {
    throw error.response.data;
  }
};
const getImg = async () => {
 
  try {
    const response = await http.get('/media/shop-footer',);
    if (response.status === 200) {
      return response.data;
    } else {
      throw response.data;
    }
  } catch (error) {
    throw error.response.data;
  }
};
const getItems = async () => {
 
  try {
    const response = await http.get('/media/header-text-promotions',);
    if (response.status === 200) {
      return response.data;
    } else {
      throw response.data;
    }
  } catch (error) {
    throw error.response.data;
  }
};

export default {
  getCategories,
  getProducts,
  getSuggestions,
  getProductById,
  getImg,
  getItems
};
