import React from 'react'
import DisplayUtils from '../../../utils/DisplayUtils';
import { useTranslation } from 'react-i18next';
import './Terms.css'

const Terms = ({ onShowTerms, acceptTerms }) => {
  const { t } = useTranslation();
  return (
    <div className="terms-content">
      <img
        alt="back-btn"
        className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} back-btn`}
        src="/misc/icons/arrow_back2_black.svg"
        onClick={onShowTerms}
      />
      <div className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} terms-text`}>
        <span className="title d-block mb-5">{t('Terms.title')}</span>
        <div className="terms-text-description mb-5">
          <span className="text d-block mb-4 grey">{t('Terms.description')}</span>
          <span className="text d-block mb-5 grey">{t('Terms.description')}</span>
          <span className="text d-block mb-5 grey">{t('Terms.description')}</span>
          <span className="text d-block grey">{t('Terms.description')}</span>
        </div>
        <button
          onClick={acceptTerms}
          className={`${
            DisplayUtils.isMobile() ? 'mobile' : 'desktop '
          } white bg-dbs-blue m-auto align-items-center d-flex justify-content-center btn-transparent ok-btn`}
        >
          <span>{t('Globals.ok')}</span>
        </button>
      </div>
      <div className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} row m-0 register-form`}></div>
    </div>
  );
};
export default Terms