const CURRENT_USER_KEY = 'cU';
const CURRENT_TOKEN_KEY = 'cT';
const CURRENT_ROLE_KEY = 'role';
const CURRENT_CART_KEY = 'cart';
const CURRENT_COOKIES_KEY = 'cA';
const CURRENT_SESSION_KEY = 'session';
const CURRENT_FIRST_KEY = 'first';
const CURRENT_INITIAL_KEY = 'initial';
const CURRENT_FILTERS_KEY = 'filter';
const NO_NEWS = 'news';
const CURRENT_DETAILS_FILTERS_KEY = 'detail_filter';
const PHONE_NUMBER = "600 123 456"
const VIDEO_MEDIA = process.env.REACT_APP_PRESTASHOP_MEDIA_VIDEO_HOME_CAT_ID
const RECOMMENDED_MEDIA = process.env.REACT_APP_PRESTASHOP_MEDIA_RECOMMENDED_HOME_CAT_ID
const SLIDER_MEDIA = process.env.REACT_APP_PRESTASHOP_MEDIA_SLIDER_HOME_CAT_ID
const NAME_PATTERN = /^[a-zA-ZáÁéÉíÍóÓúÚñÑüÜ\s]+$/;
const EMAIL_PATTERN = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i; // eslint-disable-line
const REMOVE_HTML_PATTERN = /<\/?[^>]+(>|$)/g //return string.replace(REMOVE_HTML_PATTERN, "");
const NO_IMAGE = "/misc/img/empty_product_image.png"
const PROTOCOLS = /(http(s)?:\/\/)/g;
const URL_IMAGES = 'https://mysql-b2b-prod.uxsmobile.com';

export default {
  CURRENT_USER_KEY,
  CURRENT_CART_KEY,
  CURRENT_TOKEN_KEY,
  CURRENT_ROLE_KEY,
  CURRENT_COOKIES_KEY,
  CURRENT_DETAILS_FILTERS_KEY,
  VIDEO_MEDIA,
  RECOMMENDED_MEDIA,
  SLIDER_MEDIA,
  EMAIL_PATTERN,
  REMOVE_HTML_PATTERN,
  CURRENT_FILTERS_KEY,
  CURRENT_SESSION_KEY,
  CURRENT_INITIAL_KEY,
  PHONE_NUMBER,
  NAME_PATTERN,
  NO_IMAGE,
  NO_NEWS,
  CURRENT_FIRST_KEY,
  PROTOCOLS,
  URL_IMAGES
};