import React, { useState, useEffect, Fragment, useContext } from 'react';
import {withRouter} from 'react-router-dom';
import constants from '../../../utils/constants';
import DisplayUtils from '../../../utils/DisplayUtils';
import { Pagination, OrderItem, Loading } from '../../index';
import { orderService } from '../../../services/index';
import { useTranslation } from 'react-i18next';
import './OrderHistory.css';
import { AuthContext } from '../../../contexts/AuthContext';

const OrderHistory = ({ showErrorModal, history }) => {
  const limit = 2
  const [offset, setOffset] = useState(0)
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [listOrders, setListOrders] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const { setCurrentCart } = useContext(AuthContext);
  const [OrdersPerPage] = useState(limit);

  useEffect(() => {
    const getOrders = async () => {
      setLoading(true)
      try {
        const response = await orderService.getOrders(limit, offset);
        setListOrders(response);
        setLoading(false)
      } catch (error) {
        setLoading(false);
        showErrorModal()
      }
    };
    getOrders()
  }, [showErrorModal, offset]);
  
  const repeatOrder = (filterProducts) => {
    setCurrentCart(filterProducts)
    localStorage.setItem(constants.CURRENT_CART_KEY, JSON.stringify(filterProducts));
    history.push('/cart-detail')
  };

  const paginate = pageNumber => {
    switch (pageNumber) {
      case 'right':
        setOffset(limit+offset)
        setCurrentPage(currentPage + 1);
        break;
      case 'left':
        setOffset(offset-limit)
        setCurrentPage(currentPage - 1);
        break;
      default:
        setCurrentPage(pageNumber);
        break;
    }
  };
  
  return (
    <div
      className={`${!DisplayUtils.isMobile() ? 'desktop' : 'mobile'} ${
        !listOrders || listOrders.total <= 0 ? 'h-100 align-items-center d-flex justify-content-center' : ''
      } orders-container`}
    >
      {loading ? (
        <div style={{ height: '550px' }}>
          <Loading transparent />
        </div>
      ) : listOrders && listOrders.total > 0 ? (
        <Fragment>
          {listOrders.data.map(order => (
            <div key={order.idOrder}>
              <OrderItem key={order.idOrder} order={order} repeatOrder={repeatOrder} />
              <div className="separator bg-dbs-gray4" />
            </div>
          ))}
          <div className="row m-0 justify-content-center align-items-center">
            <Pagination
              ItemsPerPage={OrdersPerPage}
              totalItems={listOrders.total}
              paginate={paginate}
              currentPage={currentPage}
            />
          </div>
          <div className="pvpr-info">
            {t('Account.orders.pvpInfo')}
          </div>
        </Fragment>
      ) : (
        <div className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop '} empty-orders-container bg-dbs-white`}>
          <div className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} row m-0 empty-orders-parts`}>
            <div
              className={`${
                !DisplayUtils.isMobile() ? 'desktop justify-content-center ' : 'mobile justify-content-start'
              } w-100 bg-dbs-white d-flex align-items-center flex-column box-empty `}
            >
              <img src="/misc/icons/empty-box.svg" alt="bag" className="" />
              <span className="text-center text-empty">{t('Account.orders.emptyOrders')}</span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default withRouter(OrderHistory);
