import React from 'react';
import { useTranslation } from 'react-i18next';
import DisplayUtils from '../../../utils/DisplayUtils';
import './RegisterBtn.css';
import { withRouter } from 'react-router-dom';

const RegisterBtn = ({ history, position }) => {
  const { t } = useTranslation();

  const goToModal = (e) => {
    e.preventDefault();
    e.stopPropagation();
    history.push('/login');
  }
  return (
    <button
      className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} btn-transparent dbs-gray3 btn-register ${position}`}
      onClick={(e) => goToModal(e)}
    >
      {t('Products.register')}
    </button>
  );
};

export default withRouter(RegisterBtn)
