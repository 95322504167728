import React from 'react';
import './CartCount.css';

const options = {
  ADD: 'ADD',
  REMOVE: 'REMOVE',
};

const CartCount = ({ quantity, editQuantity, white, detail }) => {
  const changeCount = (option) => {
    switch (option) {
      case options.ADD:
        editQuantity(quantity + 1);
        break;
      case options.REMOVE:
        if (quantity > 1) {
          editQuantity(quantity - 1);
        }
        break;
      default:
        editQuantity(1);
        break;
    }
  };

  return (
    <div
      className={`${white ? 'bg-white' : 'bg-light-b2b'} ${
        detail ? 'count-container-detail' : 'count-container'
      }  d-flex align-items-center`}
    >
      <div className="row m-0 w-100">
        <div className="col p-0 d-flex align-items-center justify-content-end">
          <img alt="remove" src="/misc/icons/remove_black@2x.png" onClick={() => changeCount(options.REMOVE)} />
        </div>
        <div className="col p-0 text-center">{quantity}</div>
        <div className="col p-0 d-flex align-items-center">
          <img alt="add" src="/misc/icons/add_black@2x.png" onClick={() => changeCount(options.ADD)} />
        </div>
      </div>
    </div>
  );
};

export default CartCount;
