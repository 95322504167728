import React from 'react';
import DisplayUtils from '../../../../utils/DisplayUtils';
import { useTranslation } from 'react-i18next';
import './RegisterOk.css';

const RegisterOk = ({ showRegisterOk }) => {
  const { t } = useTranslation();

  return (
    <div className={`${DisplayUtils.isMobile() ? 'mobile p-3' : 'desktop p-4'} success-container`}>
      <div className="success-content d-flex justify-content-center row m-0">
        <img
          alt="success-img"
          className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} success-image`}
          src="/misc/icons/Send.svg"
        />
        <div className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} mt-5 success-text`}>
          <span className="title d-block mb-2 black">{t('RegisterForm.successTitle')}</span>
          <span className="text d-block mb-3 black">{t('RegisterForm.successDescription')}</span>
        </div>
        <button onClick={() => showRegisterOk(false)} className="bg-dbs-gray1 white mt-5 btn-transparent ok-btn">
          {t('Globals.ok')}
        </button>
      </div>
    </div>
  );
};

export default RegisterOk;
