import http from './base-http-service';
import constants from '../utils/constants';

let tokenLocal = localStorage.getItem(constants.CURRENT_TOKEN_KEY);

const authenticate = async (user) => {
  try {
    const response = await http.post('/users/login', user);
    if (response.status === 200) {
      const responseData = await response.data;
      tokenLocal = responseData.token;
      localStorage.setItem(constants.CURRENT_TOKEN_KEY, tokenLocal);
      responseData.firstTime ? 
      localStorage.setItem(constants.CURRENT_FIRST_KEY, "firstTime") : 
      localStorage.setItem(constants.CURRENT_FIRST_KEY, "promo")
      return responseData;
    } else {
      throw response.data;
    }
  } catch (error) {
    throw error;
  }
};

const getUserInfo = async () => {
  let config = { headers: { Authorization: tokenLocal } };
  if (tokenLocal) {
    try {
      const response = await http.get('/users', config);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
};

const logout = () => {
  tokenLocal = null;
  localStorage.removeItem(constants.CURRENT_TOKEN_KEY);
  localStorage.removeItem(constants.CURRENT_FILTERS_KEY);
  localStorage.removeItem(constants.CURRENT_INITIAL_KEY);
  localStorage.removeItem(constants.CURRENT_CART_KEY);
  window.location.reload();
};

const reset = async (data) => {
  try {
    const response = await http.patch('/users/password-reset', data);
    return response.data;
  } catch (error) {
    throw error;
  }
};
const recover = async (user) => {
  try {
    const response = await http.patch('/users/request-password-reset', user);
    return response.data;
  } catch (error) {
    throw error;
  }
};
const activate = async (code) => {
  const data = {
    code 
  };
  try {
    const response = await http.patch('/users/activate-user', data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const requestNewUser = async (newUser) => {
  try {
    const response = await http.post('/users/request-user', newUser);
    if (response.status === 200) {
      return response.data;
    } else {
      throw response.data;
    }
  } catch (error) {
    throw error.response.data;
  }
};

export default {
  authenticate,
  logout,
  getUserInfo,
  reset,
  recover,
  activate,
  requestNewUser
};
