import React, { useEffect, useState, useContext, Fragment, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  HomeSlider,
  CarouselProducts,
  Media,
  ModalSimpleImage,
  Loading,
  ModalError,
  Brands,
  ModalSimple,
  Breakings,
  NavBar,
  Footer,
  ModalPromotions,
} from '../index';
import constants from '../../utils/constants';
import './Home.css';
import DisplayUtils from '../../utils/DisplayUtils';
import { AuthContext } from '../../contexts/AuthContext';
import { homeService, authService } from '../../services/index';

const Home = ({ location }) => {
  const { t } = useTranslation();
  const { currentUser } = useContext(AuthContext);
  const [showModal, setShowModal] = useState(false);
  const [showModalPro, setShowModalPro] = useState(false);
  const [video, setVideo] = useState(null);
  const [slider, setSlider] = useState(null);
  const [recommended, setRecommended] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showRecoverOkModal, setShowRecoverOkModal] = useState(false);
  const [listPromos, setListPromos] = useState(null);
  const [showError, setShowError] = useState(false);
  const [brandsList, setBrandsList] = useState(null);
  const [showBreakings, setShowBreakings] = useState(true);
  const [news, setNews] = useState(null);
  const [hiddenNews, setHiddenNews] = useState(false);
  const firstTime = localStorage.getItem(constants.CURRENT_FIRST_KEY);

  const showModalPass = useCallback(async () => {
    if (firstTime === 'promo') {
      await setShowModalPro(true);
      window.$('#modalPromotions').modal('show');
    } else if (firstTime) {
      await setShowModal(true);
      window.$('#simpleModalImage').modal('show');
    }
  }, [firstTime]);

  useEffect(() => {
    let unmounted = false;
    const getItems = async () => {
      try {
        if (!unmounted){
        const newsResponse = await homeService.getNews();
        setNews(newsResponse);
        if (newsResponse.length < 1) {
          setShowBreakings(false);
        }
        const response = await homeService.getMedia();
        if (response) {
          setLoading(false);
          currentUser && showModalPass();
          setVideo(Object.values(response[constants.VIDEO_MEDIA])[0]);
          setRecommended(Object.values(response[constants.RECOMMENDED_MEDIA]));
          setSlider(Object.values(response[constants.SLIDER_MEDIA]));
          if (location.hash) {
            const brand = document.getElementById(location.hash.slice(1));
            brand && brand.scrollIntoView({ behavior: 'smooth' });
          } else {
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
          }
        }
      }
      } catch (error) {
        if (!unmounted){
        setLoading(false);
        showErrorModal();
        }
      }
    };
    getItems();

    return () => { unmounted = true };
  }, [location, currentUser, showModalPass]);

  useEffect(() => {
    let unmounted = false;
    const getBrands = async () => {
      const responseBrands = await homeService.getBrands();
      if (!unmounted){
      setBrandsList(responseBrands);
      }
    };

    getBrands();
    return () => { unmounted = true };
  }, []);

  useEffect(() => {
    let unmounted = false;
    const getPromos = async () => {
      const response = await homeService.getPromos();
      if (!unmounted){
      setListPromos(response);
      }
    };
    currentUser && getPromos();
    return () => { unmounted = true };
  }, [currentUser]);

  const hideModalPass = async () => {
    if (firstTime === 'promo') {
      await setShowModalPro(false);
      window.$('#modalPromotions').modal('hide');
    } else if (firstTime) {
      await setShowModal(false);
      window.$('#simpleModalImage').modal('hide');
    }
    localStorage.removeItem(constants.CURRENT_FIRST_KEY);
    window.$('.modal-backdrop').remove();
    window.$('body').removeClass('modal-open');
  };

  const showErrorModal = async () => {
    await setShowError(true);
    window.$('#errorModal').modal('show');
  };
  const hideErrorModal = () => {
    setShowError(false);
    window.$('#errorModal').modal('hide');
    window.$('.modal-backdrop').remove();
    window.$('body').removeClass('modal-open');
  };

  const recoverPass = async () => {
    try {
      const response = await authService.recover({ email: currentUser.email });
      if (response) {
        localStorage.removeItem(constants.CURRENT_FIRST_KEY);
        showRecoverPassOkModal();
      }
    } catch (error) {
      setLoading(false);
      showErrorModal();
    }
  };

  const showRecoverPassOkModal = async () => {
    hideModalPass();
    await setShowRecoverOkModal(true);
    window.$('#simpleModal').modal('show');
  };

  const hideRecoverPassOkModal = () => {
    setShowRecoverOkModal(false);
    window.$('#simpleModal').modal('hide');
    window.$('.modal-backdrop').remove();
    window.$('body').removeClass('modal-open');
  };

  recommended &&
    recommended.length < 3 &&
    (recommended.length === 1 ? recommended.push(recommended[0], recommended[0]) : recommended.push(recommended[0]));

  const showNews = useCallback(
    item => {
      setHiddenNews(item);
    },
    [setHiddenNews]
  );

  const OnShowBreakings = useCallback(
    item => {
      setShowBreakings(item);
    },
    [setShowBreakings]
  );

  return (
    <Fragment>
      {loading ? (
        <div style={{ height: '100vh' }} className="bg-dbs-white">
          <Loading transparent />
        </div>
      ) : (
        <Fragment>
          {showModal && (
            <ModalSimpleImage
              title={t('FirstTimeModal.title')}
              description={t('FirstTimeModal.description')}
              description2={t('FirstTimeModal.description2')}
              mainBtnText={t('FirstTimeModal.btnText')}
              shop={t('FirstTimeModal.shop')}
              mainAction={recoverPass}
              closeModal={hideModalPass}
            />
          )}
          {showModalPro && (
            <ModalPromotions
              title={t('PromosModal.title')}
              description={t('PromosModal.description')}
              mainBtnText={t('PromosModal.btnText')}
              secBtnText={t('PromosModal.continue')}
              currentUser={currentUser}
              closeModal={hideModalPass}
              listPromos={listPromos}
            />
          )}
          {showRecoverOkModal && (
            <ModalSimple
              title={t('RememberPassOkModal.title')}
              description={t('RememberPassOkModal.description')}
              mainBtnText={t('Globals.ok')}
              mainAction={hideRecoverPassOkModal}
              closeModal={hideRecoverPassOkModal}
            />
          )}
          {news && news.length > 0 && showBreakings && (
            <Breakings news={news} setShowBreakings={OnShowBreakings} hiddenNews={hiddenNews} />
          )}
          {showBreakings ? (
            <NavBar currentPath={location.pathname} home showBreakings hiddenNews showNews={showNews} />
          ) : (
            <NavBar currentPath={location.pathname} showNews={showNews} />
          )}
          <div className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} home-container bg-dbs-gray4`}>
            {showError && <ModalError hideErrorModal={hideErrorModal} />}

            <div
              className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} ${
                showBreakings ? 'breaking' : 'no-breaking'
              } home-content`}
            >
              <HomeSlider images={slider} home />
              <CarouselProducts recommended={recommended} backgroundColor={'bg-dbs-gray5'} showTitle={false} />
              <Media url={video && video.mediaSourceVideo} image={video && video.mediaSource} />
              <div id="brands">{brandsList && <Brands brands={brandsList} />}</div>
            </div>
          </div>
          <Footer />
        </Fragment>
      )}
    </Fragment>
  );
};

export default Home;
