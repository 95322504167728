import React, { useState, useContext, useEffect, useRef } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CartModal, ModalError, ModalDouble, ModalSimpleImage } from '../../index';
import DisplayUtils from '../../../utils/DisplayUtils';
import { AuthContext } from '../../../contexts/AuthContext';
import { authService, userService } from '../../../services/index';
import './NavBar.css';

const NavBar = ({ currentPath, history, showBreakings, home, showNews, hiddenNews }) => {
  const { t } = useTranslation();

  const { currentCart, currentUser, setCurrentUser, modalCart, setModalCart } = useContext(AuthContext);
  const [showError, setShowError] = useState(false);
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [showAnonymousModal, setShowAnonymousModal] = useState(false);
  const [scroll, setScroll] = useState(0); // eslint-disable-line
  const [optionOvered, setOptionOvered] = useState(null);
  const navRef = useRef();

  const [paddingHome, setPaddingHome] = useState(false);
  navRef.current = showBreakings;

  useEffect(() => {
    if (!showBreakings) {
      setPaddingHome(true);
    }
    const handleScroll = () => {
      const show = window.scrollY < 45;

      if (navRef.current !== show) {
        setPaddingHome(true);
      } else {
        setPaddingHome(false);
      }
    };

    home && document.addEventListener('scroll', handleScroll);

    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, [scroll, showBreakings, home, showNews, hiddenNews]);

  useEffect(() => {
    const getUserInfo = async () => {
      try {
        const user = await authService.getUserInfo();
        if (user) {
          setCurrentUser(user);
          if (user && user.roles.includes('admin')) {
            history.push('/admin-panel');
          }
        }
      } catch (error) {
        showErrorModal();
      }
    };

    !currentUser && getUserInfo();
  }, [currentUser, history, setCurrentUser]);

  useEffect(() => {
    const getUserAddresses = async () => {
      try {
        const userAddresses = await userService.getAddresses(currentUser.id);
        if (userAddresses) {
          const newUser = currentUser;
          newUser.addresses = userAddresses;
          setCurrentUser(newUser);
        }
      } catch (error) {
        showErrorModal();
      }
    };

    currentUser && getUserAddresses();
  }, [currentUser, setCurrentUser]);

  const openNav = () => {
    if (document.getElementById('mySidenav')) {
      document.getElementById('mySidenav').style.width = '250px';
    }
  };

  const closeNav = e => {
    e.stopPropagation();
    if (document.getElementById('mySidenav')) {
      document.getElementById('mySidenav').style.width = '0';
    }
  };

  const showCartModal = async () => {
    modalCart && (await setModalCart(false));
    await setModalCart(true);
    window.$('#cartModal').modal('show');
  };

  const onModalCart = async () => {
    currentUser ? showCartModal() : showSimpleImageModal();
  };

  const hideCartModal = async () => {
    await setModalCart(false);
    window.$('#cartModal').modal('hide');
    window.$('.modal-backdrop').remove();
    window.$('body').removeClass('modal-open');
  };

  const goCart = () => {
    hideCartModal();
  };
  const showErrorModal = async () => {
    await setShowError(true);
    window.$('#errorModal').modal('show');
  };
  const hideErrorModal = () => {
    setShowError(false);
    window.$('#errorModal').modal('hide');
    window.$('.modal-backdrop').remove();
    window.$('body').removeClass('modal-open');
  };

  const logOut = () => {
    showDoubleModal();
  };

  const showDoubleModal = async () => {
    await setShowLogoutModal(true);
    window.$('#doubleModal').modal('show');
  };

  const hideDoubleModal = async () => {
    await setShowLogoutModal(false);
    window.$('#doubleModal').modal('hide');
    window.$('.modal-backdrop').remove();
    window.$('body').removeClass('modal-open');
  };

  const closeSession = () => {
    hideDoubleModal();
    authService.logout();
  };

  const showSimpleImageModal = async () => {
    await setShowAnonymousModal(true);
    window.$('#simpleModalImage').modal('show');
  };

  const hideSimpleImageModal = async () => {
    await setShowAnonymousModal(false);
    window.$('#simpleModalImage').modal('hide');
    window.$('.modal-backdrop').remove();
    window.$('body').removeClass('modal-open');
  };

  const onAccess = () => {
    currentUser ? history.push('/profile') : history.push('/login');
  };

  const onPromos = () => {
    currentUser ? history.push('/promotions') : showSimpleImageModal();
  };

  const onPromoMobile = e => {
    e.stopPropagation();
    if (document.getElementById('mySidenav')) {
      document.getElementById('mySidenav').style.width = '0';
    }
    currentUser ? history.push('/promotions') : showSimpleImageModal();
  };

  const goToLogin = () => {
    hideSimpleImageModal();
    history.push('/login');
  };

  const optionOver = (option) => {
    setOptionOvered(option);
  }
const alias = currentUser ? currentUser.alias && currentUser.alias.length < 7 ? currentUser.alias.toUpperCase() : t('Nav.profile').toUpperCase() : t('Nav.access').toUpperCase()
  return (
    <div
      className={`${DisplayUtils.isMobile() ? 'mobile bg-dbs-gray2' : 'desktop bg-dbs-gray1'} ${
        !paddingHome ? 'padding-home' : 'padding-web'
      } ${!home ? 'nav-bar-container' : 'nav-bar-container-home'} `}
    >
      {DisplayUtils.isMobile() && (
        <div className="row d-flex align-items-center justify-content-center m-0 p-0 logo-mobile-container bg-dbs-gray1">
          <Link to="/">
            <img alt="logo" className="main-logo" src="/misc/icons/logo-2.svg" />
          </Link>
        </div>
      )}
      {modalCart && currentPath !== '/cart-detail' && (
        <CartModal closeModal={hideCartModal} goCart={goCart} cartLocal={currentCart} />
      )}
      {showLogoutModal && (
        <ModalDouble
          title={t('LogoutModal.title')}
          description={t('LogoutModal.description')}
          mainBtnText={t('Globals.yes')}
          secondaryBtnText={t('Globals.no')}
          mainAction={closeSession}
          secondaryAction={hideDoubleModal}
          closeModal={hideDoubleModal}
        />
      )}
      {showAnonymousModal && (
        <ModalSimpleImage
          title={t('AnonymousModal.title')}
          description={t('AnonymousModal.description')}
          mainBtnText={t('AnonymousModal.btnText')}
          mainAction={goToLogin}
          closeModal={hideSimpleImageModal}
        />
      )}
      {showError && <ModalError hideErrorModal={hideErrorModal} />}
      <div
        className={`${
          DisplayUtils.isMobile() ? 'container-mobile' : 'h-100'
        } row p-0 m-0 w-100 d-flex align-content-center`}
      >
        {!DisplayUtils.isMobile() && (
          <div className="nav-menu d-flex align-items-center justify-content-center">
            <Link
              to="/"
              className={`${currentPath.includes('') && 'main-blue selected'} white h-100 d-flex align-items-center`}
            >
              <span className={`${currentPath.includes('home') && 'main-blue'} `}>
                {t('Nav.home').toUpperCase()}
              </span>
            </Link>
            <Link
              to={{ pathname: '/', hash: '#brands' }}
              className={`${currentPath.includes('/#brands') &&
                'main-blue selected'} white h-100 d-flex align-items-center`}
            >
              <span className={`${currentPath.includes('/#brands') && 'main-blue'} `}>
                {t('Nav.brands').toUpperCase()}
              </span>
            </Link>
            <Link
              className={`${currentPath.includes('shop') &&
                'main-blue selected'} white h-100 d-flex align-items-center`}
              to="/shop"
            >
              <span className={`${currentPath.includes('shop') && 'main-blue'} `}>
                {t('Nav.shop').toUpperCase()}
              </span>
            </Link>
            <button
              className={`${currentPath.includes('promotions') &&
                'main-blue selected'} white h-100 d-flex align-items-center promos-btn`}
              onClick={onPromos}
            >
              <span className={`${currentPath.includes('promotions') && 'main-blue'} white`}>
                {t('Nav.promotions').toUpperCase()}
              </span>
            </button>
          </div>
        )}
        {DisplayUtils.isMobile() && (
          <div className="col-2 d-flex align-items-center justify-content-start p-0 pl-3">
            <button
              type="button"
              className={`${
                DisplayUtils.isMobile() ? 'mobile' : 'desktop'
              } menu-btn btn-transparent align-items-center d-flex white`}
              onClick={() => openNav()}
            >
              <img src="/misc/icons/nav_menu.svg" alt="menu_navbar" className="menu-icon" />
            </button>
          </div>
        )}
        <div className={`${DisplayUtils.isMobile() ? 'col-3 pl-3' : 'col-8 '} d-flex align-items-center p-0 box-logo`}>
          {!DisplayUtils.isMobile() && (
            <Link to="/">
              <img alt="logo" className="desktop main-logo" src="/misc/icons/logo-2.svg" />
            </Link>
          )}
        </div>
        <div
          className={`${
            DisplayUtils.isMobile() ? 'col-7 mobile p-0 justify-content-end' : 'col-4 desktop justify-content-end'
          } d-flex align-items-center  nav-box-icons`}
        >
          <Link
            to="/contactus"
            className={`${
              currentPath.includes('contactus') ? 'main-blue' : 'white'
            } icons-box d-flex flex-column align-items-center`}
            onMouseOver={() => optionOver('contactus')}
            onMouseLeave={() => optionOver(null)}
          >
            <img
              alt="mail-icon"
              className="nav-icon"
              src={(currentPath.includes('contactus') || optionOvered === 'contactus') ? '/misc/icons/apoyo.svg' : '/misc/icons/apoyo_white.svg'}
            />
            {!DisplayUtils.isMobile() && <span className={(currentPath.includes('contactus') || optionOvered === 'contactus') ? "main-blue" : "white"}>{t('Nav.support').toUpperCase()}</span>}
          </Link>

          <button
            className={`${
              (currentPath.includes('profile') || optionOvered === 'profile') ? 'main-blue' : 'white'
            } icons-box d-flex flex-column align-items-center btn-transparent`}
            onClick={onAccess}
            onMouseOver={() => optionOver('profile')}
            onMouseLeave={() => optionOver(null)}
          >
            <img
              alt="account-icon"
              className="nav-icon"
              src={(currentPath.includes('profile') || optionOvered === 'profile') ? '/misc/icons/account_blue.svg' : '/misc/icons/account.svg'}
            />
            {!DisplayUtils.isMobile() && (
              <span>{alias}</span>
            )}
          </button>
          <div
            className={`${
              DisplayUtils.isMobile() ? 'mobile' : 'desktop'
            } cart-icon-container d-flex flex-column align-items-center`}
            onClick={onModalCart}
            onMouseOver={() => optionOver('cart-detail')}
            onMouseLeave={() => optionOver(null)}
          >
            {currentUser && currentCart && currentCart.length > 0 && (
              <span
                className={`${
                  DisplayUtils.isMobile() ? 'mobile' : 'desktop'
                } cart-badge d-flex justify-content-center align-items-center black`}
              >
                {currentCart.length}
              </span>
            )}
            <img
              alt="cart-icon"
              className="nav-icon"
              src={(currentPath.includes('cart-detail') || optionOvered === 'cart-detail') ? '/misc/icons/deliver_blue.svg' : '/misc/icons/deliver.svg'}
            />
            {!DisplayUtils.isMobile() && (
              <span className={(currentPath.includes('cart-detail') || optionOvered === 'cart-detail') ? 'main-blue' : 'white'}>
                {t('Nav.cart').toUpperCase()}
              </span>
            )}
          </div>
          {currentUser && (
            <button
              onClick={() => logOut()}
              className="white icons-box d-flex flex-column align-items-center btn-transparent"
              onMouseOver={() => optionOver('exit')}
              onMouseLeave={() => optionOver(null)}
            >
              <img alt="mail-icon" className="nav-icon" src={optionOvered === 'exit' ? "/misc/icons/logout_blue@2x.png" : "/misc/icons/logout_white@2x.png"} />
              {!DisplayUtils.isMobile() && <span className={optionOvered === 'exit' ? "main-blue" : "white"}>{t('Nav.exit').toUpperCase()}</span>}
            </button>
          )}
        </div>
      </div>
      <div className={'box-menu d-flex justify-content-center align-items-center'}>
        <div id="mySidenav" className="sidenav bg-main-b2b">
          <img alt="logo" className="nav-main-logo p-1" src="/misc/icons/logo-2.svg" />
          <img alt="closebtn" className="closebtn" src="/misc/icons/close_button.png" onClick={e => closeNav(e)}></img>
          <Link
              to="/" className="d-flex align-items-center white" href="/" onClick={e => closeNav(e)}>
            <img alt="promo-icon" className="nav-icon-mobile mr-3" src="/misc/icons/inicio.svg" />
            {t('Nav.home').toUpperCase()}
          </Link>
          <a className="d-flex align-items-center white" href="/#brands" onClick={e => closeNav(e)}>
            <img alt="promo-icon" className="nav-icon-mobile mr-3" src="/misc/icons/marcas.svg" />
            {t('Nav.brands').toUpperCase()}
          </a>
          <a className="d-flex align-items-center white" href="/shop" onClick={e => closeNav(e)}>
            <img alt="shop-icon" className="nav-icon-mobile mr-3" src="/misc/icons/tienda.svg" />
            {t('Nav.shop').toUpperCase()}
          </a>
          <button className="d-flex align-items-center white btn-transparent" onClick={onPromoMobile}>
            <img alt="promo-icon" className="nav-icon-mobile mr-3" src="/misc/icons/promotion.svg" />
            {t('Nav.promotions').toUpperCase()}
          </button>
          <a className="d-flex align-items-center white" href="/contactus" onClick={e => closeNav(e)}>
            <img alt="mail-icon" className="nav-icon-mobile mr-3" src="/misc/icons/envelope.svg" />
            {t('Nav.contact').toUpperCase()}
          </a>
        </div>
      </div>
    </div>
  );
};

export default withRouter(NavBar);
