import React, { Fragment, useEffect, useState } from 'react';
import constants from '../../../utils/constants';
import filtersUtils from '../../../utils/filtersUtils';
import DisplayUtils from '../../../utils/DisplayUtils';
import { useTranslation } from 'react-i18next';
import './Breadcrumbs.css';

const Breadcrumbs = ({ breadcrumb, handleChange, detail, product, categories }) => {
  const [items, setItems] = useState(breadcrumb);
  const { t } = useTranslation();

  const handleChangeDetail = async (event, data) => {
    const response = await filtersUtils.handleChangeFilters(event, data, categories)
    if(response) {
      localStorage.setItem(constants.CURRENT_FILTERS_KEY, JSON.stringify(response));
      handleChange();
    }
  }

  const onChangeBread = item => {
    detail ? handleChangeDetail(null, item) : handleChange(null, item);
  };

  useEffect(() => {
    if (detail) {
      const updateFilters = async () => {
       const  response = await filtersUtils.updateFilters(categories, product)
        setItems(response.breadcrumb);
        detail
          ? localStorage.setItem(constants.CURRENT_DETAILS_FILTERS_KEY, JSON.stringify(response))
          : localStorage.setItem(constants.CURRENT_FILTERS_KEY, JSON.stringify(response));
      };

      updateFilters();
    }
  }, [categories, detail, product]);

  return (
    <div className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} 
    ${DisplayUtils.isDifferentChrome() ? 'col-12' : ''}  breadcrumbs-container`}>
      <button onClick={() => onChangeBread('shop')} className="btn-transparent black">
      {t('Products.shop').toUpperCase()}
      </button>
      {items &&
        items.map((item, index) => (
          <Fragment key={index}>
            <span> / </span>
            <button
              onClick={() => onChangeBread(Number(item.id))}
              className={`btn-transparent ${index === items.length - 1 ? 'main-blue' : 'black'}`}
            >
              {item.name}
            </button>
          </Fragment>
        ))}
    </div>
  );
};

export default Breadcrumbs;
