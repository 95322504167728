import React, { useState, useEffect, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import { ModalDouble, AdminMenu, RegisterUser, AdminAddresses, UserList, Loading, ModalError, EditUser } from '../../index';
import DisplayUtils from '../../../utils/DisplayUtils';
import { useTranslation } from 'react-i18next';
import { authService } from '../../../services/index';
import { AuthContext } from '../../../contexts/AuthContext';
import './Admin.css';

const Admin = ({ history }) => {
  const { t } = useTranslation();

  const options = [t('AdminPanel.menu.register'), t('AdminPanel.menu.list'), t('AdminPanel.menu.logout')];

  const [selectedOption, setSelectedOption] = useState(0);
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const { currentUser, setCurrentUser } = useContext(AuthContext);
  const [showError, setShowError] = useState(false);
  const [showAddresses, setShowAddresses] = useState(false);
  const [editUser, setEditUser] = useState(null);
  const [userId, setUserId] = useState(null);
  const screenHeight = window.innerHeight - (DisplayUtils.isMobile() ? 77 : 0);

  useEffect(() => {
    setLoading(true);
    const getUserInfo = async () => {
      try {
        const user = await authService.getUserInfo();
        if (user) {
          setCurrentUser(user);
          setLoading(false);
          if (user && !user.roles.includes('admin')) {
            history.push('/home');
          }
        }
      } catch (error) {
        setLoading(false);
        showErrorModal();
      }
    };
    !currentUser && getUserInfo();
    currentUser && setLoading(false);
  }, [history, setCurrentUser, currentUser]);

  const showErrorModal = async () => {
    await setShowError(true);
    window.$('#errorModal').modal('show');
  };
  const hideErrorModal = () => {
    setShowError(false);
    window.$('#errorModal').modal('hide');
    window.$('.modal-backdrop').remove();
    window.$('body').removeClass('modal-open');
  };

  const optionSelected = (option) => {
    setSelectedOption(option);
  };

  const logOut = () => {
    showDoubleModal();
  };

  const showDoubleModal = async () => {
    await setShowLogoutModal(true);
    window.$('#doubleModal').modal('show');
  };

  const hideDoubleModal = () => {
    setShowLogoutModal(false);
    window.$('#doubleModal').modal('hide');
    window.$('.modal-backdrop').remove();
    window.$('body').removeClass('modal-open');
  };

  const closeSession = () => {
    hideDoubleModal();
    authService.logout();
  };

  const closeEdit = () => {
    setEditUser(null)
  }

  const openAddresses = id => {
    setUserId(id)
    setShowAddresses(true)
    setEditUser(null)
  }

  const getSelectedComponent = () => {
    switch (selectedOption) {
      case 0:
        return showAddresses && userId ? <AdminAddresses userId={userId} showAddresses={setShowAddresses}/> : <RegisterUser userId={setUserId} showAddresses={setShowAddresses}/>;
      case 1:
        return !editUser ? ( showAddresses && userId ? <AdminAddresses userId={userId} showAddresses={setShowAddresses} fromEdit={true} /> : <UserList setEditUser={setEditUser}/>) : <EditUser user={editUser} closeEdit={closeEdit} openAddresses={openAddresses}/>
      default:
        return <div>{options[selectedOption]}</div>;
    }
  };

  return loading ? (
    <Loading transparent/>
  ) : (
    <div className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} admin-container bg-light-b2b`}>
      {showLogoutModal && (
        <ModalDouble
          title={t('LogoutModal.title')}
          description={t('LogoutModal.description')}
          mainBtnText={t('Globals.yes')}
          secondaryBtnText={t('Globals.no')}
          mainAction={closeSession}
          secondaryAction={hideDoubleModal}
          closeModal={hideDoubleModal}
        />
      )}
      {showError && <ModalError hideErrorModal={hideErrorModal} />}
      <div className="row m-0 admin-content" style={DisplayUtils.isMobile() ? {} : { height: screenHeight }}>
        <div
          style={DisplayUtils.isMobile() ? {} : { height: screenHeight }}
          className={`${DisplayUtils.isMobile() ? 'mobile col-12 p-0' : 'desktop col-3 p-0'} admin-menu bg-main-b2b`}
        >
          <AdminMenu
            options={options}
            selectedOption={selectedOption}
            optionSelected={optionSelected}
            logOut={logOut}
          />
        </div>
        <div
          style={DisplayUtils.isMobile() ? { height: screenHeight } : { height: screenHeight }}
          className={`${
            DisplayUtils.isMobile() ? 'mobile col-12 p-0' : 'desktop col-9 p-0'
          } components-admin-container bg-light-b2b`}
        >
          {getSelectedComponent()}
        </div>
      </div>
      {DisplayUtils.isMobile() && (
        <div className="logout-btn-container w-100">
          <button
            className={`${
              DisplayUtils.isMobile() ? 'mobile' : 'desktop'
            } btn-transparent logout-btn align-items-center`}
            onClick={() => logOut()}
          >
            <span className="white align-middle logout-text mr-1">{t('AdminPanel.menu.logout')}</span>
            <img src="/misc/icons/logout_white@2x.png" alt="logout-icon" className="icon-logout" />
          </button>
        </div>
      )}
    </div>
  );
};

export default withRouter(Admin);
