import React, { useReducer, useEffect, forwardRef, useImperativeHandle } from 'react';
import DisplayUtils from '../../../../utils/DisplayUtils';
import { useTranslation } from 'react-i18next';
import './FormAddress.css';

const initialState = {
  id: 0,
  formName: '',
  formTitle: '',
  formSurname: '',
  formAddress: '',
  formCity: '',
  formProvince: '',
  formPostal: '',
  errors: {},
};

const reducer = (state, action) => {
  state.errors[action.type] = !action.payload;
  switch (action.type) {
    case 'formTitle':
      return { ...state, formTitle: action.payload };
    case 'formName':
      return { ...state, formName: action.payload };
    case 'formSurname':
      return { ...state, formSurname: action.payload };
    case 'formAddress':
      return { ...state, formAddress: action.payload };
    case 'formCity':
      return { ...state, formCity: action.payload };
    case 'formProvince':
      return { ...state, formProvince: action.payload };
    case 'formPostal':
      return { ...state, formPostal: action.payload };
    case 'id':
      return { ...state, id: action.payload };
    default:
      return initialState;
  }
};
const FormAddress = forwardRef((props, ref) => {
  const { t } = useTranslation();

  const [state, dispatch] = useReducer(reducer, initialState);
  const { showForm, onSaveClick, editState } = props;

  useEffect(() => {
    if (editState) {
      dispatch({ type: 'formTitle', payload: editState.title });
      dispatch({ type: 'formName', payload: editState.name });
      dispatch({ type: 'formSurname', payload: editState.surname });
      dispatch({ type: 'formAddress', payload: editState.address });
      dispatch({ type: 'formCity', payload: editState.city });
      dispatch({ type: 'formProvince', payload: editState.province });
      dispatch({ type: 'formPostal', payload: editState.postalCode });
      dispatch({ type: 'id', payload: editState.id });
    }

    return () => {
      state.errors = {};
      dispatch({ type: 'initialState' });
    };
  }, [editState, state.errors]);

  const isValid = () => {
    return Object.values(state).some((item) => item === '');
  };

  useImperativeHandle(ref, () => ({
    getState() {
      return state;
    },
  }));

  return (
    <div className={`${DisplayUtils.isMobile() ? 'col-12 mb-4 p-0' : 'col-4 mb-4'} mt-4`}>
      <div className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} form-address-container bg-white pb-4`}>
        <div
          className={`${
            DisplayUtils.isMobile() ? 'mobile' : 'desktop'
          } row m-0 form-address-title bg-dark-b2b white d-flex align-items-center`}
        >
          <div className={`${!DisplayUtils.isMobile() ? '' : ''} col-8 title-input p-0`}>
            <input
              name="formTitle"
              value={state.formTitle}
              type="text"
              placeholder={t('AddressForm.title')}
              className={`${state.errors.formTitle && 'is-invalid'} form-control w-100`}
              onBlur={(e) => dispatch({ type: e.target.name, payload: e.target.value })}
              onChange={(e) => dispatch({ type: e.target.name, payload: e.target.value })}
            />
          </div>
          <div className="col-4 p-0 pr-3 justify-content-end d-flex align-items-center">
            <img className="mr-3" alt="delete-icon" src="/misc/icons/delete@2x.png" onClick={() => showForm(false)} />
          </div>
        </div>
        <div className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} form-address-inputs`}>
          <input
            name="formName"
            value={state.formName}
            type="text"
            placeholder={t('Globals.name')}
            className={`${state.errors.formName && 'is-invalid'} form-control w-100`}
            onBlur={(e) => dispatch({ type: e.target.name, payload: e.target.value })}
            onChange={(e) => dispatch({ type: e.target.name, payload: e.target.value })}
          />
          <input
            name="formSurname"
            value={state.formSurname}
            type="text"
            placeholder={t('Globals.surname')}
            className={`${state.errors.formSurname && 'is-invalid'} form-control w-100 mt-3`}
            onBlur={(e) => dispatch({ type: e.target.name, payload: e.target.value })}
            onChange={(e) => dispatch({ type: e.target.name, payload: e.target.value })}
          />
          <input
            name="formAddress"
            value={state.formAddress}
            type="text"
            placeholder={t('AddressForm.address')}
            className={`${state.errors.formAddress && 'is-invalid'} form-control w-100 mt-3`}
            onBlur={(e) => dispatch({ type: e.target.name, payload: e.target.value })}
            onChange={(e) => dispatch({ type: e.target.name, payload: e.target.value })}
          />
          <input
            name="formPostal"
            value={state.formPostal}
            type="text"
            placeholder={t('AddressForm.postalCode')}
            className={`${state.errors.formPostal && 'is-invalid'} form-control w-100 mt-3`}
            onBlur={(e) => dispatch({ type: e.target.name, payload: e.target.value })}
            onChange={(e) => dispatch({ type: e.target.name, payload: e.target.value })}
          />
          <input
            name="formCity"
            value={state.formCity}
            type="text"
            placeholder={t('AddressForm.city')}
            className={`${state.errors.formCity && 'is-invalid'} form-control w-100 mt-3`}
            onBlur={(e) => dispatch({ type: e.target.name, payload: e.target.value })}
            onChange={(e) => dispatch({ type: e.target.name, payload: e.target.value })}
          />
          <input
            name="formProvince"
            value={state.formProvince}
            type="text"
            placeholder={t('AddressForm.province')}
            className={`${state.errors.formProvince && 'is-invalid'} form-control w-100 mt-3`}
            onBlur={(e) => dispatch({ type: e.target.name, payload: e.target.value })}
            onChange={(e) => dispatch({ type: e.target.name, payload: e.target.value })}
          />
        </div>
        <button
          className={`${
            DisplayUtils.isMobile() ? 'mobile' : 'desktop'
          } m-auto save-btn d-flex align-items-center justify-content-center bg-secondary-b2b`}
          onClick={() => onSaveClick(state)}
          disabled={isValid()}
        >
          {t('Globals.save')}
        </button>
      </div>
    </div>
  );
});

export default FormAddress;
