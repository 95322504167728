import React from 'react';
import DisplayUtils from '../../../utils/DisplayUtils';
import './ProfileMenu.css';

const ProfileMenu = ({ options, selectedOption, optionSelected, optionOver, overOption }) => {
  return (
    <div className={`${DisplayUtils.isMobile() ? 'mobile' : 'desktop'} dbs-black profile-menu-content`}>
      {DisplayUtils.isMobile() ? (
        <div className="menu-mobile">
          <ul>
            <li
              className={`${selectedOption === 0 ? 'main-blue selected' : ''} pb-1`}
              onClick={() => optionSelected(0)}
            >
              <span>{options[0]}</span>
            </li>
            <li
              className={`${selectedOption === 1 ? 'main-blue selected' : ''} pb-1`}
              onClick={() => optionSelected(1)}
            >
              <span>{options[1]}</span>
            </li>
            <li
              className={`${selectedOption === 2 ? 'main-blue selected' : ''} pb-1`}
              onClick={() => optionSelected(2)}
            >
              <span>{options[2]}</span>
            </li>
          </ul>
        </div>
      ) : (
        <ul>
          <li className="d-flex align-items-center mb-3" 
            onClick={() => optionSelected(0)} 
            onMouseOver={() => optionOver(0)} 
            onMouseLeave={() => optionOver(selectedOption)}>
            <img
              alt="orders-icon"
              src={selectedOption === 0 || overOption === 0 ? '/misc/icons/package_blue.svg' : '/misc/icons/package_black.svg'}
            />
            <span className={selectedOption === 0 ? 'main-blue' : ''}>{options[0]}</span>
          </li>
          <li className="d-flex align-items-center mb-3" 
            onClick={() => optionSelected(1)}
            onMouseOver={() => optionOver(1)}
            onMouseLeave={() => optionOver(selectedOption)}>
            <img
              alt="orders-icon"
              src={selectedOption === 1 || overOption === 1 ? '/misc/icons/location_blue.svg' : '/misc/icons/location_black.svg'}
            />
            <span className={selectedOption === 1 ? 'main-blue' : ''}>{options[1]}</span>
          </li>
          <li className="d-flex align-items-center mb-3" 
            onClick={() => optionSelected(2)}
            onMouseOver={() => optionOver(2)}
            onMouseLeave={() => optionOver(selectedOption)}>
            <img alt="orders-icon" src={selectedOption === 2 || overOption === 2 ? '/misc/icons/user_blue.svg' : '/misc/icons/user_black.svg'} />
            <span className={selectedOption === 2 ? 'main-blue' : ''}>{options[2]}</span>
          </li>
        </ul>
      )}
    </div>
  );
};

export default ProfileMenu;
